export const ErrorHandler = (error) => {
  let errorMessage
  const errorMessages = {
    204: 'No content.',
    400: 'Your request is invalid.',
    401: 'You are not authorized. Please Refresh the Page and try again.',
    403: 'You do not have permission to access this resource.',
    404: 'The requested resource was not found.',
    408: 'Request timed out. Please try again later.',
    429: 'Too many requests. Please wait a moment and try again.',
    500: 'There was a problem on the server.',
    502: 'Bad gateway. The server received an invalid response from the upstream server.',
    503: 'Service unavailable. The server is currently unable to handle the request.',
    504: 'Gateway timeout. The server did not receive a timely response from the upstream server.',
  }
  if (
    error?.message &&
    (error?.message?.includes('Network Error') ||
      error?.message?.includes('timeout'))
  ) {
    errorMessage =
      'Network error: Unable to reach the server. Please check your internet connection and try again.'
  } else if (error?.response) {
    // Backend responded with a status code
    const backendMessage = error?.response?.data?.error_message || ''
    const statusCodeMessage =
      errorMessages[error?.response?.status] || 'An unexpected error occurred.'

    errorMessage = `${statusCodeMessage} ${backendMessage}`.trim()
  } else {
    // Something happened in setting up the request
    errorMessage = `Error setting up request: ${error.message}`
  }
  return errorMessage
}
