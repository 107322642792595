import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class ChangeMgmtApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_change_mgmt_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 450000,
    })
    return this.client
  }

  getChangeSummary = async (body) => {
    try {
      const changeSummary = await this.init_change_mgmt_api().post(
        `${this.config.markethub.url}offer-change/summary`,
        body
      )
      return changeSummary.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  getBundledChangeSummary = async (body) => {
    try {
      const changeSummary = await this.init_change_mgmt_api().post(
        `${this.config.markethub.url}offer-change/bundled`,
        body
      )
      return changeSummary.data
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  getChangeStatus = async ({ filters, project_ids }, setToaster) => {
    try {
      const changeStatus = await this.init_change_mgmt_api().post(
        `${this.config.markethub.url}offer-change/changeStatusSummary`,
        { filters, project_ids }
      )
      return changeStatus.data
    } catch (e) {
      setToaster({
        message: `${e.message} `,
        type: 'error',
      })
    }
  }

  getChangeByDate = async (params) => {
    try {
      const changesByDate = await this.init_change_mgmt_api().get(
        `${this.config.markethub.url}offer-change`,
        params
      )

      return changesByDate.data
    } catch (e) {
      console.log('Error:', e)
    }
  }

  updateNotes = async (payload) => {
    try {
      return await this.init_change_mgmt_api().put(
        `${this.config.markethub.url}offer-change/review`,
        payload
      )
    } catch (error) {
      console.log(error)
      return []
    }
  }

  approveAllChanges = async (payload, setToaster) => {
    try {
      return await this.init_change_mgmt_api().put(
        `${this.config.markethub.url}offer-change/approve-all`,
        payload
      )
    } catch (error) {
      setToaster({
        message: `There is a temporary ${error.message} preventing your selection from being saved.Please try again.`,
        type: 'error',
      })
      return {}
    }
  }

  bundledUpdates = async (payload) => {
    try {
      return await this.init_change_mgmt_api().put(
        `${this.config.markethub.url}offer-change/bundle/review`,
        payload
      )
    } catch (error) {
      console.log(error)
      return []
    }
  }
}
