import {
  Input,
  Drawer,
  Grid,
  ProgressBar,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import { useState, useContext } from 'react'

import { CanEditContext, WeeklyAdAutomationContext } from '../context/Context'
import { capFirstLetter } from '../publishing/util/helpers.js'

import './scss/ColorPalette.scss'

const ColorPalette = ({
  colorPalette,
  colorPaletteSeasonsList,
  setColorPalette,
  projectId,
  isColorPaletteDrawerVisible,
  setIsColorPaletteDrawerVisible,
  isColorPaleteLoading,
}) => {
  const [isImageLoading, setIsImageLoading] = useState(true)
  const { canEdit } = useContext(CanEditContext)
  const { updateProjectPageDetails } = useContext(WeeklyAdAutomationContext)

  const handleImageLoad = () => {
    setIsImageLoading(false)
  }

  const findSeasonBySeasonName = (name) =>
    colorPaletteSeasonsList.find((season) => season?.season_name === name)

  const handleUpdate = (value) => {
    const selectedSeason = findSeasonBySeasonName(value)
    if (
      !selectedSeason ||
      selectedSeason.season_name === colorPalette?.season_name
    ) {
      return
    }

    const updatedColorPalette = {
      ...colorPalette,
      assethub_season_image_link: selectedSeason.assethub_season_image_link,
      season_name: selectedSeason.season_name,
    }
    setColorPalette(updatedColorPalette)

    const payload = {
      project_id: projectId,
      ...updatedColorPalette,
    }
    updateProjectPageDetails(payload, 'ColorPalette')
  }

  const convertSeasonsArrayToOptions = (array) =>
    array?.map((option) => ({
      label: capFirstLetter(option?.season_name),
      value: option?.season_name,
    }))

  return (
    <>
      <Drawer
        isVisible={isColorPaletteDrawerVisible}
        headingText={'Project Color Palette'}
        onRequestClose={() => setIsColorPaletteDrawerVisible(false)}
        location="bottom"
      >
        {isColorPaleteLoading ? (
          <Grid.Container className="color-palette-wrapper">
            <Grid.Item xs={12}>
              <ProgressBar indeterminate />
            </Grid.Item>
          </Grid.Container>
        ) : (
          <Grid.Container
            justify="center"
            align="center"
            className="color-palette-wrapper"
            direction="row"
          >
            <Grid.Item xs={5}>
              <Input.Label htmlFor="image_link">
                Color Palette Season
              </Input.Label>
              <Input.Select
                id="image_link"
                onUpdate={(id, value) => handleUpdate(value)}
                value={colorPalette?.season_name}
                options={convertSeasonsArrayToOptions(colorPaletteSeasonsList)}
                disabled={!canEdit}
              />
            </Grid.Item>
            {colorPalette?.assethub_season_image_link && (
              <Grid.Item xs={12} className="image-container">
                {isImageLoading && <Spinner className="image-spinner" />}
                <div className="image-container">
                  <img
                    className={`color-palette-image ${
                      isImageLoading ? 'hidden' : ''
                    }`}
                    alt="Color Palette Img Not Found"
                    src={colorPalette?.assethub_season_image_link}
                    onLoad={handleImageLoad}
                  />
                </div>
              </Grid.Item>
            )}
          </Grid.Container>
        )}
      </Drawer>
    </>
  )
}

export default ColorPalette
