import { sortBy } from 'lodash'

import { getDivisionName } from './MerchandiseUtil'

export const CHANGE_SUMMARY_PAGE_SIZE = 50
export const BUNDLE_PAGE_SIZE = 10

export const isChangeSummaryLastPage = ({ pageNum, totalRecords }) => {
  if (
    totalRecords <= CHANGE_SUMMARY_PAGE_SIZE ||
    pageNum * CHANGE_SUMMARY_PAGE_SIZE >= totalRecords
  ) {
    return true
  } else {
    return false
  }
}

export const isChangeSummaryFirstPage = (pageNum = 1) => pageNum === 1

export const isBundleLastPage = ({ pageNum, totalRecords }) => {
  if (
    totalRecords <= BUNDLE_PAGE_SIZE ||
    pageNum * BUNDLE_PAGE_SIZE >= totalRecords
  ) {
    return true
  } else {
    return false
  }
}

export const makeChangeSummaryPayload = ({
  channels = [],
  changePage,
  curProjectIds,
  query,
  pageNumber,
  pageSize,
  sortBy,
}) => {
  let payload = {
    filters: {},
    page_num: 1,
    per_page: pageSize,
    project_ids: [],
    ...(sortBy && {
      sort_by: {
        field: sortBy.field || 'start_date',
        order: sortBy.order || 'asc',
      },
    }),
  }

  if (curProjectIds) {
    payload.project_ids = curProjectIds
  }

  if (query) {
    const currentParams = Object.fromEntries(query)

    payload.filters = currentParams

    const channelFilters = currentParams?.marketing_channels
    const divisions =
      currentParams?.divisions !== undefined ? currentParams?.divisions : null
    const pyramidFilters = currentParams?.pyramid_name

    delete payload.filters.marketing_channels
    if (channelFilters) {
      payload.filters.marketing_channels = channelFilters.split(',')
    }
    if (divisions !== null && divisions.length > 0) {
      payload.filters.divisions = divisions.split(',')
    }
    if (pyramidFilters && pyramidFilters.length > 0) {
      payload.filters.pyramid_name = pyramidFilters.split(',')
    }
    const changeReviewStatus =
      currentParams?.change_review_status !== undefined
        ? currentParams?.change_review_status.split('&')
        : null

    if (changeReviewStatus !== null && changeReviewStatus.length > 0) {
      payload.filters.change_review_status = changeReviewStatus
    }
  }

  if (changePage) {
    if (changePage > 0) {
      payload.page_num = pageNumber + 1
    } else if (changePage < 0) {
      payload.page_num = pageNumber - 1
    }
  } else {
    payload.page_num = pageNumber
  }

  return payload
}

export const getSelectedChannels = (
  channelFilters = [],
  channelOptions = []
) => {
  const selectedChannels = []
  for (let i = 0; i < channelFilters.length; i++) {
    const channel = channelOptions.find(
      (channel) => channel.id === parseInt(channelFilters[i])
    )
    if (channel) {
      selectedChannels.push(channel)
    }
  }
  return selectedChannels
}
export const getSelectedPyramids = (pyramids, pyramidOptions) => {
  const selectedPyramids = []
  for (let i = 0; i < pyramids.length; i++) {
    const pyramid = pyramidOptions.find(
      (pyramid) => pyramid.value === pyramids[i]
    )
    if (pyramid) {
      selectedPyramids.push(pyramid)
    }
  }
  return selectedPyramids
}

export const getSelectedDivisions = (divisions = [], displayDivisions = []) => {
  const selectedDivisions = []
  for (let i = 0; i < divisions.length; i++) {
    const division = displayDivisions.find(
      (division) => division.id === parseInt(divisions[i])
    )
    if (division) {
      selectedDivisions.push(division)
    }
  }
  return selectedDivisions
}

export const getSelectedChannelName = (channels = [], channelId) => {
  const selectedChannel = channels.find(
    (channel) => channel.channel_id === parseInt(channelId)
  )
  return selectedChannel?.channel_name || channelId || ''
}

export const formatProjectOptions = (projects) =>
  projects?.map((project) => ({
    disabled: false,
    id: 'projectName' + project.project_id,
    label: project.project_description + ' | ' + (project.project_name || ''),
    value: project.project_id,
  }))

export const createProjectsIdsArray = (projects = []) =>
  projects.map((project) => project.project_id)

export const pyramidOptions = [
  {
    color: '#313199',
    id: 0,
    label: 'Apparel & Accessories',
    value: 'a&a',
  },
  {
    color: '#6320aa',
    id: 1,
    label: 'Beauty',
    value: 'bty',
  },
  {
    color: '#6320aa',
    id: 2,
    label: 'Essentials',
    value: 'ess',
  },
  {
    color: '#1e7854',
    id: 3,
    label: 'Food & Beverage',
    value: 'f&b',
  },
  {
    color: '#b0270d',
    id: 4,
    label: 'Hardlines',
    value: 'hrdl',
  },
  {
    color: '#128491',
    id: 5,
    label: 'Home',
    value: 'home',
  },
]

export const fieldOptions = [
  {
    id: 0,
    label: 'Select Field Name',
    value: '',
  },
  {
    id: 1,
    label: 'Art Patch Copy',
    value: 'art_patch_copy',
  },
  {
    id: 2,
    label: 'Copy Direction',
    value: 'copy',
  },
  {
    id: 3,
    label: 'Offer Notes',
    value: 'merch_notes',
  },
  {
    id: 4,
    label: 'Merch Product Notes',
    value: 'merch_product_notes',
  },
  {
    id: 5,
    label: 'Offer End Date',
    value: 'end_date',
  },
  {
    id: 6,
    label: 'Offer Markets',
    value: 'markets',
  },
  {
    id: 7,
    label: 'Offer Promo $ Market Patches',
    value: 'offer_price_patch',
  },
  {
    id: 8,
    label: 'Offer Promo $ Value',
    value: 'promo',
  },
  {
    id: 9,
    label: 'Offer Start Date',
    value: 'start_date',
  },
  {
    id: 10,
    label: 'Offer Status',
    value: 'offer_status',
  },
  {
    id: 11,
    label: 'Offer Type',
    value: 'offer_type',
  },
  {
    id: 12,
    label: 'Pictured Item',
    value: 'pictured_revised',
  },
  {
    id: 13,
    label: 'Pictured Item Fig/Model',
    value: 'fig_model',
  },
  {
    id: 14,
    label: 'Pictured Item Image File Name',
    value: 'file_image_name',
  },
  {
    id: 15,
    label: 'Pictured Item Image Type',
    value: 'image_type',
  },
  {
    id: 16,
    label: 'Pictured Item Primary AssetHub Image',
    value: 'reference_image',
  },
  {
    id: 17,
    label: 'Pictured Item Reg. $ Callout',
    value: 'reg_call_out',
  },
  {
    id: 18,
    label: 'Sale Handle',
    value: 'sale_handle',
  },
]

export const setCharAt = (str, diffIndices) => {
  let characterArray = Array.from(str)
  for (let i = 0; i < diffIndices.length; i++) {
    for (let j = 0; j < characterArray.length; j++) {
      if (diffIndices[i] === j) {
        if (!isNaN(characterArray[j]) && characterArray[j] !== ' ') {
          characterArray[j] = `<mark>${characterArray[j]}</mark>`
        }
      }
    }
  }
  let jsxString = characterArray.join('')
  return jsxString
}

export const formatMarkets = (markets = '', oldMarkets) => {
  if (oldMarkets) {
    let jsxString = ''
    let diffIndices = []
    markets.split('').forEach((val, i) => {
      if (val !== oldMarkets.charAt(i)) {
        diffIndices.push(i)
      }
    })

    jsxString = setCharAt(markets, diffIndices)

    const formattedMarkets = jsxString
      .substring(1, jsxString.length - 1)
      .split('Markets=')

    formattedMarkets.shift()

    return formattedMarkets
  } else {
    let formattedMarkets = markets
      .substring(1, markets.length - 1)
      .split('Markets=')

    formattedMarkets.shift()

    return formattedMarkets
  }
}

export const formatDcpi = (fields_changed, old) => {
  let formattedChanges = []

  fields_changed = fields_changed.filter((item) => item.dpci !== undefined)
  fields_changed.sort((a, b) => a.dpci.localeCompare(b.dpci))

  fields_changed.forEach((change) => {
    let { dpci, old_value, new_value, dpci_added, dpci_deleted } = change

    if (old === 1) {
      if (!dpci_added && !dpci_deleted && old_value) {
        formattedChanges.push({ dpci, value: old_value })
      }
      if (dpci_deleted && old_value) {
        formattedChanges.push({ deleted: true, dpci, value: old_value })
      }
    } else {
      if (!dpci_added && !dpci_deleted && new_value) {
        formattedChanges.push({ dpci, value: new_value })
      }
      if (dpci_added && new_value) {
        formattedChanges.push({ added: true, dpci, value: new_value })
      }
    }
  })

  return formattedChanges
}

export const formatUpdateTime = (isoString) => {
  let date = new Date(isoString)
  return isoString != null
    ? date.toLocaleString([], {
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : 'null'
}

export const formatEmailToUsername = (email) =>
  email ? email.split('@')[0] : 'null'

export const sortNotesByCreatedTime = (notes = []) =>
  sortBy(notes, (a) => a.written_at) ?? []

export const getChangeSummaryFieldValue = (
  fieldName,
  fieldValue,
  divisions = []
) => {
  switch (fieldName) {
    case 'Division':
      return getDivisionName(divisions, fieldValue)
    case 'Pictured Item':
      return fieldValue && fieldValue.offer_field_change_details
        ? formatDcpi(fieldValue.offer_field_change_details)
        : null
    case 'Offer Promo $ Market Patches':
      return (
        <ul>
          {formatMarkets(fieldValue).map((markets, index) => (
            <li
              dangerouslySetInnerHTML={{
                __html: `${markets.replace(/:|,/g, ' ')}`,
              }}
              key={`market-${index}`}
            ></li>
          ))}
        </ul>
      )
    default:
      return fieldValue
  }
}

export const groupByChangeRecords = (history = {}) => {
  const result = history?.changes?.reduce((acc, obj) => {
    const existingObj = acc.find(
      (item) =>
        item.changed_by === obj.changed_by &&
        item.date_changed === obj.date_changed &&
        item.origin === obj.origin
    )
    if (existingObj) {
      existingObj.fields_changed.push(obj)
    } else {
      acc.push({
        changed_by: obj.changed_by,
        date_changed: obj.date_changed,
        fields_changed: [obj],
        origin: obj.origin,
        post_ad_close: obj.post_ad_close,
      })
    }
    return acc
  }, [])

  result?.forEach((group) => {
    group.fields_changed.sort(
      (a, b) => new Date(b.date_changed) - new Date(a.date_changed)
    )
  })

  return result
}

export const getStatusChangeType = (value) => {
  if (value === '0') return 'PENDING'
  else if (value === '1') return 'APPROVED'
  else return 'NOT_APPROVED'
}

export const getOfferChangeType = (origin, offerId) => {
  if (origin === 'Ivy') {
    return 'OFFER REVISED IN IVY'
  } else if (offerId === -1) {
    return 'PLACEHOLDER OFFER CHANGE'
  } else {
    return 'CREATIVEHUB REVISED'
  }
}

export const VIEW_LINK_NAMES = {
  merch_view: 'Promo Offers View',
}

export const statusChangePayload = ({
  value,
  fieldName,
  changeRecord,
  history,
}) => {
  const now = new Date()
  const isoString = now.toISOString()
  let payload = {
    change_time: changeRecord.date_changed,
    marketing_approval_status: {
      [fieldName]: {
        approval_status: getStatusChangeType(value),
        updated_at: isoString,
      },
    },
    offer_id: history.offer_id,
    offer_uuid: history.offer_uuid,
    priority: history.priority,
    project_id: history.project_id,
    pyramid_id: history.pyramid_id,
    review: {
      status: changeRecord?.review_status?.review,
    },
    story_id: history.story_id,
    umbrella_id: history.umbrella_id,
  }
  return payload
}

export const activityChangePayload = ({
  activityName,
  channelName,
  value,
  fieldName,
  changeRecord,
  fieldValueIndex = 0,
  history,
  currentUserEmail,
}) => {
  const now = new Date()
  const isoString = now.toISOString()

  let payload = {
    change_time: changeRecord.date_changed,
    channel_activity_status: {
      activity_name: activityName,
      channel_name: channelName,
      field: fieldName,
      status: value,
    },

    offer_id: history.offer_id,
    offer_uuid: history.offer_uuid,

    priority: history.priority,

    project_id: history.project_id,

    pyramid_id: history.pyramid_id,

    review: {
      status: changeRecord?.review_status?.review,
      updated_at: isoString,
      updated_by: currentUserEmail,
    },
    story_id: history.story_id,
    umbrella_id: history.umbrella_id,
  }

  return payload
}

export const mktgApprovalStatusOptions = [
  {
    id: 0,
    label: 'Select Mktg Approval Status',
    value: '',
  },
  {
    id: 1,
    label: 'Auto Approved & Approved',
    value: 'auto_approved&approved',
  },
  {
    id: 2,
    label: 'Auto Approved',
    value: 'auto_approved',
  },
  {
    id: 3,
    label: 'Approved',
    value: 'approved',
  },
  {
    id: 4,
    label: 'Pending',
    value: 'pending',
  },
  {
    id: 5,
    label: 'Not Approved',
    value: 'not_approved',
  },
]

export const approveAllPayload = (history) => {
  let payload = {
    offer_id: history.offer_id,
    offer_uuid: history.offer_uuid,
    project_id: history.project_id,
    pyramid_id: history.pyramid_id,
    story_id: history.story_id,
    umbrella_id: history.umbrella_id,
  }
  return payload
}

export const creativeArtOptions = [
  {
    id: 0,
    label: 'Select Art Filter',
    value: '',
  },
  {
    id: 1,
    label: 'Art All',
    value: 'only',
  },
  {
    id: 2,
    label: 'Art Unselected',
    value: 'false',
  },
  {
    id: 3,
    label: 'Art Selected',
    value: 'true',
  },
]

export const creativeCopyOptions = [
  {
    id: 0,
    label: 'Select Copy Filter',
    value: '',
  },
  {
    id: 1,
    label: 'Copy All',
    value: 'only',
  },
  {
    id: 2,
    label: 'Copy Unselected',
    value: 'false',
  },
  {
    id: 3,
    label: 'Copy Selected',
    value: 'true',
  },
]

export function categorizeOfferStatus(result = [], canCheckArt, canCheckCopy) {
  const extractStatuses = (obj) =>
    obj?.fields_changed?.flatMap((row) => [
      ...(row?.fields_changed?.flatMap((field) => field?.review?.status) ?? []),
      ...(row?.items_changed?.flatMap((item) =>
        item.fields_changed?.flatMap((field) => field?.review?.status)
      ) ?? []),
    ]) ?? []

  const offerStatus = result
    .flatMap((res) => extractStatuses(res))
    .filter(Boolean)

  const uniqueStatuses = [...new Set(offerStatus)]

  const checkDwaStatus = (obj) =>
    obj?.fields_changed?.flatMap((row) => [
      ...(row?.fields_changed?.flatMap((field) =>
        field.channel_activities?.flatMap((channel) =>
          channel.activities?.map((activity) => ({
            name: activity?.name,
            status: activity?.status,
          }))
        )
      ) ?? []),
      ...(row?.items_changed?.flatMap((item) =>
        item.fields_changed?.flatMap((field) =>
          field.channel_activities?.flatMap((channel) =>
            channel.activities?.map((activity) => ({
              name: activity?.name,
              status: activity?.status,
            }))
          )
        )
      ) ?? []),
    ]) ?? []

  const dwaStatuses = result
    .flatMap((res) => checkDwaStatus(res))
    .filter(Boolean)

  const dwaCheckExists = dwaStatuses.some(
    (activity) =>
      activity &&
      ((activity.name === 'Art' && canCheckArt) ||
        (activity.name === 'Copy' && canCheckCopy))
  )

  const allDwaChecked = dwaStatuses.every(
    (activity) =>
      (activity.name === 'Art' && (!canCheckArt || activity.status)) ||
      (activity.name === 'Copy' && (!canCheckCopy || activity.status))
  )

  const isAutoApproved = uniqueStatuses.every(
    (status) => status === 'Auto Approved'
  )
  const hasApprovedOrAutoApproved = uniqueStatuses.every(
    (status) => status === 'Approved' || status === 'Auto Approved'
  )
  const isNotApproved = uniqueStatuses.every(
    (status) => status === 'Not Approved'
  )

  if (isNotApproved) {
    return 'Not Approved'
  }

  if (uniqueStatuses.includes('Pending') || uniqueStatuses.includes('')) {
    return 'Pending Approval'
  }

  if (isAutoApproved && !dwaCheckExists) {
    return 'Auto-approved'
  }

  if (hasApprovedOrAutoApproved) {
    if (!dwaCheckExists) {
      return 'Completed'
    } else {
      return allDwaChecked ? 'Completed' : 'Pending Changes'
    }
  }

  return 'Not Approved'
}

export const groupByTcin = (result = []) => {
  const groupedByTcin = result.reduce((acc, res) => {
    const fieldsChanged = res.fields_changed || []
    fieldsChanged.forEach((field) => {
      const itemsChanged = field.items_changed || []
      const fieldChanged = field.fields_changed || []
      itemsChanged.forEach((item) => {
        console.log(field)
        if (item.tcin) {
          if (!acc[item.tcin]) {
            acc[item.tcin] = []
          }
          acc[item.tcin].push({
            ...item,
            changed_by: res.changed_by,
            date_changed: res.date_changed,
            origin: res.origin,
          })
        }
      })

      let rand = Math.floor(Math.random() * 2000)
      fieldChanged.forEach((field, index) => {
        acc[rand] = acc[rand] || []
        acc[rand].push({
          ...field,
          changed_by: res.changed_by,
          date_changed: res.date_changed,
          notIncluded: true,
          origin: res.origin,
          post_ad_close: res.post_ad_close,
          rowNum: index,
        })
      })
    })
    return acc
  }, {})

  Object.keys(groupedByTcin).forEach((tcin) => {
    groupedByTcin[tcin].sort(
      (a, b) => new Date(b.date_changed) - new Date(a.date_changed)
    )
  })

  const sortedTcinKeys = Object.keys(groupedByTcin).sort((a, b) => {
    const latestDateA = new Date(groupedByTcin[a][0].date_changed)
    const latestDateB = new Date(groupedByTcin[b][0].date_changed)
    return latestDateB - latestDateA
  })

  return { groupedByTcin, sortedTcinKeys }
}
