import { getPyramidColor } from '../../planning/util/ProjectDetailsUtil'

export const priorityOptions = [
  {
    disabled: false,
    label: 'All',
    value: '',
  },
  {
    disabled: false,
    label: 'Enterprise Only',
    value: 'ent',
  },
  {
    disabled: false,
    label: 'Pyramid Only',
    value: 'pyramid',
  },
]

export const pyramidOptions = [
  {
    id: 0,
    label: 'Choose Filter',
    value: '',
  },
  {
    id: 1,
    label: 'Apparel & Accessories',
    value: 'a&a',
  },
  {
    id: 2,
    label: 'Beauty',
    value: 'bty',
  },
  {
    id: 3,
    label: 'Essentials',
    value: 'ess',
  },
  {
    id: 4,
    label: 'Food & Beverage',
    value: 'f&b',
  },
  {
    id: 5,
    label: 'Hardlines',
    value: 'hrdl',
  },
  {
    id: 6,
    label: 'Home',
    value: 'home',
  },
]

export const IvyChannels = {
  online_and_store: 'Online and Store',
  online_only: 'Online Only',
  store_only: 'Store Only',
}

export const filterDisplayPriorities = (
  pyramids = [],
  priorityFilter = '',
  pyramidFilter = ''
) => {
  const pyramidFilterArray = pyramidFilter ? pyramidFilter.split(',') : []
  if (priorityFilter === 'ent') {
    return pyramids.filter((x) => x.pyramid_type === 'ent')
  } else if (priorityFilter === 'pyramid' && pyramidFilter !== '') {
    return pyramids.filter((x) => pyramidFilterArray.includes(x.pyramid_type))
  } else if (priorityFilter === 'pyramid' && pyramidFilter === '') {
    return pyramids.filter((x) => x.pyramid_type !== 'ent')
  } else if (priorityFilter === '' && pyramidFilter !== '') {
    return pyramids.filter(
      (x) =>
        pyramidFilterArray.includes(x.pyramid_type) || x.pyramid_type === 'ent'
    )
  }
  return pyramids
}

export const sortPyramids = (pyramids) => {
  //try const
  let sortedPyramids = []
  /* eslint-disable */
  const allPyrmaids = {
    ent: [],
    aa: [],
    beauty: [],
    essentials: [],
    fb: [],
    hardlines: [],
    home: [],
  }
  /* eslint-disable */

  if (pyramids && pyramids.length > 0) {
    pyramids.forEach((x) => {
      x.color = getPyramidColor(x.pyramid_type).colorCode
      if (x.pyramid_type === 'ent') {
        allPyrmaids.ent.push(x)
      } else if (x.pyramid_type === 'a&a') {
        allPyrmaids.aa.push(x)
      } else if (x.pyramid_type === 'bty') {
        allPyrmaids.beauty.push(x)
      } else if (x.pyramid_type === 'ess') {
        allPyrmaids.essentials.push(x)
      } else if (x.pyramid_type === 'f&b') {
        allPyrmaids.fb.push(x)
      } else if (x.pyramid_type === 'hrdl') {
        allPyrmaids.hardlines.push(x)
      } else if (x.pyramid_type === 'home') {
        allPyrmaids.home.push(x)
      }
    })

    let objValues = Object.values(allPyrmaids)

    objValues.forEach((pyramidGroup) => {
      pyramidGroup = pyramidGroup.sort(
        (a, b) => a.priority_order - b.priority_order
      )
      sortedPyramids = [...sortedPyramids, ...pyramidGroup]
    })
  }

  return sortedPyramids
}

export const calculateDueDates = (deadlineDate = new Date()) => {
  const today = new Date()
  return (
    (deadlineDate.getTime() + 86400000 - today.getTime()) / (1000 * 3600 * 24)
  )
}

export const makeChannelIdQueryParam = (channelIds = []) => {
  return channelIds?.length > 0 ? `?channelIds=${channelIds}` : ''
}

export const makeChannelAndDivisionQueryParam = (
  channels = [],
  divisions = []
) => {
  return (
    (channels?.length > 0 ? `&channels=${channels}` : '') +
    (divisions?.length > 0 ? `&divisions=${divisions}` : '')
  )
}
