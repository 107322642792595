import { Breadcrumbs } from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { useContext } from 'react'
import { Routes, Route, NavLink, useLocation } from 'react-router-dom'

import { BreadcrumbContext } from '../context/Context'
import usePopCookies from '../hooks/usePopCookies'

const HeaderBreadCrumbs = () => {
  const { pathname, wadId } = usePopCookies()
  const productName = 'CreativeHub'
  const { breadcrumbContext } = useContext(BreadcrumbContext)
  const context = breadcrumbContext.row
  const pathString = useLocation()
  const pathArray = pathString.pathname.split('/')
  const outcomePriorityId = pathArray[3]
  const storyId = pathArray[4]
  const umbrellaId = pathArray[5]
  const {
    project_description: projectDescription = '',
    project_id: projectId = '',
    project_name: projectName = '',
    start_date: startDate = '',
    end_date: endDate = '',
  } = context || {}
  const shortDescription = projectDescription.replace(/Week\s(\d)\sQ\d/, 'W$1')
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={pathname}>{productName}</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/planning"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>MKTG Priorities</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/planning/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href="/">{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href="/planning">
              MKTG Priorities
            </Breadcrumbs.Item>
            <Breadcrumbs.Item
              href={`/planning/${projectId}`}
            >{`${projectDescription} - ${projectName}`}</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/createEditProject"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/'}>
              MKTG Priorities - Create Project
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/createEditProject/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href="/">{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/'}>
              MKTG Priorities - Edit Project
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />

      <Route
        path="/publishing"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}> {productName} </Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/publishing'}>
              Category Amplification
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/publishing/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}> {productName} </Breadcrumbs.Item>

            <Breadcrumbs.Item href={'/publishing'}>
              Category Amplification
            </Breadcrumbs.Item>

            <Breadcrumbs.Item href={`/publishing/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/wad-automation"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}> {productName} </Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/wad-automation'}>
              Weekly Ad
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/wad-automation/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}> {productName} </Breadcrumbs.Item>

            <Breadcrumbs.Item href={'/wad-automation'}>
              Weekly Ad
            </Breadcrumbs.Item>

            <Breadcrumbs.Item href={`/wad-automation/${projectId}`}>
              {projectDescription + '   ' + shortDescription + '    '}
              <DateFormatter date={startDate} format="MM/DD/YYYY" />
              {' - '}
              <DateFormatter date={endDate} format="MM/DD/YYYY" />
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/priorityoffers"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}> {productName} </Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/priorityoffers'}>
              Priority Offers
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/priorityoffers/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}> {productName} </Breadcrumbs.Item>

            <Breadcrumbs.Item href={'/priorityoffers'}>
              Priority Offers
            </Breadcrumbs.Item>

            <Breadcrumbs.Item href={`/priorityoffers/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />

      <Route
        path="/groupedOffers"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Grouped Offers</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/groupedOffers/:groupedId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Grouped Offers</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/weeklyad"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Weekly Ad</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/weeklyad/dpci"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/weeklyad'}>Weekly Ad</Breadcrumbs.Item>
            <Breadcrumbs.Item>DPCI Search</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/pastads"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Past Ads</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/weeklyad/:wadId/merchant"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/weeklyad'}>Weekly Ad</Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/weeklyad/${wadId}`}>
              Pagination
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Merchant View</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/weeklyad/:wadId/sample"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/weeklyad'}>Weekly Ad</Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/weeklyad/${wadId}`}>
              Pagination
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Sample View</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/weeklyad/:wadId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/weeklyad'}>Weekly Ad</Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Pagination</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/unauthorized"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Unauthorized</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/weeklyad/:wadId/DWA"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/weeklyad'}>Weekly Ad</Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/weeklyad/${wadId}`}>
              Pagination
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>DWA</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/change-tracking"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/change-tracking'}>
              Change Tracking
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/change-tracking/:wadId/:type"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/change-tracking'}>
              Change Tracking
            </Breadcrumbs.Item>
            <Breadcrumbs.Item
              href={pathname}
            >{`History ${wadId}`}</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/catalog/:catalogId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/catalog'}>Catalog</Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/catalog/${wadId}`}>
              Pagination
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/catalog"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/catalog'}>Catalog</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/past-catalogs"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/past-catalogs'}>
              Past Catalog
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/catalog/:catalogId/product-list"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/catalog'}>Catalog</Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/catalog/${wadId}/product-list`}>
              Product List
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/catalog/:catalogId/creative"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/catalog'}>Catalog</Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/catalog/${wadId}/creative`}>
              Catalog Page View
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/catalog/:catalogId/sample"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/catalog'}>Catalog</Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/catalog/${wadId}/sample`}>
              Image Sample View
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/catalog/:catalogId/history"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/catalog'}>Catalog</Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/catalog/${wadId}/history`}>
              'Product History'
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/vendor"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/vendor'}>Vendors</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/admin"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/admin'}>Admin-Center</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/contacts"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/contacts'}>Contacts</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/defaultfilters"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/defaultfilters'}>
              Default Filters
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/changeSummary"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>

            <Breadcrumbs.Item href={'/changeSummary'}>
              Change Summary
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/changeSummary/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>

            <Breadcrumbs.Item href={'/changeSummary'}>
              Change Summary
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/changeSummary/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/v1/changeSummary"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>

            <Breadcrumbs.Item href={'/v1/changeSummary'}>
              V1 Change Summary
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/v1/changeSummary/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>

            <Breadcrumbs.Item href={'/v1/changeSummary'}>
              V1 Change Summary
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/v1/changeSummary/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/execution"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={pathname}>Promo Offers</Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/execution/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/execution'}>
              Promo Offers
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/execution/:projectId/:outcomePriorityId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/execution'}>
              Promo Offers
            </Breadcrumbs.Item>
            <Breadcrumbs.Item
              href={`/execution/${projectId}/${outcomePriorityId}`}
            >
              {projectDescription}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/execution/:projectId/:outcomePriorityId/:storyId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/execution'}>
              Promo Offers
            </Breadcrumbs.Item>
            <Breadcrumbs.Item
              href={`/execution/${projectId}/${outcomePriorityId}/${storyId}`}
            >
              {projectDescription}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/execution/:projectId/:outcomePriorityId/:storyId/:umbrellaId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/execution'}>
              Promo Offers
            </Breadcrumbs.Item>
            <Breadcrumbs.Item
              href={`/execution/${projectId}/${outcomePriorityId}/${storyId}/${umbrellaId}`}
            >
              {projectDescription}
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/execution/items/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/execution'}>
              Promo Offers
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/items/${projectId}`}>
              Image Tracking List
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />

      <Route
        path="/execution/projectSummary/:projectId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/execution'}>
              Promo Offers
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/projectSummary/${projectId}`}>
              Project Summary List
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />

      <Route
        path="/execution/creativeSummary/:projectId/:storyId"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/execution'}>
              Promo Offers
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/${projectId}/${storyId}`}>
              Creative Summary
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
      <Route
        path="/execution/creativeSummary/:projectId/:storyId/:umbrellaId/:umbrellaOrder"
        element={
          <Breadcrumbs crumbAs={NavLink}>
            <Breadcrumbs.Item href={'/'}>{productName}</Breadcrumbs.Item>
            <Breadcrumbs.Item href={'/execution'}>
              Promo Offers
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/${projectId}`}>
              {projectDescription}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item href={`/execution/${projectId}/${storyId}`}>
              Creative Summary
            </Breadcrumbs.Item>
          </Breadcrumbs>
        }
      />
    </Routes>
  )
}
export default HeaderBreadCrumbs
