import { Chip } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import {
  getChipValues,
  pyramid_options,
} from '../../planning/util/ProjectDetailsUtil'
import { getPyramidOptionsByValues } from '../../planning/util/ProjectDetailsUtil'
const MarketingPyramidSelect = ({
  selectedMarketingPriorityFilter,
  selectedMarketingPyramidFilter,
  setMarketingPriorityFilter,
  setMarketingPyramidFilter,
  disabled,
  handleFilterUpdate,
}) => {
  const handleUpdate = (_, value) => {
    const pyramidTypes = value?.map((pyramid) => pyramid.value).join(',')
    setMarketingPyramidFilter(pyramidTypes)
    handleFilterUpdate()
  }

  return (
    <>
      <Autocomplete
        id="marketingPyramidSelect"
        grow
        chipHeight="dense"
        label="Pyramid"
        placeholder="Filter Pyramids"
        onUpdate={handleUpdate}
        options={pyramid_options}
        multiselect
        value={getPyramidOptionsByValues(selectedMarketingPyramidFilter)}
        renderChip={(cat, onRequestDelete) => (
          <Chip
            size="dense"
            style={{
              backgroundColor: cat.color,
              border: '0px',
              color: 'white',
            }}
            onRequestDelete={onRequestDelete}
          >
            {getChipValues(cat.value)}
          </Chip>
        )}
        disabled={selectedMarketingPriorityFilter === 'ent' || disabled}
        data-testid="marketingPyramidSelect"
      />
    </>
  )
}

export default MarketingPyramidSelect
