import { Layout, ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { useEffect, useState, useMemo } from 'react'

import DefaultFilterApi from '../api/creative-hub/DefaultFilterApi'
import LoadingSpinner from '../components/LoadingSpinner'
import appConfig from '../config/appConfig'
import { EffectiveRoleContext } from '../context/Context'
import usePopCookies from '../hooks/usePopCookies'
import { isAdmin } from '../util/CheckRole'
import { ErrorHandler } from '../WeeklyAdAutomation/util/ErrorHandler'

import Header from './Header'
import { MainRouter } from './MainRouter'
import PopNavigation from './PopNavigation'

const PopLayout = () => {
  const { toSampleUrl, pathname, wadId, archive } = usePopCookies()
  const { session } = useAuth()
  const [loading, setLoading] = useState(false)
  const [isSideNavOpen, setIsSideNavOpen] = useState(null)
  const [userDefinedFilters, setUserDefinedFilters] = useState({})
  const [isUserDefinedFilterLoading, setIsUserDefinedFilterLoading] =
    useState(true)
  const defaultFilterApi = useMemo(() => new DefaultFilterApi(), [])
  const makeToast = ToastProvider.useToaster()

  const gridColumn = '1/span 5'
  const bodyToolBarStyle =
    pathname.startsWith('/planning') ||
    pathname.startsWith('/groupedOffers') ||
    pathname.startsWith('/publishing') ||
    pathname.startsWith('/execution')
      ? { margin: '0px', padding: '0px' }
      : null

  const role = session?.userInfo.memberOf
  const targetRole =
    session && session.userInfo && session.userInfo.memberOf
      ? session.userInfo.memberOf.filter(
          (targetRole) =>
            targetRole.includes('APP-OAUTH2-CRTVHUB') ||
            targetRole.includes('APP-PAO-WRITE-')
        )
      : []

  targetRole.sort()

  const vendorRole =
    session && session.userInfo && session.userInfo.memberOf
      ? session.userInfo.memberOf.filter((vendorRole) =>
          vendorRole.match(/^APP-CTLG$/)
        )
      : []

  const isVendorRestrictedAccess =
    vendorRole.length &&
    vendorRole[0] != null &&
    targetRole.length === 0 &&
    targetRole[0] == null

  const [effectiveRole, setEffectiveRole] = useState(role)
  const config = appConfig()
  const targetRoles = effectiveRole?.some((role) => isAdmin(role))
    ? config.adGroups
    : targetRole

  const handleSideNavToggle = () => {
    setIsSideNavOpen(!isSideNavOpen)
  }

  const closeSideNav = () => {
    setIsSideNavOpen(false)
  }

  useEffect(() => {
    fetchUserSetDefaultFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchUserSetDefaultFilters = async () => {
    try {
      const response = await defaultFilterApi.getDefaults()
      let { view_filters: viewFilters = [] } = response

      let filterObj = {}
      viewFilters?.forEach((filter) => {
        filterObj[filter.view_name] = {
          filters: filter.filters,
          view_name: filter.view_name,
        }
      })
      setUserDefinedFilters(filterObj || {})
      setIsUserDefinedFilterLoading(false)
    } catch (error) {
      makeToast({ message: ErrorHandler(error), type: 'error' })
      setUserDefinedFilters({})
      setIsUserDefinedFilterLoading(false)
    }
  }

  return (
    <EffectiveRoleContext.Provider
      value={{
        effectiveRole,
        isUserDefinedFilterLoading,
        setEffectiveRole,
        setUserDefinedFilters,
        userDefinedFilters,
      }}
    >
      <Layout
        darkMode={false}
        style={{
          position: 'relative',
        }}
      >
        <Header
          onSideNavToggle={handleSideNavToggle}
          pathname={pathname}
          effectiveRole={effectiveRole}
          isSideNavOpen={isSideNavOpen}
        />
        <PopNavigation
          showSidebar={isSideNavOpen}
          wadId={wadId}
          toSampleUrl={toSampleUrl}
          onRequestClose={handleSideNavToggle}
          closeSideNav={closeSideNav}
          setLoading={setLoading}
          targetRole={targetRoles}
          archive={archive}
        />
        <Layout.Body
          includeRail
          style={{ ...bodyToolBarStyle, gridColumn }}
          onClick={closeSideNav}
        >
          <MainRouter
            isVendor={isVendorRestrictedAccess}
            effectiveRole={effectiveRole}
            toSampleUrl={toSampleUrl}
            archive={archive}
          />
        </Layout.Body>
        <LoadingSpinner show={loading} />
      </Layout>
    </EffectiveRoleContext.Provider>
  )
}
export default PopLayout
