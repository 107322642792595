import { Form, Grid } from '@enterprise-ui/canvas-ui-react'

const ChannelDropdown = ({
  id,
  title,
  disabled,
  onChange,
  detailsObj,
  setChangeDetected,
}) => {
  const channelId =
    title === 'Audio'
      ? 10
      : title === 'Video'
        ? 9
        : title === 'Circle'
          ? 15
          : title === 'Banner'
            ? 8
            : 0

  const channelOptions = [
    {
      label: '',
      value: null,
    },
    {
      label: 'assigned',
      value: 'assigned',
    },
    {
      label: 'unassigned',
      value: 'unassigned',
    },
  ]

  return (detailsObj !== undefined) & (detailsObj !== null) ? (
    <Grid.Container className="category-container-row">
      <Grid.Item className="category-label" xs={2}>
        {title}
      </Grid.Item>
      <Grid.Item className="category-input" xs={10}>
        <Form.Field
          type="select"
          disabled={
            !disabled && detailsObj?.channel_id === channelId ? false : true
          }
          onUpdate={(id, value) => {
            onChange(value, 'state', title)
            setChangeDetected(true)
          }}
          options={channelOptions}
          value={detailsObj?.state !== undefined ? detailsObj?.state : ''}
          id={`${title}-selectbox-${id}`}
        />
      </Grid.Item>
    </Grid.Container>
  ) : (
    <Grid.Container className="category-container-row">
      <Grid.Item className="category-input" xs={12}>
        <div className="no-audio-filler" />
      </Grid.Item>
    </Grid.Container>
  )
}

export default ChannelDropdown
