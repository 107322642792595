import { Modal, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import { ColorBox, createColor } from 'material-ui-color'
import { useState, useContext, useEffect } from 'react'

import { CanEditContext, WeeklyAdAutomationContext } from '../context/Context'

import './scss/pages.scss'
import { DEFAULT_COLOR } from './util/Constants'
const CustomColorPicker = ({
  isColorModalOpen,
  setIsColorModalOpen,
  pageColor,
  pageId,
  pageNumber,
}) => {
  const { updatePageDetails } = useContext(WeeklyAdAutomationContext)
  const { canEdit } = useContext(CanEditContext)
  const [color, setColor] = useState()
  const handleChoose = () => {
    const { css = DEFAULT_COLOR } = color
    const { backgroundColor = DEFAULT_COLOR } = css
    const payload = { page_color: backgroundColor }
    updatePageDetails(pageId, payload)
    setIsColorModalOpen(false)
  }
  useEffect(() => {
    if (isColorModalOpen) setColor(createColor(pageColor))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isColorModalOpen])
  const handleClose = () => {
    setIsColorModalOpen(false)
  }
  const handleChange = (color) => {
    if (canEdit) {
      setColor(color)
    }
  }
  return (
    <Modal
      isVisible={isColorModalOpen}
      headingText={`PAGE ${pageNumber} - Choose Page Color`}
      onRefuse={() => setIsColorModalOpen(false)}
      className="color-picker-modal"
    >
      <div className="hc-pa-normal">
        <Grid.Container spacing="dense" direction="column">
          <Grid.Item>
            <ColorBox
              defaultValue={DEFAULT_COLOR}
              value={canEdit ? color : pageColor}
              onChange={handleChange}
            />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container direction="row-reverse" spacing="dense">
          <Grid.Item>
            <Button onClick={handleChoose} type="primary" disabled={!canEdit}>
              CHOOSE
            </Button>
          </Grid.Item>
          <Grid.Item>
            <Button onClick={handleClose} type="secondary">
              CANCEL
            </Button>
          </Grid.Item>
        </Grid.Container>
      </div>
    </Modal>
  )
}

export default CustomColorPicker
