import {
  Anchor,
  ExpandableSection,
  Grid,
  Input,
  Image,
  InputRating,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import { useState } from 'react'

import OfferApi from '../../api/creative-hub/OfferApi'
import { assethubImageCheck, promoPrice } from '../../execution/util/ItemUtil'
import noImagePlaceholder from '../../media/NoImagePlaceholder.png'
import { updateBoostedTcins, isBoosted } from '../util/helpers'
import '../../execution/scss/Item.scss'

const PublishingItem = (props) => {
  const {
    picturedItem,
    canEdit,
    setIsItemImageModalOpen,
    setItemSelected,
    isArtPatch,
    isMixedDiscount,
    pricePatches,
    selectedStoryId,
    boostedTcins,
    setBoostedTcins,
    showPriority,
    setChangeDetected,
  } = props
  const [expandItem, setExpandItem] = useState(false)

  const offerApi = new OfferApi()

  const updateValue = async (data = {}, field, value) => {
    if (!data[field] || picturedItem[field] !== value) {
      const payload = {
        dpci: data.dpci,
        [field]: value,
        item_id: data.item_id,
        ivy_offer_id: picturedItem.ivy_offer_id,
        offer_id: data.offer_id,
        pyramid_name: data.pyramid_name,
        story_id: selectedStoryId,
        tcin: data.tcin,
      }
      // update = data
      try {
        return await offerApi.updateOfferItems([payload])
      } catch (error) {
        console.log(error)
        return null
      }
    }
  }

  const incompleteItem = (item) => {
    var incomplete = true
    if (item.image_type && item.image_type !== null) incomplete = false
    return incomplete
  }

  const flipPriority = (priority) => (priority === 1 ? 0 : 1)

  const handleItemImageModal = (picturedItem = {}) => {
    if (!canEdit) {
      return true
    }

    setIsItemImageModalOpen(true)
    setItemSelected(picturedItem)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        alt_image: picturedItem.creativehub_alt_images || noImagePlaceholder,
        art_patch_copy: picturedItem.art_patch_copy || '',
        art_patch_markets: picturedItem.art_patch_markets || '',
        created_by: picturedItem.created_by || '',
        created_date: picturedItem.created_date || '',
        deleted: picturedItem.deleted || '',
        dpci: picturedItem.dpci || '',
        file_image_name: picturedItem.file_image_name || '',
        image_type: picturedItem.image_type || '',
        is_priority: isBoosted(boostedTcins, picturedItem.tcin) ? 1 : 0,
        item_id: picturedItem.item_id || '',
        item_name: picturedItem.item_name || '',
        item_order: picturedItem.item_order || '',
        ivy_offer_id: picturedItem.ivy_offer_id || '',
        merch_notes: picturedItem.merch_notes || '',
        model: Boolean(picturedItem.model),
        offer_id: picturedItem.offer_id || '',
        primary_image_url: picturedItem.primary_image_url || noImagePlaceholder,
        pyramid_name: picturedItem.pyramid_name || '',
        reg_callout: Boolean(picturedItem.reg_callout),
        reg_price: picturedItem.reg_price || '',
        sample_collected: picturedItem.sample_collected || '',
        sample_id: picturedItem.sample_id || '',
        sample_status: picturedItem.sample_status || '',
        shooting: picturedItem.shooting || '',
        tcin: picturedItem.tcin || '',
      }}
    >
      {({ values, setFieldValue }) => {
        const {
          reg_price = '',
          file_image_name = '',
          art_patch_copy = '',
          art_patch_markets = '',
          reg_callout = '',
          image_type = '',
          primary_image_url = '',
          tcin = '',
          dpci = '',
          item_name = '',
          model = '',
          sample_id = '',
          sample_status = '',
          merch_notes = '',
          is_priority = '',
        } = values
        return (
          <ExpandableSection padding="none" expanded={expandItem}>
            <Grid.Container
              align="center"
              spacing="narrow"
              data-testid="publishingItemContainer"
            >
              <Grid.Item xs={1}>
                <div>
                  <FontAwesomeIcon
                    style={{ fill: 'black' }}
                    className={`clickable item-expander ${
                      expandItem
                        ? 'rotate-clockwise-45'
                        : 'rotate-counter-clockwise-45'
                    }`}
                    icon={faAngleDown}
                    onClick={(e) => {
                      setExpandItem(!expandItem)
                    }}
                    size="sm"
                    data-testid="expand-publish-media-units"
                  />
                </div>
              </Grid.Item>
              <Grid.Item xs={4} className="no-pointer">
                <Image
                  className="publishing-item-image"
                  src={primary_image_url}
                  alt={tcin}
                />
              </Grid.Item>
              <Grid.Item
                xs={7}
                className={
                  incompleteItem(values)
                    ? 'incompleteItem no-pointer'
                    : 'itemDetails no-pointer'
                }
              >
                {isArtPatch && (
                  <div data-testid={'publishing-item-markets'}>
                    <strong> MARKETS:</strong> {art_patch_markets}
                  </div>
                )}
                <div data-testid="item-tcin">
                  <strong>TCIN: </strong> {tcin}
                </div>
                {showPriority ? (
                  <InputRating
                    disabled={!canEdit}
                    className="item-priority-indicator"
                    total={1}
                    value={is_priority}
                    onClick={(e) => {
                      var newPriority = flipPriority(is_priority)
                      setFieldValue('is_priority', newPriority)
                      setBoostedTcins(
                        updateBoostedTcins(boostedTcins, tcin, newPriority)
                      )
                      setChangeDetected(true)
                      e.stopPropagation()
                    }}
                  />
                ) : null}
                <div>
                  <strong>DPCI: </strong> {dpci}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item_name,
                  }}
                />
              </Grid.Item>
              {assethubImageCheck(primary_image_url, noImagePlaceholder) && (
                <Grid.Item xs={10} className="itemAssethubDownload">
                  AssetHub Image{' '}
                  <Anchor href={`${primary_image_url}&is_download=true`}>
                    Download{' '}
                  </Anchor>{' '}
                  /{' '}
                  <a
                    className="C-Anchor"
                    target="_blank"
                    href={primary_image_url}
                    rel="noreferrer"
                  >
                    Preview
                  </a>
                </Grid.Item>
              )}
            </Grid.Container>
            <ExpandableSection.Content style={{ padding: 8 }}>
              <Grid.Item xs={12} className="itemDetails">
                <hr />
                <Grid.Container spacing={1}>
                  <Grid.Item xs={6} className="itemGrid">
                    <Input.Label>Reg $</Input.Label>
                    <Input.Text id="reg_price" value={reg_price} disabled />
                  </Grid.Item>
                  <Grid.Item xs={6} className="itemGrid">
                    <Input.Label>Reg $ Callout</Input.Label>
                    <Input.Select
                      id="reg_callout"
                      defaultValue={reg_callout}
                      disabled={!canEdit}
                      options={[
                        {
                          label: 'No',
                          value: false,
                        },
                        {
                          label: 'Yes',
                          value: true,
                        },
                      ]}
                      onUpdate={(id, value) => {
                        setFieldValue('reg_callout', value)
                        updateValue(values, id, value)
                      }}
                    />
                  </Grid.Item>
                  {isMixedDiscount && (
                    <Grid.Item xs={12} className="itemGrid">
                      <Input.Label>Promo $ (Mixed Discount)</Input.Label>
                      <Input.Text
                        id="promoPrice"
                        value={promoPrice(pricePatches, picturedItem)}
                        disabled
                      />
                    </Grid.Item>
                  )}
                  {isArtPatch && (
                    <Grid.Item xs={12} className="itemGrid">
                      <Input.Label>Art Patch Copy</Input.Label>
                      <Input.Text
                        id="art_patch_copy"
                        value={art_patch_copy}
                        onChange={(event) => {
                          setFieldValue('art_patch_copy', event.target.value)
                        }}
                        onBlur={(event) => {
                          updateValue(
                            values,
                            event.target.id,
                            event.target.value
                          )
                        }}
                      />
                    </Grid.Item>
                  )}
                  <Grid.Item xs={12} className="itemGrid">
                    <Input.Label>File Image Name</Input.Label>
                    <Input.Text
                      id="file_image_name"
                      value={file_image_name}
                      onChange={(event) => {
                        setFieldValue('file_image_name', event.target.value)
                      }}
                      onBlur={(event) => {
                        updateValue(values, event.target.id, event.target.value)
                      }}
                      disabled={!canEdit}
                    />
                  </Grid.Item>
                  <Grid.Item xs={4} className="itemGrid">
                    <Tooltip content="Click to search AssetHub" location="top">
                      <img
                        onClick={() => handleItemImageModal(picturedItem)}
                        style={{ height: '80%', width: '80%' }}
                        src={primary_image_url}
                        alt={tcin}
                      />
                    </Tooltip>
                  </Grid.Item>
                  <Grid.Item xs={4} className="itemGrid">
                    <Tooltip content="Click to search AssetHub" location="top">
                      <img
                        onClick={() => handleItemImageModal(picturedItem)}
                        style={{ height: '80%', width: '80%' }}
                        src={
                          picturedItem?.creativehub_alt_images &&
                          picturedItem?.creativehub_alt_images[0]?.image_url
                            ? picturedItem?.creativehub_alt_images[0]?.image_url
                            : noImagePlaceholder
                        }
                        alt={tcin}
                      />
                    </Tooltip>
                  </Grid.Item>
                  <Grid.Item xs={4} className="itemGrid ">
                    <Tooltip content="Click to search AssetHub" location="top">
                      <img
                        onClick={() => handleItemImageModal(picturedItem)}
                        style={{ height: '80%', width: '80%' }}
                        src={
                          picturedItem?.creativehub_alt_images &&
                          picturedItem?.creativehub_alt_images[1]?.image_url
                            ? picturedItem?.creativehub_alt_images[1]?.image_url
                            : noImagePlaceholder
                        }
                        alt={tcin}
                      />
                    </Tooltip>
                  </Grid.Item>

                  <Grid.Item xs={6} className="itemGrid">
                    <Input.Label>Image Type</Input.Label>
                    <Input.Select
                      id="image_type"
                      data-testid="select-image-options"
                      defaultValue={image_type}
                      disabled={!canEdit}
                      options={[
                        {
                          label: '',
                          value: '',
                        },
                        {
                          label: 'Internal',
                          value: 'internal',
                        },
                        {
                          label: 'External',
                          value: 'external',
                        },
                        {
                          label: 'Re-use',
                          value: 're-use',
                        },
                      ]}
                      onUpdate={(id, value) => {
                        setFieldValue('image_type', value)
                        updateValue(values, id, value)
                      }}
                    />
                    <span className="C-FormField__Label__itemTypeRequired">
                      Required Field
                    </span>
                  </Grid.Item>
                  <Grid.Item xs={6} className="itemGrid">
                    <Input.Label>Sample Status</Input.Label>
                    <Input.Select
                      id="sample_status"
                      defaultValue={sample_status}
                      disabled={!canEdit}
                      options={[
                        {
                          disabled: false,
                          label: '',
                          value: '',
                        },
                        {
                          disabled: false,
                          label: 'Inventory',
                          value: 'inventory',
                        },
                        {
                          disabled: false,
                          label: 'Sample Ordered',
                          value: 'sample-ordered',
                        },
                        {
                          disabled: false,
                          label: 'No Sample Available',
                          value: 'no-sample-available',
                        },
                      ]}
                      onUpdate={(id, value) => {
                        setFieldValue('sample_status', value)
                        updateValue(values, id, value)
                      }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={6} className="itemGrid">
                    <Input.Label>Fig/Model</Input.Label>
                    <Input.Select
                      id="model"
                      defaultValue={model}
                      disabled={!canEdit}
                      options={[
                        {
                          label: 'No',
                          value: false,
                        },
                        {
                          label: 'Yes',
                          value: true,
                        },
                      ]}
                      onUpdate={(id, value) => {
                        setFieldValue('model', value)
                        updateValue(values, id, value)
                      }}
                    />
                  </Grid.Item>

                  <Grid.Item xs={6} className="itemGrid">
                    <Input.Label>Sample Id</Input.Label>
                    <Input.Text
                      id="sample_id"
                      value={sample_id}
                      onChange={(event) => {
                        setFieldValue('sample_id', event.target.value)
                      }}
                      onBlur={(event) => {
                        updateValue(values, event.target.id, event.target.value)
                      }}
                      disabled={!canEdit}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12} className="itemGrid">
                    <Input.Label>Merch Product Notes</Input.Label>
                    <Input.Text
                      id="merch_notes"
                      value={merch_notes}
                      onChange={(event) => {
                        setFieldValue('merch_notes', event.target.value)
                      }}
                      onBlur={(event) => {
                        updateValue(values, event.target.id, event.target.value)
                      }}
                      grow={true}
                      disabled={!canEdit}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
            </ExpandableSection.Content>
          </ExpandableSection>
        )
      }}
    </Formik>
  )
}

export default PublishingItem
