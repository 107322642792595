import {
  Button,
  Card,
  Grid,
  GridContainer,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleDown,
  faFilePdf,
  faTv,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { sortBy } from 'lodash'
import { useCallback, useState, useRef, useEffect, useContext } from 'react'

import ProjectApi from '../../api/creative-hub/ProjectApi'
import PyramidApi from '../../api/creative-hub/PyramidApi'
import DialogView from '../../containers/DialogView'
import {
  CanEditContext,
  EnterpriseLinkedUmbrellasContext,
} from '../../context/Context'
import { handleFireflyClickInteraction } from '../../util/FireflyHelper'
import { displayChannelsByPyramidType } from '../util/ChannelUtil'
import { getPyramidFullName } from '../util/ProjectDetailsUtil'
import { getPyramidColor } from '../util/ProjectDetailsUtil.js'
import { createPyramidRequest } from '../util/PyramidUtil'

import InlineEditInput from './InlineEditInput'
import MarketChannelPdf from './MarketChannelPDF'
import PyramidDetails from './PyramidDetails'
import UmbrellaChannelSelector from './UmbrellaChannelSelector'

import '../scss/Bucket.scss'

const PyramidBucket = (props) => {
  const {
    addChannelToUmbrella,
    channelsDialogIsVisible,
    channelsPDFPreviewIsVisible,
    removeChannelFromUmbrella,
    addAllChannelsToUmbrella,
    removeAllChannelsFromUmbrella,
    channels = [],
    channelFilters,
    handleToggleChannelDialog,
    handleTogglePDFPreview,
    uniqueId,
    pyramidType,
    pyramidList,
    isVisible,
    projectId,
    projectTitle,
    projectStartDate,
    projectEndDate,
    selectedChannelBucket,
    session = {},
    setSelectedChannelBucket,
    setRefreshData,
    setRefreshPyramidData,
    setUmbrellaIsDropped,
    setSelectedUmbrellas,
  } = props
  const pyramidTitle = pyramidType ? getPyramidFullName(pyramidType) : 'N/A'
  const filteredChannels = displayChannelsByPyramidType(pyramidType, channels)
  const [channelUmbrellas, setChannelUmbrellas] = useState([])
  const [assignedValues, setAssignedValues] = useState([])
  const [pyramidElements, setPyramidElements] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [expandAllPyramids, setExpandAllPyramids] = useState(false)
  const [expandedPyramids, setExpandedPyramids] = useState([])
  const [isPyramidDraggable, setIsPyramidDraggable] = useState(true)
  const [selectedPyramid, setSelectedPyramid] = useState({})
  const [isUmbrellaChannelsLoading, setIsUmbrellaChannelsLoading] =
    useState(false)
  const { canEdit } = useContext(CanEditContext)
  const enterpriseLinkedUmbrellas = useContext(EnterpriseLinkedUmbrellasContext)

  const loadUmbrellaChannels = useCallback(async () => {
    setIsUmbrellaChannelsLoading(true)
    const projectApi = new ProjectApi()
    const pyramids = await projectApi.findUmbrellaChannelsByPyramid({
      projectId,
      pyramidType,
    })

    pyramids && setIsUmbrellaChannelsLoading(false)
    const ps = pyramids
      .sort((a, b) => a.priority_order - b.priority_order)
      .map((pyramid) => {
        const sorted = pyramid.stories.sort(
          (a, b) => a.story_order - b.story_order
        )

        return sorted.map((story) => ({
          ...story,
          guest_outcome_name: pyramid.pyramid_name,
          guest_outcomes: pyramid.guest_outcomes,
          pyramid_id: pyramid.pyramid_id,
        }))
      })
      .flat()

    const cUmbrellas = ps.reduce(
      (prevUmbrellas, currStory) =>
        currStory.umbrellas
          ? [
              ...prevUmbrellas,
              ...[
                ...currStory.umbrellas
                  .sort((a, b) => a.umbrella_order - b.umbrella_order)
                  .map((umbrella) => ({
                    ...umbrella,
                    guest_outcome_name: currStory.guest_outcome_name,
                    guest_outcomes: currStory.guest_outcomes,
                    id: umbrella.planning_umbrella_id,
                    pyramid_id: currStory.pyramid_id,
                    story_id: currStory.story_id,
                    story_name: currStory.story_name,
                  })),
              ],
            ]
          : prevUmbrellas,
      []
    )
    const assignedChannels = cUmbrellas.reduce(
      (prevUmbrellas, currUmbrellas) =>
        currUmbrellas.channels
          ? [
              ...prevUmbrellas,
              ...currUmbrellas.channels.map((channel) => ({
                channel_id: channel.channel_id,
                umbrella_id: currUmbrellas.id,
              })),
            ]
          : prevUmbrellas,
      []
    )
    setChannelUmbrellas(cUmbrellas)
    setAssignedValues(assignedChannels)
  }, [projectId, pyramidType])

  useEffect(() => {
    setPyramidElements(pyramidList)
  }, [loadUmbrellaChannels, pyramidList, pyramidType, selectedChannelBucket])

  const sortedElements = sortBy(pyramidElements, (a) => a.priority_order)
  const draggingItem = useRef()
  const dragOverItem = useRef()
  const pyramidApi = new PyramidApi()

  const handleLoadUmbrellaChannels = () => {
    loadUmbrellaChannels()
    setSelectedChannelBucket(pyramidType)
    handleToggleChannelDialog()
  }

  const handleLoadUmbrellaPDFPreview = () => {
    loadUmbrellaChannels()
    setSelectedChannelBucket(pyramidType)
    handleTogglePDFPreview()
  }

  const handleDragStart = (event, position) => {
    event.dataTransfer.setData('drag-pyramid', true)
    draggingItem.current = position
  }

  const dragEnter = (event) => {
    event.dataTransfer.types.includes('drag-pyramid') && event.preventDefault()
  }

  const dragOver = (event) => {
    event.dataTransfer.types.includes('drag-pyramid') && event.preventDefault()
  }

  const handleDrop = (element, position, elementType) => {
    if (elementType === 'pyramid') {
      dragOverItem.current = position
      const pyramidsCopy = [...sortedElements]
      const draggingItemContent = pyramidsCopy[draggingItem.current]
      if (
        pyramidsCopy[dragOverItem.current] &&
        pyramidsCopy[draggingItem.current] &&
        draggingItem.current !== dragOverItem.current
      ) {
        pyramidsCopy.splice(draggingItem.current, 1)
        pyramidsCopy.splice(dragOverItem.current, 0, draggingItemContent)
        let end, start
        if (draggingItem.current < dragOverItem.current) {
          start = draggingItem.current
          end = dragOverItem.current
        } else {
          start = dragOverItem.current
          end = draggingItem.current
        }
        //only update changed indicies
        let updatedPyramidList = []
        for (let index = start; index <= end; index++) {
          pyramidsCopy[index].priority_order = index + 1
          updatedPyramidList.push(pyramidsCopy[index])
        }
        pyramidApi.updatePyramids(updatedPyramidList)
        setPyramidElements(pyramidsCopy)
      }
    }
  }

  const handleChannelClick = (channelId, umbrellaId) => {
    const channelAssigned = assignedValues.some(
      (val) => channelId === val.channel_id && umbrellaId === val.umbrella_id
    )

    if (channelAssigned) {
      setAssignedValues((prev) => {
        const filtered = prev.filter(
          (prev) =>
            prev.channel_id !== channelId || prev.umbrella_id !== umbrellaId
        )
        return filtered
      })
      removeChannelFromUmbrella({
        channel_id: channelId,
        project_id: projectId,
        umbrella_id: umbrellaId,
      })
    } else {
      setAssignedValues((prev) => [
        ...prev,
        { channel_id: channelId, umbrella_id: umbrellaId },
      ])
      addChannelToUmbrella({
        channel_id: channelId,
        project_id: projectId,
        umbrella_id: umbrellaId,
      })
    }
  }
  const handleSelectAllClick = (
    channelId,
    projectId,
    pyramidType,
    selectAll,
    umbrellas
  ) => {
    let mappedData =
      umbrellas?.map((umbrella) => ({
        channel_id: channelId,
        umbrella_id: umbrella.id,
      })) || []

    if (selectAll) {
      setAssignedValues((prev) => {
        const combined = [...prev, ...mappedData]
        const uniqueValues = combined.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (v) =>
                v &&
                v.channel_id === value.channel_id &&
                v.umbrella_id === value.umbrella_id
            )
        )
        return uniqueValues
      })
    } else {
      setAssignedValues((prev) =>
        prev.filter((prev) => prev.channel_id !== channelId)
      )
    }

    addAllChannelsToUmbrella({
      channel_id: channelId,
      project_id: projectId,
      pyramid_type: pyramidType,
      select_all: selectAll,
    })
  }

  const createPyramid = (pyramidType, pyramidOrder) => {
    const pyramidRequest = createPyramidRequest(
      pyramidType,
      pyramidOrder + 1,
      projectId
    )
    pyramidApi
      .createPyramid(pyramidRequest)
      .then((res) => {
        res && setRefreshData(true)
        handleFireflyClickInteraction(
          'pageload',
          'MKTG Priorities: Add Pyramid',
          'success',
          session,
          {
            message: `pyramidType: ${pyramidType} - pyramidOrder: ${pyramidOrder}`,
            start_date: projectStartDate,
          }
        )
        return res
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deletePyramid = async (pyramid = {}) => {
    pyramidApi.deletePyramid(pyramid).finally(() => {
      setRefreshData(true)
      const { pyramid_order = 0, pyramid_type = '' } = pyramid
      handleFireflyClickInteraction(
        'pageload',
        'MKTG Priorities: Delete Pyramid',
        'success',
        session,
        {
          message: `pyramidType: ${pyramid_type} - pyramidOrder: ${pyramid_order}`,
          start_date: projectStartDate,
        }
      )
    })
  }

  const handleExpandAllPyramids = () => {
    if (!expandAllPyramids === false) {
      setExpandedPyramids([])
    }
    setExpandAllPyramids(!expandAllPyramids)
  }

  const handleExpandSinglePyramid = (pyramidId) => {
    setExpandAllPyramids(false)

    if (expandedPyramids.length > 0 && expandedPyramids.includes(pyramidId)) {
      setExpandedPyramids(expandedPyramids.filter((id) => id !== pyramidId))
    } else {
      setExpandedPyramids([...expandedPyramids, pyramidId])
    }
  }

  return pyramidElements ? (
    <>
      <div
        // hc-pa-normal
        className={isVisible ? 'visibleBucket' : 'hiddenBucket'}
        style={{
          backgroundColor: getPyramidColor(pyramidType).colorCode,
        }}
        key={pyramidType + '-' + uniqueId}
        data-testid="pyramids"
      >
        <Grid.Container className="pyramidText" justify="space-between">
          <Grid.Item>
            <h2 className="pyramid-title-container">
              <FontAwesomeIcon
                display={'inline-block'}
                className={`clickable pyramid-expander ${
                  expandAllPyramids
                    ? 'rotate-clockwise-45'
                    : 'rotate-counter-clockwise-45'
                }`}
                icon={faAngleDown}
                onClick={(e) => {
                  e.preventDefault()
                  handleExpandAllPyramids()
                }}
                size="lg"
                data-testid="expand-all-pyramids"
              />
              {pyramidTitle}
            </h2>
          </Grid.Item>
          <Grid.Item id="addBucket">
            <FontAwesomeIcon
              className="labelledIcon clickable"
              onClick={handleLoadUmbrellaPDFPreview}
              icon={faFilePdf}
              size="lg"
            />
            <FontAwesomeIcon
              className="labelledIcon clickable"
              onClick={handleLoadUmbrellaChannels}
              icon={faTv}
              size="lg"
            />
            {canEdit ? (
              <FontAwesomeIcon
                className="labelledIcon"
                icon={faPlusCircle}
                size="lg"
                onClick={() => createPyramid(pyramidType, pyramidList.length)}
                id="addBucket"
                data-testid="addPyramid"
                data-cy={`addBucket-${pyramidTitle}`}
              />
            ) : null}
          </Grid.Item>
        </Grid.Container>
        {isModalVisible && (
          <div data-testid="pyramidConfirmationModal">
            <Modal
              headingText={
                pyramidType === 'ent'
                  ? 'Are you sure you want to delete this outcome?'
                  : 'Are you sure you want to delete this marketing priority?'
              }
              onRefuse={() => setIsModalVisible(false)}
              isVisible={isModalVisible}
            >
              <div className="hc-pa-normal">
                <Grid.Container>
                  <Grid.Item>
                    <p className="modalPyramidMessage">
                      Deleting{' '}
                      <strong>
                        {selectedPyramid?.pyramid_name || pyramidType === 'ent'
                          ? 'this outcome'
                          : 'this marketing priority'}{' '}
                      </strong>{' '}
                      will delete all stories and unlink all umbrellas assigned
                      to those stories.
                    </p>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Container>
                  <Grid.Item>
                    <Button
                      data-testid={'delete-pyramid'}
                      type="primary"
                      onClick={() => {
                        setIsModalVisible(false)
                        deletePyramid(selectedPyramid)
                      }}
                    >
                      Yes
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      type="secondary"
                      onClick={() => {
                        setIsModalVisible(false)
                      }}
                    >
                      No
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </Modal>
          </div>
        )}
        {sortedElements?.map((pyramid, index) => (
          <Card
            className="bucketItem"
            onDragStart={
              canEdit && isPyramidDraggable
                ? (event) => handleDragStart(event, index)
                : null
            }
            onDragEnter={
              canEdit && isPyramidDraggable ? (event) => dragEnter(event) : null
            }
            onDragOver={
              canEdit && isPyramidDraggable ? (event) => dragOver(event) : null
            }
            onDrop={
              canEdit && isPyramidDraggable
                ? (event) =>
                    handleDrop(
                      event,
                      index,
                      event.dataTransfer.types.includes('drag-pyramid')
                        ? 'pyramid'
                        : null
                    )
                : null
            }
            key={'pyramid-' + pyramid.pyramid_id}
            data-testid={'pyramidBucket-' + index}
            draggable={canEdit && isPyramidDraggable}
          >
            <GridContainer
              data-testid="pyramidList"
              xs={12}
              className="pyramidListContainer"
              id={pyramid.priority_order}
              justify="space-between"
            >
              <Grid.Item xs={1} className="expandStoryIcon">
                <FontAwesomeIcon
                  display={'inline-block'}
                  className={`clickable pyramid-expander ${
                    expandAllPyramids ||
                    expandedPyramids?.includes(pyramid.pyramid_id)
                      ? 'rotate-clockwise-45'
                      : 'rotate-counter-clockwise-45'
                  }`}
                  icon={faAngleDown}
                  onClick={(e) => {
                    e.preventDefault()
                    handleExpandSinglePyramid(pyramid.pyramid_id)
                  }}
                  size="lg"
                  data-testid="expand-single-pyramids"
                />
                <Grid.Item data-cy={`expandable-${pyramidTitle}-${index}`}>
                  {pyramid.priority_order}
                </Grid.Item>
              </Grid.Item>{' '}
              {/* <Grid.Item
                xs={0.5}
                data-cy={`expandable-${pyramidTitle}-${index}`}
              >
                {pyramid.priority_order}
              </Grid.Item> */}
              <Grid.Item xs={6} className="pyramidName">
                <h4 data-cy={`pyramidName-${pyramidTitle}-${index}`}>
                  <InlineEditInput
                    data={pyramid}
                    id={`pyramidName-${pyramidTitle}-${index}`}
                    field="pyramid_name"
                    value={pyramid.pyramid_name || ''}
                    placeholder={
                      pyramidType === 'ent'
                        ? 'New Outcome'
                        : 'New Mktg Priority'
                    }
                    setRefreshPyramidData={setRefreshPyramidData}
                    grow={true}
                  />
                </h4>
              </Grid.Item>
              <Grid.Item xs={3} align="left">
                <h4
                  data-cy={`captain-${pyramidTitle}-${index}`}
                  className="captian"
                >
                  <InlineEditInput
                    data={pyramid}
                    field="captain"
                    value={pyramid.captain || ''}
                    placeholder="Captain"
                    setRefreshPyramidData={setRefreshPyramidData}
                  />
                </h4>
              </Grid.Item>
              {canEdit && (
                <Grid.Item className="trashIcon" xs={0.5}>
                  <FontAwesomeIcon
                    className="labelledIcon"
                    icon={faTrashAlt}
                    size="lg"
                    data-testid={`delete-button-${index}`}
                    data-cy={`delete-${pyramidTitle}-${index}`}
                    onClick={(event) => {
                      event.stopPropagation()
                      setSelectedPyramid(pyramid)
                      setIsModalVisible(true)
                    }}
                  />
                </Grid.Item>
              )}
            </GridContainer>
            {expandAllPyramids || expandedPyramids?.length > 0 ? (
              <>
                <PyramidDetails
                  addChannelToUmbrella={addChannelToUmbrella}
                  removeChannelFromUmbrella={removeChannelFromUmbrella}
                  addAllChannelsToUmbrella={addAllChannelsToUmbrella}
                  removeAllChannelsFromUmbrella={removeAllChannelsFromUmbrella}
                  channels={channels}
                  setRefreshData={setRefreshData}
                  pyramid={pyramid}
                  projectId={projectId}
                  projectTitle={projectTitle}
                  pyramidType={pyramid.pyramid_type}
                  setRefreshPyramidData={setRefreshPyramidData}
                  setIsPyramidDraggable={setIsPyramidDraggable}
                  channelFilters={channelFilters}
                  index={index}
                  expandedPyramids={expandedPyramids}
                  expandAllPyramids={expandAllPyramids}
                  setUmbrellaIsDropped={setUmbrellaIsDropped}
                  setSelectedUmbrellas={setSelectedUmbrellas}
                  session={session}
                  projectStartDate={projectStartDate}
                />
              </>
            ) : (
              <></>
            )}
            {/* </ExpandableSection> */}
          </Card>
        ))}
      </div>
      <DialogView
        isVisible={
          channelsDialogIsVisible && pyramidType === selectedChannelBucket
        }
        onClose={handleToggleChannelDialog}
      >
        <UmbrellaChannelSelector
          projectId={projectId}
          handleChannelClick={handleChannelClick}
          handleSelectAllClick={handleSelectAllClick}
          pyramidType={pyramidType}
          assigned={assignedValues}
          title={pyramidTitle}
          channels={filteredChannels}
          umbrellas={channelUmbrellas}
          isLoading={isUmbrellaChannelsLoading}
          channelFilters={channelFilters}
        />
      </DialogView>
      <DialogView
        isVisible={
          channelsPDFPreviewIsVisible && pyramidType === selectedChannelBucket
        }
        onClose={handleTogglePDFPreview}
        toolbar={
          <div className="dialog-view-toolbar">
            <PDFDownloadLink
              document={
                <MarketChannelPdf
                  assigned={assignedValues}
                  enterpriseLinkedUmbrellas={enterpriseLinkedUmbrellas}
                  umbrellas={channelUmbrellas}
                  channels={filteredChannels}
                  projectTitle={projectTitle}
                  pyramidTitle={pyramidTitle}
                  pyramidElements={sortedElements}
                  projectEndDate={projectEndDate}
                  projectStartDate={projectStartDate}
                  channelFilters={channelFilters}
                />
              }
              fileName={`CreativeHub_Planning_${pyramidTitle}_${projectTitle}_${new Date().toLocaleDateString()}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download'
              }
            </PDFDownloadLink>
          </div>
        }
      >
        <PDFViewer name="smple" height="1200" width="100%" showToolbar>
          <MarketChannelPdf
            assigned={assignedValues}
            enterpriseLinkedUmbrellas={enterpriseLinkedUmbrellas}
            umbrellas={channelUmbrellas}
            channels={filteredChannels}
            projectTitle={projectTitle}
            pyramidTitle={pyramidTitle}
            pyramidElements={sortedElements}
            projectEndDate={projectEndDate}
            projectStartDate={projectStartDate}
            channelFilters={channelFilters}
          />
        </PDFViewer>
      </DialogView>
    </>
  ) : null
}

export default PyramidBucket
