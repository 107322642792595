import { Chip } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

import { getChipValues } from '../planning/util/ProjectDetailsUtil'

const ProjectSummaryFilter = ({
  filterOptions,
  setSelectedFilters,
  selectedFilters = [],
}) => (
  <Autocomplete
    allowCustomInputValue={false}
    id="dwaChannelSelect"
    multiselect
    chipHeight="dense"
    label={'Project Summary Filter'}
    value={selectedFilters}
    onUpdate={(event, value) => {
      setSelectedFilters(value)
    }}
    renderChip={(cat, onRequestDelete) => (
      <Chip
        size="dense"
        style={
          cat.color && {
            backgroundColor: cat.color,
            border: '0px',
            color: 'white',
          }
        }
        onRequestDelete={onRequestDelete}
      >
        {cat.color ? getChipValues(cat.value) : cat.label}
      </Chip>
    )}
    options={filterOptions}
    placeholder="Choose Channels or Pyramids"
    data-testid="dwaChannelSelect"
    grow
  />
)

export default ProjectSummaryFilter
