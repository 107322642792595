import { Chip, Grid, GridItem, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import {
  faCopy,
  faTimesCircle,
  faLink,
  faKey,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'

import UmbrellaApi from '../../api/creative-hub/UmbrellaApi'
import appConfig from '../../config/appConfig'
import {
  UmbrellaContext,
  CanEditContext,
  EnterpriseLinkedUmbrellasContext,
} from '../../context/Context'
import { handleFireflyClickInteraction } from '../../util/FireflyHelper'
import { deriveNoOfDaysFromCurrentDate } from '../util/DateUtil.js'
import { formatter, getTierAbbreviation } from '../util/ProjectDetailsUtil'
import { copyUmbrellaLink } from '../util/UmbrellaUtil'

import PyramidChip from './PyramidChip'

import '../scss/Umbrella.scss'

const Umbrella = (props) => {
  const {
    data,
    setRefreshData,
    storyId,
    storyName,
    projectStartDate,
    index,
    isPyramid = false,
    activeTab,
    selectedUmbrellas,
    setSelectedUmbrellas,
    setIsDragging,
    isDragging,
    currentlySelected,
    projectTitle,
    pyramidName,
    pyramidType,
    session = {},
  } = props
  const config = appConfig()

  const umbrellaApi = new UmbrellaApi()
  const { canEdit } = useContext(CanEditContext)
  const [isSelected, setIsSelected] = useState(
    currentlySelected ? currentlySelected : false
  )
  const enterpriseLinkedUmbrellas = useContext(EnterpriseLinkedUmbrellasContext)

  const {
    distro,
    forecast = {},
    ivy_umbrella_id,
    key,
    merchant_category = {},
    name,
    notes,
    planning_umbrella_id,
    priority = {},
    invalid_dates = false,
    start_date,
    end_date,
    status,
  } = data

  const { group_name = '' } = merchant_category
  const isEnterpriseLinked = enterpriseLinkedUmbrellas?.linkedUmbrellas?.find(
    (umbrella) => umbrella.ivy_umbrella_id === ivy_umbrella_id
  )
  const deactivated = status === 'DEACTIVATED' ? true : false

  //remove hide one elements if need be!!!
  const hideOtherItemsDragging = () => {
    if (selectedUmbrellas?.length > 1) {
      for (let i = 0; i < selectedUmbrellas.length; i++) {
        let elements = document.getElementsByClassName('selectedUmbrellaCard')
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.add('hide')
        }
      }
    }
  }

  const showOtherItemsDragging = () => {
    if (selectedUmbrellas?.length > 1) {
      for (let i = 0; i < selectedUmbrellas.length; i++) {
        let elements = document.getElementsByClassName('selectedUmbrellaCard')
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.remove('hide')
        }
      }
    }
  }

  const startDrag = (event, position) => {
    event.stopPropagation()
    if (!planning_umbrella_id) {
      setIsDragging(true)
      let element = event.target
      element.classList.add('hide')
      hideOtherItemsDragging()
      event.dataTransfer.setData(
        'drag-umbrella',
        JSON.stringify(selectedUmbrellas)
      )
      event.dataTransfer.setData(
        'allow_drop_in_' + activeTab.toLowerCase(),
        true
      )
    } else {
      event.dataTransfer.setData('drag-umbrella', JSON.stringify(data))
      event.dataTransfer.setData('allow_drop_in_unlinked', true)
      event.dataTransfer.setData(storyId, position)
      event.dataTransfer.setData('story-id', storyId)
    }
  }

  const endDrag = (e) => {
    let element = e.target
    element.classList.remove('hide')
    showOtherItemsDragging()
    if (!planning_umbrella_id) {
      setIsDragging(false)
    }
  }

  const unlinkFromStory = (umbrellaId, storyId) => {
    umbrellaApi
      .unlinkUmbrella(umbrellaId, storyId)
      .then((res) => {
        res && setRefreshData(true)
      })
      .catch((err) => console.log(err))

    let noOfDaysBeforeProjectStartDate =
      deriveNoOfDaysFromCurrentDate(projectStartDate)

    // Only trigger the firefly event if the unLinked umbrella is 8 weeks (57 days) from the project start date.
    if (noOfDaysBeforeProjectStartDate <= config.lateLinkUnlinkUmbrellaDays) {
      handleFireflyClickInteraction(
        'pageload',
        'MKTG Priorities: unlink Umbrella',
        'success',
        session,
        {
          message: `project_title: ${projectTitle} || pyramid_type: ${pyramidType} || pyramid_name: ${pyramidName} || story_name: ${storyName} || unlinked_umbrella: ${data.name} `,
          start_date: projectStartDate,
        }
      )
    }
  }

  const { isUmbrellaExpanded } = useContext(UmbrellaContext)

  const addUmbrella = (e, ind) => {
    let currentUmb = selectedUmbrellas
    currentUmb?.push(data)
    setSelectedUmbrellas(currentUmb)
  }

  const removeUmbrella = (e, ind) => {
    let currentUmb = selectedUmbrellas

    let removeDeselectedUmbrellaArray = currentUmb.filter(
      (umb) => umb.ivy_umbrella_id !== data.ivy_umbrella_id
    )
    setSelectedUmbrellas(removeDeselectedUmbrellaArray)
  }

  return (
    <>
      <div
        className={isSelected ? `selectedUmbrellaCard` : 'umbrellaCard'}
        onClick={(e) => {
          if (!planning_umbrella_id && canEdit) {
            if (!isSelected) {
              addUmbrella(e, index)
            } else {
              removeUmbrella(e, index)
            }
            setIsSelected(!isSelected)
          }
        }}
        draggable={planning_umbrella_id ? canEdit : isSelected}
        onDragStart={canEdit ? (event) => startDrag(event, index) : null}
        onDragEnd={endDrag}
        data-testid="umbrella"
      >
        <Grid.Container draggable={false} className="basicInfo" xs={12}>
          {isDragging && !planning_umbrella_id && isSelected ? (
            <GridItem xs={12} className="umbrellaCount">
              {selectedUmbrellas?.length + ' '} umbrellas selected...
            </GridItem>
          ) : null}
          <Grid.Item xs={11}>
            <h4 className={deactivated ? 'deactivatedName' : 'umbrellaName'}>
              <a
                target="_blank"
                href={`${config.ivy.uiUrl}umbrella/${ivy_umbrella_id}`}
                className={deactivated ? 'deactivatedLink' : null}
                rel="noreferrer"
                draggable={false}
              >
                {isEnterpriseLinked && isPyramid ? (
                  <Tooltip
                    content="This umbrella is also linked to an Enterprise Guest Outcome"
                    location="top-left"
                  >
                    <FontAwesomeIcon
                      icon={faLink}
                      className="fa-icon linked"
                      size="lg"
                    />
                  </Tooltip>
                ) : null}
                {!isPyramid ? <PyramidChip groupName={group_name} /> : null}
                {key ? (
                  <FontAwesomeIcon
                    icon={faKey}
                    className="fa-duotone key"
                    size="lg"
                  />
                ) : null}
                <span className={invalid_dates ? 'invalidDates' : null}>
                  {' '}
                  {name}{' '}
                </span>
                {invalid_dates ? (
                  <span className="invalidDatesText">INVALID DATES </span>
                ) : null}
              </a>
              {deactivated ? ' - DEACTIVATED' : null}
              <FontAwesomeIcon
                data-testid="umbrellaCopyBtn"
                className="umbrella-copy-btn clickable"
                icon={faCopy}
                onClick={() => copyUmbrellaLink(data)}
              />
            </h4>
          </Grid.Item>
          {planning_umbrella_id && canEdit ? (
            <Grid.Item
              data-testid="unlinkButton"
              className="unlinkUmbrellaButton"
              xs={1}
              onClick={() => {
                setSelectedUmbrellas([])
                unlinkFromStory(planning_umbrella_id, storyId)
              }}
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="fa-icon"
                size="lg"
              />
            </Grid.Item>
          ) : (
            <></>
          )}
        </Grid.Container>

        <Grid.Container draggable={false} className="basicInfo" xs={12}>
          <Grid.Item xs={3}>
            <DateFormatter
              date={start_date}
              format="MM/DD"
              timezone="America/Los_Angeles"
            />
            {' to '}
            <DateFormatter
              date={end_date}
              format="MM/DD"
              timezone="America/Los_Angeles"
            />

            <div>{distro}</div>
          </Grid.Item>
          {key ? (
            <>
              <Grid.Item className="name" xs={6}>
                {priority?.name || ''}
              </Grid.Item>
              <Grid.Item className="chips" xs={3}>
                <Chip size="dense">{getTierAbbreviation(priority?.tier)}</Chip>
              </Grid.Item>
            </>
          ) : (
            <>
              <Grid.Item className="name" xs={6}>
                {priority?.name || ''}
              </Grid.Item>
              <Grid.Item className="chips" xs={3}>
                <Chip size="dense">{getTierAbbreviation(priority?.tier)}</Chip>
              </Grid.Item>
            </>
          )}
        </Grid.Container>
        {isUmbrellaExpanded && (
          <div className="expandedUmbrellaSection">
            <hr />
            <Grid.Container className="header" xs={12}>
              <Grid.Item xs={4}>
                <h4>Promo</h4>$
                {Number(forecast?.total_promo_sales)
                  ? formatter.format(forecast?.total_promo_sales)
                  : ' ---'}
              </Grid.Item>
              <Grid.Item xs={4}>
                <h4>D Mkdn</h4>$
                {Number(forecast?.total_dmarkdown)
                  ? formatter.format(forecast?.total_dmarkdown)
                  : ' ---'}
              </Grid.Item>
              <Grid.Item xs={4}>
                <h4>Units</h4>
                {Number(forecast?.total_promo_sales_units)
                  ? formatter.format(forecast?.total_promo_sales_units)
                  : ' ---'}
              </Grid.Item>
            </Grid.Container>
            <Grid.Container className="header" xs={12}>
              <Grid.Item xs={12}>
                <h4>Notes</h4>
                {notes}
              </Grid.Item>
            </Grid.Container>
          </div>
        )}
      </div>
    </>
  )
}
export default Umbrella
