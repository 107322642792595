import { Grid, Input, Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import {
  faAngleDown,
  faAngleRight,
  faEye,
  faEyeSlash,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { useRef } from 'react'

import './scss/EditablePageSummary.scss'
import { CanEditContext } from '../../context/Context'
import { PYRAMID, STORY, UMBRELLA, OFFER, DEACTIVATED } from '../util/Constants'
const InlineLabeledInputWithButton = ({
  hidden = false,
  expand = false,
  status,
  label,
  value,
  type,
  onChange,
  isHovered,
  onDeleteButtonClick,
  onHideShowButtonClick,
  onExpandCollapseButtonClick,
  onBlur,
}) => {
  const initialValueRef = useRef(value)
  const { canEdit } = useContext(CanEditContext)
  const handleBlur = () => {
    if (value !== initialValueRef.current) {
      onBlur(value)
    }
  }
  const renderButtons = () => {
    if (!canEdit) return null

    return (
      <Grid.Container
        direction="row"
        align="flex-end"
        spacing="none"
        justify="flex-end"
      >
        <Grid.Item align="flex-end">
          <Tooltip content={`${hidden ? 'Show' : 'Hide'}`} location="top">
            <Button
              iconOnly={true}
              type="ghost"
              aria-label={hidden ? 'hide' : 'Show'}
              onClick={onHideShowButtonClick}
            >
              <FontAwesomeIcon
                icon={hidden ? faEyeSlash : faEye}
                className="trash-button"
              />
            </Button>
          </Tooltip>
        </Grid.Item>
        <Grid.Item align="flex-end">
          <Tooltip content="Delete" location="top">
            <Button
              iconOnly={true}
              type="ghost"
              aria-label="delete"
              onClick={onDeleteButtonClick}
            >
              <FontAwesomeIcon icon={faTrash} className="trash-button" />
            </Button>
          </Tooltip>
        </Grid.Item>
      </Grid.Container>
    )
  }

  return (
    <Grid.Container direction="row" align="center" spacing="dense">
      <Grid.Item
        className={`editable-input-container ${
          status === DEACTIVATED ? 'deactivated' : ''
        } ${isHovered && canEdit ? 'hoverBackground' : ''}`}
      >
        <Grid.Item>
          {type != OFFER ? (
            <Tooltip
              content={`${expand ? 'Expand' : 'Collapse'}`}
              location="top"
            >
              <Button
                iconOnly
                type="ghost"
                className="expand-collapse-button"
                aria-label={expand ? 'expand' : 'collapse'}
                onClick={onExpandCollapseButtonClick}
              >
                <FontAwesomeIcon
                  icon={
                    type === OFFER ? '' : expand ? faAngleRight : faAngleDown
                  }
                  className="trash-button"
                />
              </Button>
            </Tooltip>
          ) : null}
        </Grid.Item>
        <Grid.Item>
          <label
            className={`${
              type === PYRAMID
                ? 'label-pyramid'
                : type === STORY
                  ? 'label-story'
                  : type === UMBRELLA
                    ? 'label-umbrella'
                    : type === OFFER
                      ? 'label-offer'
                      : ''
            }`}
          >
            {label}
          </label>
        </Grid.Item>
        <Grid.Item xs={type === PYRAMID ? 9 : 10}>
          <Input.Text
            id={'editable-input'}
            className={`${
              type === PYRAMID
                ? 'editable-input-pyramid'
                : type === STORY
                  ? 'editable-input-story'
                  : type === UMBRELLA
                    ? 'editable-input-umbrella'
                    : type === OFFER
                      ? 'editable-input-offer'
                      : ''
            } ${status === DEACTIVATED ? 'deactivated' : ''} ${
              isHovered && canEdit ? 'hoverBackground' : ''
            }`}
            value={value}
            onChange={onChange}
            onBlur={handleBlur}
            disabled={!canEdit}
          />
        </Grid.Item>

        {renderButtons()}
      </Grid.Item>
    </Grid.Container>
  )
}
export default InlineLabeledInputWithButton
