import './scss/pages.scss'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import {
  Card,
  Modal,
  Form,
  Grid,
  Button,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import AddIcon from '@mui/icons-material/Add'
import { Fab } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useState, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { CanEditContext, WeeklyAdAutomationContext } from '../context/Context'

import DraggablePage from './DraggablePage'
import { NUMBERED, LETTERED } from './util/Constants'
class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false
        }

        return true
      },
    },
  ]
}

function isInteractiveElement(element) {
  const interactiveElements = ['path', 'svg', 'button']
  return interactiveElements.includes(element.tagName.toLowerCase())
}

const theme = createTheme({
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#2f2f8a',
          },
          backgroundColor: '#3838a3',
          zIndex: 5,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
  },
})

const PagesAtAGlance = () => {
  const { canEdit } = useContext(CanEditContext)
  const { projectId } = useParams()
  const sensors = useSensors(useSensor(MyPointerSensor))
  const {
    pages = [],
    createPage,
    currentPageInfo,
    reorderWAPages = () => {},
  } = useContext(WeeklyAdAutomationContext)
  const [, setActive] = useState()
  const [type, setType] = useState(NUMBERED)
  const [numberOfPages, setNumberOfPages] = useState(1)
  const [isCreatePageModalOpen, setIsCreatePageModalOpen] = useState(false)
  const pageRefs = useRef([])

  useEffect(() => {
    if (isCreatePageModalOpen) {
      setType(NUMBERED)
      setNumberOfPages(1)
    }
  }, [isCreatePageModalOpen])

  const handleAddClick = () => {
    setIsCreatePageModalOpen(true)
  }

  const handleClose = () => {
    setIsCreatePageModalOpen(false)
  }

  const handleCreate = () => {
    const payload = {
      number_of_pages: parseInt(numberOfPages),
      project_id: projectId,
      type,
    }
    createPage(payload)
    setIsCreatePageModalOpen(false)
  }

  const validateInput = (e) => {
    const value = e.target.value
    if (isNaN(Number(value))) {
      setNumberOfPages('')
    } else if (Number(value) >= 1 && Number(value) <= 99) {
      setNumberOfPages(value)
    }
  }
  const handlePageScroll = (index) => {
    setTimeout(() => {
      pageRefs.current[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }, 100)
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active)
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = pages.findIndex(({ id }) => id === active.id)
          const activePage = pages[activeIndex]
          const overIndex = pages.findIndex(({ id }) => id === over.id)
          reorderWAPages({ activePage, overIndex })
        }
        setActive(null)
      }}
      onDragCancel={() => {
        setActive(null)
      }}
    >
      <SortableContext
        items={pages}
        disabled={!canEdit || currentPageInfo?.showAdditionalInfo}
      >
        <Card className="pageContainer">
          {pages?.map((page, index) => (
            <DraggablePage
              key={page?.page_id}
              id={page?.page_id}
              displayNumber={page?.label}
              data={page}
              pageRef={(el) => (pageRefs.current[index] = el)}
              pageIndex={index}
              onDetailsClick={handlePageScroll}
            />
          ))}
          {!currentPageInfo?.showAdditionalInfo && (
            <div className="draggablePageContainer" style={{ width: 220 }}>
              <Card className="fab-card">
                <div className="fabContainer">
                  <ThemeProvider theme={theme}>
                    <Fab
                      aria-label="create page(s)"
                      onClick={handleAddClick}
                      disabled={!canEdit}
                    >
                      <AddIcon />
                    </Fab>
                  </ThemeProvider>
                </div>
              </Card>
            </div>
          )}
        </Card>
        <Modal
          isVisible={isCreatePageModalOpen}
          headingText="Create Page(s)"
          onRefuse={() => setIsCreatePageModalOpen(false)}
          size="dense"
        >
          <div className="hc-pa-normal">
            <Grid.Container spacing="dense" direction="column">
              <Grid.Item>
                <Input.Radio
                  className="inline-radio-options"
                  required
                  id={'create_page_type'}
                  value={type}
                  options={[
                    {
                      label: 'Numbered',
                      value: NUMBERED,
                    },
                    {
                      label: 'Lettered',
                      value: LETTERED,
                    },
                  ]}
                  onUpdate={(id, value) => {
                    setType(value)
                  }}
                />
              </Grid.Item>
              <Grid.Item>
                <Form.Field
                  type="number"
                  label={'Enter Number of Pages'}
                  value={numberOfPages}
                  onChange={validateInput}
                ></Form.Field>
              </Grid.Item>
            </Grid.Container>
            <Grid.Container direction="row-reverse" spacing="dense">
              <Grid.Item>
                <Button
                  onClick={() => handleCreate()}
                  type="primary"
                  disabled={numberOfPages === ''}
                >
                  CREATE
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button onClick={handleClose} type="secondary">
                  CANCEL
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      </SortableContext>
    </DndContext>
  )
}

export default PagesAtAGlance
