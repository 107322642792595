import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'

export default class DefaultFilterApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_defaults_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  getDefaults = async () =>
    await this.init_defaults_api()
      .get(`${this.config.markethub.url}filters`)
      .then((res) => res.data)
      .catch((e) => {
        throw new Error(e)
      })

  putDefaults = async (payload) =>
    await this.init_defaults_api()
      .put(`${this.config.markethub.url}filters`, payload)
      .then((res) => res.data)
      .catch((e) => {
        throw new Error(e)
      })
}
