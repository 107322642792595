import { Grid } from '@enterprise-ui/canvas-ui-react'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'

import WeeklyAdMediaUnitChannelCheckboxes from '../../WeeklyAdAutomation/AdditionalInfoTabs/MediaUnitChannel'
import { validateDestinationUrl } from '../util/helpers'

import ChannelCheckboxes from './ChannelCheckboxes'
import ContentDetailMarkets from './ContentDetailMarkets'
import ContentDetailCategory from './ContentDetailsCategory'
import ContentDetailsRow from './ContentDetailsRow'

const MarketingDetails = ({
  dwaChannels = false,
  editMarkets,
  setEditMarkets,
  editCategories,
  setEditCategories,
  mediaUnitData,
  marketOptions,
  categoryOptions,
  disabled,
  destinationUrl,
  setDestinationUrl,
  marketsSelected,
  setMarketsSelected,
  notesInput,
  setNotesInput,
  categoriesSelected,
  setCategoriesSelected,
  audioChannelObj,
  videoChannelObj,
  bannerChannelObj,
  priorityChannelObj,
  circleChannelObj,
  updateChannel,
  setChangeDetected,
  resetValues,
  isPriorityCategory,
}) => {
  useEffect(() => {
    if (resetValues) resetValues()
    // eslint-disable-next-line
  }, [mediaUnitData])
  const [initialNotes, setInitialNotes] = useState(notesInput)

  return (
    <div
      className="media-unit-section-container"
      data-testid="marketing-details"
    >
      <Grid.Item className="details-header">Marketing Details</Grid.Item>
      <Grid.Container>
        <Grid.Item xs={12}>
          {!isPriorityCategory ? (
            <ContentDetailMarkets
              value={marketsSelected}
              setMarketsSelected={setMarketsSelected}
              title="Markets"
              disabled={disabled}
              marketOptions={marketOptions}
              setEditMarkets={setEditMarkets}
              setChangeDetected={setChangeDetected}
              editMarkets={editMarkets}
              mediaUnitId={mediaUnitData.id}
            />
          ) : null}
          <ContentDetailCategory
            categoriesSelected={categoriesSelected}
            setCategoriesSelected={setCategoriesSelected}
            disabled={disabled}
            categoryOptions={categoryOptions}
            setChangeDetected={setChangeDetected}
            editCategories={editCategories}
            setEditCategories={setEditCategories}
            mediaUnitId={mediaUnitData.id}
          />
          <ContentDetailsRow
            destinationUrlRow={validateDestinationUrl(destinationUrl)}
            value={destinationUrl}
            updateValue={setDestinationUrl}
            onChange={setDestinationUrl}
            title="Destination URL"
            disabled={disabled}
            id={mediaUnitData.id}
            setChangeDetected={setChangeDetected}
          />
          {dwaChannels ? (
            <WeeklyAdMediaUnitChannelCheckboxes
              checked
              mediaUnitData={mediaUnitData}
              disabled
              setChangeDetected={setChangeDetected}
            />
          ) : (
            <ChannelCheckboxes
              audioChannelObj={audioChannelObj}
              videoChannelObj={videoChannelObj}
              circleChannelObj={circleChannelObj}
              bannerChannelObj={bannerChannelObj}
              priorityChannelObj={priorityChannelObj}
              onChange={updateChannel}
              title="Channels"
              disabled={disabled}
              id={mediaUnitData.id}
              setChangeDetected={setChangeDetected}
              isPriorityCategory={isPriorityCategory}
            />
          )}

          <Grid.Container className="category-container-row" justify="center">
            <Grid.Item xs={12} className="notes-for-creative">
              <TextField
                fullWidth
                multiline
                rows={2}
                id="notes"
                name="notes"
                label="Notes for Creative"
                value={notesInput || ''}
                disabled={disabled}
                // TODO:  Check to see if anything changed.  If not, then don't trigger update
                inputProps={{
                  style: {
                    fontSize: 14,
                    ...(dwaChannels ? { padding: '10px' } : {}),
                  },
                }}
                onBlur={() => {
                  if (notesInput !== initialNotes) {
                    setChangeDetected(true)
                    setInitialNotes(notesInput)
                  }
                }}
                onChange={(e) => setNotesInput(e.target.value)}
                data-testid="notes-input"
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default MarketingDetails
