import { Card, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import classnames from 'classnames'
import { useContext } from 'react'

import { CanEditContext, WeeklyAdAutomationContext } from '../context/Context'
import './scss/summaryList.scss'
import { isOfferAddedAfterProjectKODate } from '../creativeHubGlobal/util/OfferUtil'
import { formatToUi } from '../util/MomentUtil'

import { PYRAMID, STORY, UMBRELLA, OFFER, DEACTIVATED } from './util/Constants'
const WASummaryList = () => {
  const {
    sortedPyramids,
    currentPageInfo: { showAdditionalInfo = false },
    selectedProject,
  } = useContext(WeeklyAdAutomationContext)
  const { canEdit } = useContext(CanEditContext)
  const cursorStyle = {
    cursor: canEdit && !showAdditionalInfo ? 'grab' : 'default',
  }

  return (
    <Card style={{ height: '100%', overflow: 'auto', padding: 15 }} disabled>
      {sortedPyramids?.map((pyramid = {}, ind) => {
        const {
          pyramid_type = '',
          pyramid_id = '',
          pyramid_name = '',
          priority_order = 0,
          stories = [],
          color = '',
        } = pyramid
        return (
          <Grid.Container
            spacing="none"
            align="center"
            justify="flex-start"
            xs={12}
            key={pyramid_id}
          >
            <Grid.Item
              xs={12}
              style={{
                backgroundColor: color,
              }}
            >
              <div
                className="pyramidHeader"
                draggable={canEdit && !showAdditionalInfo}
                style={cursorStyle}
                onDragStart={(event) => {
                  event.stopPropagation()
                  let data = { payload: pyramid, type: PYRAMID }
                  event.dataTransfer.setData(
                    'page-entity',
                    JSON.stringify(data)
                  )
                }}
              >
                {pyramid_type === 'ent'
                  ? 'EGO'
                  : `${pyramid_type?.toUpperCase()} MP`}
                {priority_order}: {pyramid_name}
              </div>
            </Grid.Item>
            <Grid.Item xs={12} key={`${priority_order}-${ind}`}>
              {stories?.map((story = {}, ind) => {
                const {
                  story_order = 0,
                  story_name = '',
                  umbrellas = [],
                } = story
                return (
                  <Grid.Item
                    className="basicBoxStyle"
                    key={`${story_order}-${ind}`}
                    draggable={canEdit && !showAdditionalInfo}
                    style={cursorStyle}
                    onDragStart={(event) => {
                      event.stopPropagation()
                      let data = {
                        payload: { ...pyramid, stories: [story] },
                        type: STORY,
                      }
                      event.dataTransfer.setData(
                        'page-entity',
                        JSON.stringify(data)
                      )
                    }}
                  >
                    <Grid.Item>
                      <Heading size={6}>
                        {' '}
                        S{story_order}: {story_name}
                      </Heading>
                    </Grid.Item>
                    {umbrellas?.map((umbrella = {}, ind) => {
                      const {
                        umbrella_name = '',
                        offers = [],
                        umbrella_order = 0,
                        umbrella_status = '',
                      } = umbrella
                      return (
                        <Card
                          className="basicBoxStyle"
                          key={`${umbrella_order}-${ind}`}
                          draggable={canEdit}
                          style={cursorStyle}
                          onDragStart={(event) => {
                            event.stopPropagation()
                            if (umbrella_status.toLowerCase() === DEACTIVATED) {
                              event.preventDefault()
                              return
                            }

                            let data = {
                              payload: {
                                ...pyramid,
                                stories: [{ ...story, umbrellas: [umbrella] }],
                              },
                              type: UMBRELLA,
                            }
                            event.dataTransfer.setData(
                              showAdditionalInfo
                                ? 'drag-umbrella'
                                : 'page-entity',
                              JSON.stringify(showAdditionalInfo ? offers : data)
                            )
                          }}
                        >
                          <Grid.Item
                            className={
                              umbrella_status.toLowerCase() === DEACTIVATED
                                ? 'deactivated'
                                : ''
                            }
                          >
                            {' '}
                            U{umbrella_order}: {umbrella_name}
                          </Grid.Item>
                          {offers?.map((offer, ind) => {
                            const {
                              offer_name = '',
                              offer_order = 0,
                              weekly_ad_page_labels = '',
                              circle_offer = false,
                              created_date = '',
                              offer_status = '',
                            } = offer
                            const lateOffer = isOfferAddedAfterProjectKODate(
                              selectedProject.offers_due_date,
                              created_date
                            )
                            const pageLabelsArray = weekly_ad_page_labels
                              ? weekly_ad_page_labels.split(',')
                              : []
                            return (
                              <Card
                                className="basicBoxStyle"
                                key={`${offer_order}-${ind}`}
                                draggable={canEdit}
                                style={{
                                  ...cursorStyle,
                                  ...(circle_offer && { color: '#F3383D' }),
                                }}
                                onDragStart={(event) => {
                                  event.stopPropagation()
                                  if (
                                    offer_status.toLowerCase() === DEACTIVATED
                                  ) {
                                    event.preventDefault()
                                    return
                                  }

                                  let data = {
                                    payload: {
                                      ...pyramid,
                                      stories: [
                                        {
                                          ...story,
                                          umbrellas: [
                                            { ...umbrella, offers: [offer] },
                                          ],
                                        },
                                      ],
                                    },
                                    type: OFFER,
                                  }
                                  event.dataTransfer.setData(
                                    showAdditionalInfo
                                      ? 'drag-offer'
                                      : 'page-entity',
                                    JSON.stringify(
                                      showAdditionalInfo ? [offer] : data
                                    )
                                  )
                                }}
                              >
                                {
                                  <span
                                    title={
                                      lateOffer
                                        ? `Added to CH ${formatToUi(
                                            created_date
                                          )}`
                                        : ``
                                    }
                                    className={classnames(
                                      `${lateOffer && `late_offer_background`}`,
                                      `${
                                        offer_status === DEACTIVATED
                                          ? 'deactivated'
                                          : ''
                                      }`
                                    )}
                                  >
                                    {`O${offer_order}: ${offer_name} `}
                                  </span>
                                }
                                <div className="offer-pages">
                                  {pageLabelsArray.length > 0 &&
                                    pageLabelsArray.map((page, ind) => (
                                      <div key={ind} className="offer-page-box">
                                        {page}
                                      </div>
                                    ))}
                                </div>
                              </Card>
                            )
                          })}
                        </Card>
                      )
                    })}
                  </Grid.Item>
                )
              })}
            </Grid.Item>
          </Grid.Container>
        )
      })}
    </Card>
  )
}

export default WASummaryList
