import {
  Form,
  Grid,
  Heading,
  Input,
  Modal,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import * as yup from 'yup'

import CancelSubmitButtons from '../components/CancelSubmitButtons'
import { User } from '../util/AuthUtils'
import { formatToDateTime } from '../util/MomentUtil'

let editItemSampleSchema = yup.object().shape({
  edit_sampleItem_description: yup.string(),
  edit_sampleItem_sample: yup
    .string()
    .oneOf(['inventory', 'turnIn', 'missing']),
  edit_sampleItem_sampleId: yup.string().when('edit_sampleItem_sample', {
    is: (sample) => sample === 'inventory',
    otherwise: yup.string(),
    then: yup.string().required('SampleID required when status: inventory'),
  }),
})

const EditItemSample = (props) => {
  const {
    setEditItem,
    putItem,
    hasEditPrivileges,
    editItem: { show, body: editItem },
  } = props
  const user = User()

  const wipeState = () => {
    setEditItem({
      body: {},
      show: false,
    })
  }
  return !isEmpty(editItem) ? (
    <Formik
      enableReinitialize
      initialValues={{
        edit_sampleItem_figModel: Boolean(editItem.figModel),
        edit_sampleItem_imagePulled:
          editItem.imagePulled || editItem.imagePulled === 0
            ? editItem.imagePulled
            : -1,
        edit_sampleItem_image_file_name: editItem.imageFileName || '',
        edit_sampleItem_image_type: editItem.imageType || '',
        edit_sampleItem_marketing_notes: editItem.marketingNotes || '',
        edit_sampleItem_merch_notes: editItem.merchNotes,
        edit_sampleItem_sample: editItem.sample || '',
        edit_sampleItem_sampleId: editItem.sampleId || '',
        edit_sampleItem_sampleNeeded: Boolean(editItem.sampleNeeded),
        edit_sampleItem_samplePulled:
          editItem.samplePulled || editItem.samplePulled === 0
            ? editItem.samplePulled
            : -1,
        edit_sampleItem_sampleRequestReason: editItem.sampleRequestReason || '',
      }}
      validationSchema={editItemSampleSchema}
      onSubmit={(values, funcs) => {
        putItem({
          body: {
            figModel: values.edit_sampleItem_figModel,
            id: editItem.id,
            imageFileName: values.edit_sampleItem_image_file_name,
            imagePulled: values.edit_sampleItem_imagePulled,
            imageType: values.edit_sampleItem_image_type,
            lastUpdatedTimestamp: editItem.lastUpdatedTimestamp,
            marketingNotes: values.edit_sampleItem_marketing_notes,
            merchNotes: values.edit_sampleItem_merch_notes,
            sample: values.edit_sampleItem_sample,
            sampleId: values.edit_sampleItem_sampleId,
            sampleNeeded: values.edit_sampleItem_sampleNeeded,
            samplePulled: values.edit_sampleItem_samplePulled,
            sampleRequestReason: values.edit_sampleItem_sampleRequestReason,
          },
          user,
        })
        funcs.resetForm() //must be before wipeState call, which causes rerender
        wipeState()
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }) => {
        const handleDialogClose = () => {
          handleReset()
          wipeState()
        }
        return (
          <Modal isVisible={show} onRefuse={handleDialogClose}>
            <Heading
              size={4}
              className="hc-pt-expanded hc-pl-expanded hc-pr-expanded"
            >
              {`Edit ${editItem.description}  ${editItem.dpci}`}
              <Tooltip
                location={'left'}
                style={{ float: 'right' }}
                content={`Item Revised: ${
                  editItem.sampleLastUpdatedField
                } at ${formatToDateTime(editItem.sampleLastUpdatedTimestamp)}`}
              >
                <FontAwesomeIcon
                  icon={faClock}
                  size="xs"
                  className="hc-clr-grey03"
                />
              </Tooltip>
            </Heading>
            <div className="hc-pa-normal">
              <Grid.Container spacing="dense">
                <Grid.Item xs={2}>
                  <Input.Label disabled={!hasEditPrivileges}>
                    Shooting
                  </Input.Label>
                  <Form.Field
                    type="toggle"
                    id="edit_sampleItem_sampleNeeded"
                    className="hc-mt-normal"
                    disabled={!hasEditPrivileges}
                    value={values.edit_sampleItem_sampleNeeded}
                    onChange={() =>
                      setFieldValue(
                        'edit_sampleItem_sampleNeeded',
                        !values.edit_sampleItem_sampleNeeded
                      )
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="edit_sampleItem_imagePulled"
                    label="Image Collected"
                    value={values.edit_sampleItem_imagePulled}
                    disabled={!hasEditPrivileges}
                    options={[
                      { label: '', value: -1 },
                      { label: 'No', value: 0 },
                      { label: 'Yes', value: 1 },
                      { label: 'Canceled', value: 2 },
                    ]}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    id="edit_sampleItem_image_type"
                    type="select"
                    disabled={!hasEditPrivileges}
                    label="Image Type"
                    value={values.edit_sampleItem_image_type}
                    onUpdate={setFieldValue}
                    options={[
                      { label: '', value: '' },
                      { label: 'Pickup Image', value: 'pickup' },
                      { label: 'Vendor Image', value: 'vendor' },
                    ]}
                    error={errors.edit_sampleItem_image_type}
                    errorText={errors.edit_sampleItem_image_type}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="edit_sampleItem_image_file_name"
                    value={values.edit_sampleItem_image_file_name}
                    disabled={!hasEditPrivileges}
                    onChange={handleChange}
                    label="Image File Name"
                  />
                </Grid.Item>
                <Grid.Item xs={2}>
                  <Input.Label disabled={!hasEditPrivileges}>
                    Fig Model
                  </Input.Label>
                  <Form.Field
                    id="edit_sampleItem_figModel"
                    type="toggle"
                    className="hc-mt-normal"
                    disabled={!hasEditPrivileges}
                    value={values.edit_sampleItem_figModel}
                    onChange={() =>
                      setFieldValue(
                        'edit_sampleItem_figModel',
                        !values.edit_sampleItem_figModel
                      )
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="edit_sampleItem_samplePulled"
                    label="Sample Collected"
                    value={values.edit_sampleItem_samplePulled}
                    disabled={!hasEditPrivileges}
                    options={[
                      { label: '', value: -1 },
                      { label: 'No', value: 0 },
                      { label: 'Yes', value: 1 },
                      { label: 'Canceled', value: 2 },
                    ]}
                    onUpdate={setFieldValue}
                  />
                </Grid.Item>
                <Grid.Item xs={3}>
                  <Form.Field
                    type="select"
                    id="edit_sampleItem_sample"
                    label="Sample Status"
                    value={values.edit_sampleItem_sample}
                    onUpdate={setFieldValue}
                    disabled={!hasEditPrivileges}
                    options={[
                      { label: '', value: '' },
                      { label: 'Inventory', value: 'inventory' },
                      { label: 'Turn-in', value: 'turnIn' },
                      { label: 'Missing', value: 'missing' },
                    ]}
                  />
                </Grid.Item>
                <Grid.Item xs={4}>
                  <Form.Field
                    id="edit_sampleItem_sampleId"
                    value={values.edit_sampleItem_sampleId}
                    disabled={!hasEditPrivileges}
                    label="ID Number"
                    onChange={handleChange}
                    error={errors.edit_sampleItem_sampleId}
                    errorText={errors.edit_sampleItem_sampleId}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    id="edit_sampleItem_merch_notes"
                    value={values.edit_sampleItem_merch_notes}
                    disabled={!hasEditPrivileges}
                    label="Offer Notes"
                    onChange={handleChange}
                  />
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Form.Field
                    id="edit_sampleItem_marketing_notes"
                    value={values.edit_sampleItem_marketing_notes}
                    disabled={!hasEditPrivileges}
                    label="Marketing Notes"
                    onChange={handleChange}
                  />
                </Grid.Item>
              </Grid.Container>
              <CancelSubmitButtons
                cancelText={hasEditPrivileges ? 'Cancel' : 'Close'}
                handleDialogClose={handleDialogClose}
                handleSubmit={hasEditPrivileges ? handleSubmit : null}
              />
            </div>
          </Modal>
        )
      }}
    </Formik>
  ) : null
}
export default EditItemSample
