import {
  Breadcrumbs,
  Card,
  Drawer,
  Grid,
  Image,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import './scss/WACreativeSummary.scss'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { useEffect, useState, useMemo, useRef } from 'react'

import MerchandiseApi from '../../api/creative-hub/MerchandiseApi'
import { getDivisionName } from '../../creativeHubGlobal/util/MerchandiseUtil'
import {
  checkForAllMarkets,
  makeOfferDetails,
} from '../../creativeHubGlobal/util/OfferUtil'
import { assethubImageCheck, promoPrice } from '../../execution/util/ItemUtil'
import noImagePlaceholder from '../../media/NoImagePlaceholder.png'
import { DEACTIVATED } from '../../WeeklyAdAutomation/util/Constants'

const WAOffersDrawer = ({
  isDialogVisible,
  umbrellaDetailsForSelectedStory,
  selectedUmbrella,
  setIsDialogVisible,
  setSelectedUmbrella,
  offers = [],
  setOfferDetails,
  fetchMoreOffers,
  isOfferListLoading,
  setIsOfferListLoading,
  offerMetadata = { last: false, number: 0 },
  setOfferMetadata,
}) => {
  const merchandiseApi = useMemo(() => new MerchandiseApi(), [])
  const [divisions, setDivisions] = useState([])
  const [nextPage, setNextPage] = useState(1)

  const contentEndRef = useRef(null)

  useEffect(() => {
    merchandiseApi.getDivisions().then((response) => setDivisions(response))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers])

  const interSectionCallback = (entries) => {
    const [entry] = entries

    if (entry.isIntersecting && entry.intersectionRatio > 0) {
      if (!offerMetadata?.last && selectedUmbrella) {
        fetchMoreOffers(selectedUmbrella, nextPage)
        setNextPage((prevPage) => prevPage + 1)
        setIsOfferListLoading(true)
      }
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(interSectionCallback, {
      threshold: [0.1],
    })
    observer.observe(contentEndRef.current)

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      contentEndRef.current && observer.unobserve(contentEndRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers])

  const sortedItems = (items) =>
    items.sort((a, b) => a.item_order - b.item_order)

  return (
    <Drawer
      isVisible={isDialogVisible}
      headingText={`Offers`}
      onRequestClose={() => {
        setOfferDetails([])
        setIsDialogVisible(false)
        setSelectedUmbrella({})
        setOfferMetadata({})
      }}
      xs={6}
    >
      <div className="summaryHeader">
        <Breadcrumbs>
          <Breadcrumbs.Item
            onClick={() => setIsDialogVisible(false)}
          >{`Story: ${umbrellaDetailsForSelectedStory?.story?.story_name}`}</Breadcrumbs.Item>
          <Breadcrumbs.Item>
            Umbrella: {selectedUmbrella?.name}{' '}
          </Breadcrumbs.Item>
        </Breadcrumbs>
      </div>
      <div className="offerListContainer">
        {offers?.map((offer, index) => {
          offer.markets = [
            checkForAllMarkets(
              offer?.markets,
              offer?.location_group_id,
              offer?.price_patches,
              offer?.mixed_discount
            ),
          ]
          return (
            <Grid.Container
              data-testid="offer-container"
              spacing="none"
              align="center"
              xs={10}
              className="offer-container"
              index={index}
              key={index + '--' + offer.id}
            >
              <Grid.Item
                xs={10}
                className={classnames(
                  'wa-summary-item--content',
                  offer.status === DEACTIVATED && 'deactivated'
                )}
              >
                <h5 className="wa-summary-item--title">
                  <div>
                    {offer.ivy_offer_id === -1 ? 'Placeholder ' : ''} Offer{' '}
                    {index > -1 ? index + 1 : ''}:{' '}
                    <span className="wa-summary-item--value">
                      {offer.is_key ? (
                        <FontAwesomeIcon
                          icon={faKey}
                          className="fa-duotone key"
                          size="lg"
                          color="black"
                        />
                      ) : null}
                      {offer.name}
                    </span>
                  </div>
                  <div>
                    Ivy Offer Id:
                    <span className="wa-summary-item--value">
                      {offer.ivy_offer_id}
                    </span>
                  </div>
                  <>
                    {offer.division ? (
                      <div>
                        Division:{' '}
                        <span className="wa-summary-item--value">
                          {getDivisionName(divisions, offer.division)}
                        </span>
                      </div>
                    ) : null}
                  </>
                  <>
                    <div>
                      {`Offer Details:  `}
                      <span className="wa-summary-item--value">
                        {makeOfferDetails(offer.circle_offer, offer.mass_offer)}
                      </span>
                    </div>
                  </>
                  {offer.ivy_offer_id !== -1 ? (
                    <>
                      <div>
                        Promo $:{' '}
                        <span className="wa-summary-item--value">
                          {' '}
                          {offer.promo_content}
                        </span>
                      </div>
                      <div>
                        Sale Callout:{' '}
                        <span className="wa-summary-item--value">
                          {offer.sale_handle}
                        </span>
                      </div>
                      <div>
                        Mktg Channels:{' '}
                        {offer.channels?.map((chan, i) => (
                          <span
                            className="wa-summary-item--value"
                            index={i}
                            key={
                              offer.id + '-offer-channel-' + chan.channel_name
                            }
                          >
                            {chan.channel_name}
                            {'; '}
                          </span>
                        ))}
                      </div>
                      <div>
                        Copy Direction:{' '}
                        <span className="wa-summary-item--value">
                          {' '}
                          {offer.copy}
                        </span>
                      </div>
                      <div>
                        Offer Notes:{' '}
                        <span className="wa-summary-item--value">
                          {offer.merch_notes}
                        </span>
                      </div>
                      <div>
                        Markets:{' '}
                        {offer.markets?.map((market, i) => (
                          <span
                            className="wa-summary-item--value"
                            index={i}
                            key={offer.id + '-offer-markets-' + i}
                          >
                            {market}
                            {'; '}
                          </span>
                        ))}
                      </div>
                      {!offer.mixed_discount &&
                      offer.price_patches?.length > 0 ? (
                        <>
                          <Grid.Item
                            className=""
                            xs={12}
                            data-testid="price-patch-header"
                          >
                            <h4>Price Patching:</h4>
                          </Grid.Item>
                          {offer.price_patches?.map((patch = {}, index) => (
                            <>
                              {patch.price_markets?.length > 0 ? (
                                <Grid.Container
                                  data-testid="offer"
                                  spacing="none"
                                  align="center"
                                  xs={12}
                                  key={'patch-' + index}
                                >
                                  <Grid.Item className="" xs={1}>
                                    <h4>Price</h4>
                                  </Grid.Item>
                                  <Grid.Item
                                    className="wa-summary-item--value"
                                    xs={1}
                                  >
                                    {patch.price_patch}
                                  </Grid.Item>
                                  <Grid.Item className="" xs={1}>
                                    <h4>Markets</h4>
                                  </Grid.Item>
                                  <Grid.Item
                                    className="wa-summary-item--value"
                                    xs={9}
                                  >
                                    {patch.price_markets.join('; ')}
                                  </Grid.Item>
                                </Grid.Container>
                              ) : null}
                            </>
                          ))}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </h5>
              </Grid.Item>
              {offer.items.length > 0 ? (
                <Grid.Container
                  spacing="none"
                  justify="space-around"
                  xs={10}
                  className="item-container"
                >
                  {sortedItems(offer.items)?.map((item, i) => (
                    <Grid.Item
                      key={i + '-item-' + item.id}
                      className="image-card"
                      xs={3}
                    >
                      <Card>
                        {assethubImageCheck(
                          item.primary_image_url,
                          item.placeHolderImage
                        ) && <div align="center">AssetHub Image</div>}
                        <Image
                          alt="Item image is not available"
                          height="200px"
                          src={
                            item.primary_image_url
                              ? item.primary_image_url
                              : noImagePlaceholder
                          }
                          width="100%"
                        />

                        <div className="image-content-details">
                          <h5 className="wa-summary-item--title">
                            {item.markets?.length > 0 ? (
                              <div>
                                Markets:{' '}
                                <span className="wa-summary-item--value">
                                  {item.markets.join('; ')}
                                </span>
                              </div>
                            ) : null}
                            {item.art_patch_copy ? (
                              <div>
                                Art Patch Copy:{' '}
                                <span className="wa-summary-item--value">
                                  {item.art_patch_copy}
                                </span>
                              </div>
                            ) : null}
                            <div>
                              DPCI:{' '}
                              <span className="wa-summary-item--value">
                                {item.dpci}
                              </span>
                            </div>
                            <div>
                              TCIN:{' '}
                              <span className="wa-summary-item--value">
                                {item.tcin}
                              </span>
                            </div>
                            <div>
                              Item Desc.:{' '}
                              <span
                                className="summary-item--value"
                                dangerouslySetInnerHTML={{
                                  __html: item.item_name,
                                }}
                              />
                            </div>
                            <div>
                              Reg. Callout:{' '}
                              <span className="wa-summary-item--value">
                                {item?.reg_callout === true ? 'Yes' : 'No'}
                              </span>{' '}
                            </div>
                            <div>
                              Reg.:{' '}
                              <span className="wa-summary-item--value">
                                {item.reg_price}
                              </span>
                            </div>
                            {offer.mixed_discount ? (
                              <div>
                                Promo $ (Mixed Discount):{' '}
                                <span className="wa-summary-item--value">
                                  {promoPrice(offer.price_patches, item)}
                                </span>
                              </div>
                            ) : null}
                            <div>
                              Image Status:{' '}
                              <span className="wa-summary-item--value">
                                {item.image_type}
                              </span>
                            </div>
                            <div>
                              Merch Product Notes:{' '}
                              <span className="wa-summary-item--value">
                                {item.merch_notes}
                              </span>{' '}
                            </div>
                          </h5>
                        </div>
                      </Card>
                    </Grid.Item>
                  ))}
                </Grid.Container>
              ) : (
                <>
                  {offer.ivy_offer_id !== -1 ? (
                    <Grid.Item className="no-items-found" xs={12}>
                      No Featured Items Found.
                    </Grid.Item>
                  ) : null}
                </>
              )}
            </Grid.Container>
          )
        })}
        <div ref={contentEndRef} className="contentEnd"></div>
        {isOfferListLoading && (
          <div className="loadingSpinner">
            <div className="pyramidSpinner">
              <Spinner size="expanded" />
            </div>
          </div>
        )}
      </div>
    </Drawer>
  )
}
export default WAOffersDrawer
