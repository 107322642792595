import {
  Card,
  Divider,
  Grid,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import React, { useState, useEffect, useContext } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import OfferApi from '../../api/creative-hub/OfferApi'
import {
  CanEditContext,
  EffectiveRoleContext,
  MerchantViewContext,
} from '../../context/Context'
import {
  groupArtPatchOffers,
  MapFollowersToLeaders,
} from '../../creativeHubGlobal/util/LeadersUtil'
import { divisionSelection } from '../../creativeHubGlobal/util/MerchandiseUtil'
import {
  displayOffersByChannel,
  sortedModifiedOffers,
} from '../../creativeHubGlobal/util/OfferUtil'
import { getPyramidAbbrByGroupName } from '../../planning/util/ProjectDetailsUtil'
import { canEditExecution } from '../../util/CheckRole'

import Offer from './Offer'

import '../scss/Bucket.scss'

const OfferBucket = (props) => {
  const {
    umbrella,
    umbrellaId,
    setEditOfferArtPatch,
    setIsOfferDrawerOpen,
    setSelectedOffer,
    loading,
    offerElements,
    handleDeleteOffer,
    outcomePriorityId,
    projectId,
    offerErrors,
    projectStartDate,
    projectEndDate,
    storyId,
    storyName,
    pyramidNameId,
    pyramidType,
    projectDescription,
    projectName,
    recentlyClickedOffer,
    setRecentlyClickedOffer,
    setSelectedUmbrellaId,
    marketingChannelFilter = [],
    handleOfferUpdate,
    selectedOffer,
    setIsItemImageModalOpen,
    setItemSelected,
    updatedItemImage,
    selectedStoryId,
  } = props

  const { effectiveRole } = useContext(EffectiveRoleContext)
  const [canEdit, setCanEdit] = useState(false)
  const [offersGroup, setOffersGroup] = useState()
  const { divisions } = useContext(MerchantViewContext)
  const displayDivisions = divisionSelection(divisions)
  const pyramidName =
    getPyramidAbbrByGroupName(umbrella?.merchant_category?.group_name) || ''

  useEffect(() => {
    let leadersWithFollowersArray

    if (typeof offerElements !== 'undefined') {
      let displayOffers = displayOffersByChannel(
        offerElements,
        marketingChannelFilter
      )
      const { followers = [], leaders = [] } = groupArtPatchOffers({
        displayOffers,
        projectDescription,
        projectName,
        pyramidName,
        pyramidNameId,
        pyramidType,
        storyId,
        storyName,
        umbrella,
      })

      leadersWithFollowersArray = MapFollowersToLeaders(leaders, followers)
      setOffersGroup(leadersWithFollowersArray)
    }
  }, [
    offerElements,
    marketingChannelFilter,
    projectDescription,
    projectName,
    pyramidName,
    pyramidNameId,
    pyramidType,
    storyId,
    storyName,
    umbrella,
  ])

  useEffect(() => {
    setCanEdit(effectiveRole?.some((role) => canEditExecution(role)))
  }, [effectiveRole, setCanEdit])

  const draggingItem = {}
  const dragOverItem = {}
  const offerApi = new OfferApi()

  const handleDrop = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      dragOverItem.current = newIndex
      draggingItem.current = oldIndex
      const offersCopy = [...offersGroup]
      const draggingItemContent = offersCopy[draggingItem.current]

      if (
        offersCopy[dragOverItem.current] &&
        offersCopy[draggingItem.current] &&
        draggingItem.current !== dragOverItem.current
      ) {
        offersCopy.splice(draggingItem.current, 1)
        offersCopy.splice(dragOverItem.current, 0, draggingItemContent)
        let end, start
        if (draggingItem.current < dragOverItem.current) {
          start = draggingItem.current
          end = dragOverItem.current
        } else {
          start = dragOverItem.current
          end = draggingItem.current
        }
        //only update changed indicies
        let updatedOfferList = []
        for (let index = start; index <= end; index++) {
          if (offersCopy[index][0] !== undefined) {
            offersCopy[index][0].offer_order = index + 1
            offersCopy[index][0].umbrella_name = umbrella.name
            offersCopy[index][0].story_name = storyName
            offersCopy[index][0].story_id = storyId
            offersCopy[index][0].project_name = projectName
            offersCopy[index][0].pyramid_name =
              pyramidType === 'ent'
                ? pyramidName.toUpperCase()
                : pyramidType.toUpperCase() // e.g. A&A
            offersCopy[index][0].priority = pyramidNameId.pyramidName // description e.g. (Home Top Offers)
            offersCopy[index][0].pyramid_id = pyramidNameId.pyramidId
            updatedOfferList.push(offersCopy[index][0])
          }
        }

        const offerMap = new Map()

        offerElements?.forEach((offer) => offerMap.set(offer.id, offer))
        offerApi.updateOffers(updatedOfferList).then((res) => {
          handleOfferUpdate(
            updatedOfferList[0]?.umbrella_id,
            sortedModifiedOffers(res.data, offerMap)
          )
        })
      }
    }
  }

  const SortableOfferList = SortableContainer(() => (
    <div className="hc-pa-none">
      {loading ? (
        <ProgressBar indeterminate />
      ) : (
        <CanEditContext.Provider value={{ canEdit }}>
          <div data-testid="offers" className="hc-pa-none">
            {offersGroup?.length > 0 ? (
              offersGroup?.map((leaderAndFollowers, i) => {
                const [leader, ...followers] = leaderAndFollowers
                return (
                  <SortableOfferItem
                    leader={leader}
                    followers={followers}
                    index={i}
                    disabled={!canEdit}
                  />
                )
              })
            ) : (
              <Card className="empty-offer">
                No offers are associated to this umbrella.
              </Card>
            )}
          </div>
        </CanEditContext.Provider>
      )}
    </div>
  ))

  const SortableOfferItem = SortableElement(({ leader, followers, index }) => (
    <Card
      className={`bucketItem${
        leader.ivy_offer_id === -1
          ? ' placeholder-offer'
          : leader.status === 'deactivated' || leader.deleted
            ? ' deactivated-offer'
            : ''
      }`}
      key={'offer-' + leader.id + index}
      onSortEnd={
        canEdit && leader.deleted === false && leader.offer_order !== -2
          ? (event) => {
              event.preventDefault()
              handleDrop()
            }
          : null
      }
      data-testid={'offerBucket-' + umbrellaId}
      draggable={false}
    >
      <Grid.Container xs={12}>
        <Grid.Item xs={12}>
          <Offer
            offer={leader}
            umbrella={umbrella}
            followers={followers}
            setEditOfferArtPatch={setEditOfferArtPatch}
            setIsOfferDrawerOpen={setIsOfferDrawerOpen}
            setSelectedOffer={setSelectedOffer}
            handleDeleteOffer={(id) => handleDeleteOffer(id, umbrellaId)}
            outcomePriorityId={outcomePriorityId}
            storyId={storyId}
            storyName={storyName}
            pyramidNameId={pyramidNameId}
            projectId={projectId}
            projectName={projectName}
            canEdit={canEdit}
            projectStartDate={projectStartDate}
            projectEndDate={projectEndDate}
            recentlyClickedOffer={recentlyClickedOffer}
            setRecentlyClickedOffer={setRecentlyClickedOffer}
            setSelectedUmbrellaId={setSelectedUmbrellaId}
            displayDivisions={displayDivisions}
            divisions={divisions}
            selectedOffer={selectedOffer}
            setIsItemImageModalOpen={setIsItemImageModalOpen}
            setItemSelected={setItemSelected}
            updatedItemImage={updatedItemImage}
            selectedStoryId={selectedStoryId}
          />
          {followers?.map((follower) => (
            <React.Fragment key={follower.id}>
              <Divider />
              <Grid.Item className={'hc-bg-grey07 hc-clr-black'} xs={12}>
                <Offer
                  offer={follower}
                  umbrella={umbrella}
                  setIsOfferDrawerOpen={setIsOfferDrawerOpen}
                  setSelectedOffer={setSelectedOffer}
                  outcomePriorityId={outcomePriorityId}
                  storyId={storyId}
                  storyName={storyName}
                  pyramidNameId={pyramidNameId}
                  projectId={projectId}
                  projectName={projectName}
                  isFollower
                  projectStartDate={projectStartDate}
                  projectEndDate={projectEndDate}
                  recentlyClickedOffer={recentlyClickedOffer}
                  setRecentlyClickedOffer={setRecentlyClickedOffer}
                  setSelectedUmbrellaId={setSelectedUmbrellaId}
                  displayDivisions={displayDivisions}
                  divisions={divisions}
                  canEdit={canEdit}
                  selectedOffer={selectedOffer}
                  setIsItemImageModalOpen={setIsItemImageModalOpen}
                  setItemSelected={setItemSelected}
                  updatedItemImage={updatedItemImage}
                  selectedStoryId={selectedStoryId}
                />
              </Grid.Item>
            </React.Fragment>
          ))}
        </Grid.Item>
      </Grid.Container>
    </Card>
  ))

  if (offerErrors) {
    return (
      <CanEditContext.Provider value={{ canEdit }}>
        <div className="hc-pa-normal">
          <Card className="empty-offer">{offerErrors}</Card>
        </div>
      </CanEditContext.Provider>
    )
  }

  return (
    <SortableOfferList
      useDragHandle
      useWindowAsScrollContainer={true}
      onSortEnd={handleDrop}
    />
  )
}

export default OfferBucket
