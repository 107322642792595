export const PYRAMID = 'pyramids'
export const CHANNEL = 'channels'
export const DIVISION = 'divisions'
export const MKTG_PRIORITIES_VIEW = 'MKTG_PRIORITIES_VIEW'
export const PROMO_OFFERS_VIEW = 'PROMO_OFFERS_VIEW'
export const CHANGE_SUMMARY_VIEW = 'CHANGE_SUMMARY_VIEW'
export const DEFAULT_FILTERS_VIEWS = [
  {
    filters: {},
    view_name: PROMO_OFFERS_VIEW,
  },
  {
    filters: {},
    view_name: CHANGE_SUMMARY_VIEW,
  },
  {
    filters: {},
    view_name: MKTG_PRIORITIES_VIEW,
  },
]
