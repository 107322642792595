import { Form } from '@enterprise-ui/canvas-ui-react'
import { useState, useContext } from 'react'

import ChangeMgmtApi from '../../api/creative-hub/ChangeMgmtApi'
import OfferApi from '../../api/creative-hub/OfferApi'
import PyramidApi from '../../api/creative-hub/PyramidApi'
import StoryApi from '../../api/creative-hub/StoryApi'
import { CanEditContext } from '../../context/Context'
import '../scss/InlineEditInput.scss'
import { sortNotesByCreatedTime } from '../../creativeHubGlobal/util/ChangeSummaryUtil'
import { Email } from '../../util/AuthUtils'

const InlineEditInput = ({
  data,
  field,
  value,
  multiline,
  placeholder,
  setRefreshPyramidData,
  isDeactivated = false,
  handlePromoChange,
  handleMerchNotesChange,
  handleCopyChange,
  testCy = null,
  setHistory,
  notes,
  setChangesMade,
  label,
  setNotes,
  fieldName,
  history,
}) => {
  const [editingValue, setEditingValue] = useState(value)
  const permissions = useContext(CanEditContext)
  const canEdit = permissions?.canEdit
  const currentUserEmail = Email()
  const onChange = (event) => {
    setEditingValue(event.target.value)
    if (field === 'copy' || field === 'merch_notes') {
      setChangesMade(true)
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.target.blur()
    }
  }

  const onBlur = (event) => {
    updateValue(data, field, event.target.value)
    if (field === 'promoMessage') return handlePromoChange(event.target.value)
    if (field === 'copy') return handleCopyChange(data.copy)
    if (field === 'merch_notes') return handleMerchNotesChange(data.merch_notes)
  }

  const override = (event) => {
    //   This is required to avoid opening and closing
    //   the Expandable section when editing values
    event.stopPropagation()
  }

  const overrideDragStart = (event) => {
    // This is required to avoid opening and closing
    // the Expandable section when editing values
    event.preventDefault()
    event.stopPropagation()
  }

  const updateValue = async (data, field, value) => {
    if (
      field === 'creative_notes' &&
      value !== null &&
      value !== '' &&
      (data[field] !== value || data[field] === undefined)
    ) {
      data[field] = value
      var oldNotes = notes ? notes : []
      const now = new Date()
      const isoString = now.toISOString()
      var newNote = {
        value: value,
        written_at: isoString,
        written_by: currentUserEmail,
      }
      var updatedNotes = [...oldNotes, newNote]

      const payload = {
        change_time: data.date_changed,
        field_notes: {
          [fieldName]: updatedNotes,
        },
        offer_id: history.offer_id,
        offer_uuid: history.offer_uuid,
        priority: history.priority,
        project_id: history.project_id,
        pyramid_id: history.pyramid_id,
        review: {
          status: history?.review_status?.status,
        },
        story_id: history.story_id,
        umbrella_id: history.umbrella_id,
      }
      //update data takes a list of datas
      const changeMgmtApi = new ChangeMgmtApi()
      try {
        const resp = await changeMgmtApi.bundledUpdates(payload)
        setHistory(resp.data)
        setEditingValue('')
        setNotes(
          sortNotesByCreatedTime(
            resp.data.fields_changed.find((x) => x.field === fieldName).notes
          )
        )
        return resp
      } catch (error) {
        console.log(error)
        return null
      }
    }

    //update Offer Promo Message if data has umbrellaId and and projectId and planning umbrella id return
    if (
      data[field] !== value &&
      data['project_id'] &&
      data['planning_umbrella_id'] &&
      data['promoMessageUpdate']
    ) {
      const payload = {
        project_id: data.project_id,
        promo_message: value,
        umbrella_id: data.planning_umbrella_id,
      }
      //update data
      const offerApi = new OfferApi()
      try {
        const resp = await offerApi.updatePromoMessage(payload)
        return resp
      } catch (error) {
        console.log(error)
        return null
      }
    }

    //update Offer if data has offerId and return
    if (data[field] !== value && data['ivy_offer_id']) {
      data[field] = value
      //update data
      const offerApi = new OfferApi()
      try {
        const resp = await offerApi.updateOffers([data])
        return resp
      } catch (error) {
        console.log(error)
        return null
      }
    }
    //update Story if data has storyId and pyramidId and return
    if (data[field] !== value && data['story_id'] && data['pyramid_id']) {
      data[field] = value
      //update data
      const storyApi = new StoryApi()
      try {
        const resp = await storyApi.updateStory(data)
        return resp
      } catch (error) {
        console.log(error)
        return null
      }
    }
    //update pyramid field if data has pyramidId and return
    if (data[field] !== value && data['pyramid_id']) {
      data[field] = value

      //update data takes a list of datas
      const dataList = [data]
      const pyramidsApi = new PyramidApi()
      try {
        const resp = await pyramidsApi.updatePyramids(dataList)
        setRefreshPyramidData(true)
        return resp
      } catch (error) {
        console.log(error)
        return null
      }
    }
  }

  return (
    <>
      {multiline ? (
        <Form.Field
          className={`inlineInput
            ${placeholder === 'New Story' ? 'inlineStory' : ''}
            ${!canEdit ? 'readOnly' : ''}`}
          type="textarea"
          value={editingValue}
          onBlur={canEdit ? onBlur : override}
          onChange={canEdit ? onChange : override}
          onKeyDown={onKeyDown}
          grow={true}
          onClick={override}
          onDragStart={overrideDragStart}
          draggable={canEdit ? true : false} //required to override
          data-testid="inlineInput"
          placeholder={placeholder}
          disabled={isDeactivated}
          data-cy={testCy}
          size="dense"
          id="inlineEditInput"
          label={label}
        />
      ) : (
        <Form.Field
          className={`inlineInput
            ${
              placeholder === 'New Story' && canEdit
                ? 'inlineStory'
                : 'readStory'
            }
            ${placeholder !== 'New Story' && !canEdit ? 'readOnly' : ''}`}
          value={editingValue}
          onChange={canEdit ? onChange : override}
          onKeyDown={onKeyDown}
          onBlur={canEdit ? onBlur : override}
          onClick={override}
          onDragStart={overrideDragStart}
          draggable //required to override
          data-testid="inlineInput"
          placeholder={placeholder}
          disabled={!canEdit}
          data-cy={testCy}
          id="inlineEditInput"
        />
      )}
    </>
  )
}

export default InlineEditInput
