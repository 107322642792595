import axios from 'axios'

import appConfig from '../../config/appConfig'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'
import { makeChannelIdQueryParam } from '../../creativeHubGlobal/util/CreativeHubGlobalUtil'
import { DEACTIVATED } from '../../WeeklyAdAutomation/util/Constants'

export default class WeeklyAdAutomationApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_project_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }
  createPage = async (payload) =>
    await this.init_project_api()
      .post(`${this.config.markethub.url}weekly-ad/pages`, payload)
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  fetchProjectSummary = async (
    projectId,
    selectedChannels = [],
    channelfilterOptions = [],
    pyramidOptions = []
  ) => {
    const offerFilterParam = channelfilterOptions.length
      ? `&offerFilter=${channelfilterOptions}`
      : ''
    const pyramidParam = pyramidOptions.length
      ? `&pyramidFilter=${encodeURIComponent(pyramidOptions)}`
      : ''
    return await this.init_project_api()
      .get(
        `${
          this.config.markethub.url
        }project-summary/${projectId}${makeChannelIdQueryParam(
          selectedChannels
        )}${offerFilterParam}${pyramidParam}`,
        projectId
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })
  }

  fetchPageDetails = async (pageId) =>
    await this.init_project_api()
      .get(`${this.config.markethub.url}weekly-ad/pages/${pageId}/details`)
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  //UpdatePageDetails
  updatePageDetails = async (pageId, payload) =>
    await this.init_project_api()
      .patch(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}/details`,
        payload
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  fetchPages = async (projectId) =>
    //-------------getPages API Call--------------
    await this.init_project_api()
      .get(`${this.config.markethub.url}weekly-ad/pages?projectId=${projectId}`)
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })
  updateProjectPageDetails = async (payload) =>
    await this.init_project_api()
      .put(`${this.config.markethub.url}weekly-ad/pages/project`, payload)
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  addEntityToPage = async (pageId, payload, entityType) => {
    const transformedPayload = {
      entity_dropped: entityType,
      pyramid: {
        pyramid_id: payload.pyramid_id,
        stories: payload.stories.map((story) => ({
          story_id: story.story_id,
          umbrellas:
            story.umbrellas
              ?.filter((umbrella) => umbrella.umbrella_status !== DEACTIVATED)
              ?.map((umbrella) => ({
                offers:
                  umbrella.offers
                    ?.filter((offer) => offer.offer_status !== DEACTIVATED)
                    ?.map((offer) => offer.offer_id) || [],
                umbrella_id: umbrella.umbrella_id, // Extract only offer IDs
              })) || [],
        })),
      },
    }

    return await this.init_project_api()
      .post(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}/entity`,
        transformedPayload
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })
  }

  deletePageEntity = async (pageId, entityPayload) =>
    await this.init_project_api()
      .delete(`${this.config.markethub.url}weekly-ad/pages/${pageId}/entity`, {
        data: entityPayload,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })
  updatePageEntity = async (pageId, entityPayload) =>
    await this.init_project_api()
      .put(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}/entity`,
        entityPayload
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  deletePage = async (pageId, projectId) =>
    await this.init_project_api()
      .delete(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}?projectId=${projectId}`
      )
      .then((res) => {
        if (res.status === 204) {
          throw new Error(
            'Page already Deleted or Not Found.Please Refresh the Page'
          )
        }
        return res.data
      })
      .catch((e) => {
        throw e
      })

  reorderPages = async (pageId, new_sequence, type) =>
    await this.init_project_api()
      .put(`${this.config.markethub.url}weekly-ad/pages/${pageId}/reorder`, {
        new_sequence,
        type,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  fetchUmbrellaDetailsForStory = async ({ project_id, story_id, umbrellas }) =>
    await this.init_project_api()
      .post(
        `${this.config.markethub.url}item/summary?entityLevel=UMBRELLA&includeDeactivatedUmbrellas=true`,
        {
          project_id,
          story_id,
          umbrellas,
        }
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  fetchOffersForUmbrella = async ({
    project_id,
    story_id,
    umbrellas,
    pageNum,
  }) =>
    await this.init_project_api()
      .post(
        `${this.config.markethub.url}item/summary?entityLevel=OFFER&page=${pageNum}&size=5`,
        {
          project_id,
          story_id,
          umbrellas,
        }
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  getMediaUnits = async (pageId) =>
    await this.init_project_api()
      .get(`${this.config.markethub.url}weekly-ad/pages/${pageId}/media-units`)
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  getMediaUnitdetails = async (pageId, mediaUnitId) =>
    await this.init_project_api()
      .get(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}/media-units/${mediaUnitId}`
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  createMediaUnit = async (pageId, payload) =>
    await this.init_project_api()
      .post(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}/media-units`,
        payload
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  updateMediaUnit = async (pageId, payload) =>
    await this.init_project_api()
      .patch(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}/media-units`,
        payload
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  reorderMediaUnits = async (pageId, mediaUnitId, payload) =>
    await this.init_project_api()
      .put(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}/media-units/${mediaUnitId}/reorder`,
        payload
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  deleteMediaUnit = async (pageId, mediaUnitId) =>
    await this.init_project_api()
      .delete(
        `${this.config.markethub.url}weekly-ad/pages/${pageId}/media-units/${mediaUnitId}`
      )
      .then((res) => {
        if (res.status === 204) {
          throw new Error(
            'Page already Deleted or Not Found.Please Refresh the Page'
          )
        }
        return res.data
      })
      .catch((e) => {
        throw e
      })

  getSeasonsList = async () =>
    await this.init_project_api()
      .get(`${this.config.markethub.url}weekly-ad/pages/color-palette/seasons`)
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })

  updateColorPalette = async (projectId, payload) =>
    await this.init_project_api()
      .put(
        `${this.config.markethub.url}weekly-ad/pages/color-palette?projectId=${projectId}`,
        payload
      )
      .then((res) => res.data)
      .catch((e) => {
        throw e
      })
}
