import { Grid, Form } from '@enterprise-ui/canvas-ui-react'

const WeeklyAdMediaUnitChannelCheckboxes = ({
  mediaUnitData: { channels = [] } = {},
  checked = false,
  disabled = true,
}) => {
  const title =
    channels?.[0]?.channel_id === 3
      ? 'DWA Cover'
      : channels?.[0]?.channel_id === 4
        ? 'DWA Inside'
        : ''
  return (
    <Grid.Container className="category-container-row">
      <Grid.Item className="category-label" xs={2}>
        {'Channels'}
      </Grid.Item>

      <Grid.Item className="checkbox-input" xs={10}>
        {title && (
          <Form.Field
            data-testid="priority-checkbox"
            className="checkbox-media-channels"
            label={title}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            id={`priority-checkbox-${title}`}
          />
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default WeeklyAdMediaUnitChannelCheckboxes
