import { Form } from '@enterprise-ui/canvas-ui-react'
import { Grid, ProgressBar, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { isEmpty } from 'lodash'
import {
  useContext,
  useState,
  useEffect,
  useMemo,
  Fragment,
  useRef,
} from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'

import ChannelApi from '../api/creative-hub/ChannelApi'
import MerchandiseApi from '../api/creative-hub/MerchandiseApi'
import ProjectApi from '../api/creative-hub/ProjectApi'
import PyramidApi from '../api/creative-hub/PyramidApi'
import UmbrellaApi from '../api/creative-hub/UmbrellaApi'
import appConfig from '../config/appConfig'
import DialogView from '../containers/DialogView'
import {
  CanEditContext,
  EffectiveRoleContext,
  MerchantViewContext,
} from '../context/Context'
import { BreadcrumbContext } from '../context/Context'
import OutcomePriorityTable from '../creativeHubGlobal/components/OutcomePriorityTable'
import StickySelectAndFilter from '../creativeHubGlobal/components/StickySelectAndFilter'
import {
  calculateDueDates,
  makeChannelIdQueryParam,
} from '../creativeHubGlobal/util/CreativeHubGlobalUtil'
import { getDefaultSettings } from '../creativeHubGlobal/util/DefaultFiltersUtil'
import { divisionSelection } from '../creativeHubGlobal/util/MerchandiseUtil'
import { formatChannels } from '../ImageTracking/util/ItemUtil'
import { canEditExecution } from '../util/CheckRole'
import { formatToJavaScriptDate } from '../util/MomentUtil'

import AssetHubExecutionModal from './components/AssetHubExecutionModal'
import StoriesTable from './components/StoriesTable'
import UmbrellaOfferChannelSelector from './components/UmbrellaOfferChannelSelector'

import './scss/ExecutionPage.scss'

const ExecutionPage = ({ session = {} }) => {
  const [allFiltersLoaded, setAllFiltersLoaded] = useState(false)
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const config = appConfig()
  const [selectedProject, setSelectedProject] = useState(useLocation().state)
  const [selectedProjectId] = useState(useParams().projectId)
  const [selectedOutcomePriorityId, setSelectedOutcomePriorityId] = useState(
    useParams().pyramidId
  )
  const [selectedMarketingChannelFilter, setMarketingChannelFilter] = useState(
    []
  )
  const [selectedMarketingPriorityFilter, setMarketingPriorityFilter] =
    useState('')
  const [selectedMarketingPyramidFilter, setMarketingPyramidFilter] =
    useState('')
  const [selectedDivisionFilter, setSelectedDivisionFilter] = useState([])
  const [pyramids, setPyramids] = useState()
  const [selectedPyramidType, setSelectedPyramidType] = useState()
  const [selectedPyramidNameId, setSelectedPyramidNameId] = useState()
  const [selectedOutcomeStories, setSelectedOutcomeStories] = useState([])
  const [linkedUmbrellas, setLinkedUmbrellas] = useState([])
  const projectApi = useMemo(() => new ProjectApi(), [])
  const channelApi = useMemo(() => new ChannelApi(), [])
  const pyramidApi = useMemo(() => new PyramidApi(), [])
  const umbrellaApi = useMemo(() => new UmbrellaApi(), [])
  const merchandiseApi = useMemo(() => new MerchandiseApi(), [])
  const [channels, setChannels] = useState([])
  const [divisions, setDivisions] = useState([])
  const [channelsDialogIsVisible, setChannelsDialogIsVisible] = useState(false)
  const [offerUmbrellaChannels, setOfferUmbrellaChannels] = useState([])
  const handleToggleChannelDialog = async () =>
    setChannelsDialogIsVisible((prev) => !prev)
  // eslint-disable-next-line
  const [isOfferDrawerOpen, setIsOfferDrawerOpen] = useState(false)
  const [selectedStoryId, setSelectedStoryId] = useState(useParams().storyId)
  const [canEdit, setCanEdit] = useState(false)
  const { effectiveRole, userDefinedFilters, isUserDefinedFilterLoading } =
    useContext(EffectiveRoleContext)
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [refreshData, setRefreshData] = useState(true)
  const [isItemImageModalOpen, setIsItemImageModalOpen] = useState(false)
  const [itemSelected, setItemSelected] = useState()
  const [selectedUmbrella, setSelectedUmbrella] = useState(null)
  const initialRender = useRef(true)
  const [selectedUmbrellaId, setSelectedUmbrellaId] = useState(
    useParams().umbrellaId
  )
  const [recentlyClickedOffer, setRecentlyClickedOffer] = useState(
    useParams().offerId
  )

  const [updatedItemImage, setUpdatedItemImage] = useState()
  // eslint-disable-next-line
  const [initPyramids, setInitPyramids] = useState('')
  const [isCopyInfoPastDue, setIsCopyInfoPastDue] = useState(false)
  const [isKickoffInfoPastDue, setIsKickoffInfoPastDue] = useState(false)
  const [isImagesInfoPastDue, setIsImagesInfoPastDue] = useState(false)
  const [isWeekOfCopyDueDate, setIsWeekOfCopyDueDate] = useState(false)
  const [isWeekOfFinalDueDate, setIsWeekOfFinalDueDate] = useState(false)
  const [isWeekOfImagesDueDate, setIsWeekOfImagesDueDate] = useState(false)
  const [pyramidsApiLoading, setPyramidsApiLoading] = useState(false)
  const [loadingStories, setLoadingStories] = useState(false)

  const [pastProject, setPastProject] = useState(false)
  const selectedChannelIds = selectedMarketingChannelFilter?.map(
    (channel) => channel.id
  )

  const viewName = 'Promo Offers'

  const { defaultChannels, defaultDivisions, defaultPyramids } =
    getDefaultSettings(userDefinedFilters, 'PROMO_OFFERS_VIEW')

  useEffect(() => {
    setCanEdit(effectiveRole.some((role) => canEditExecution(role)))
  }, [effectiveRole, setCanEdit])

  useEffect(() => {
    if (selectedProject?.project_id) {
      if (allFiltersLoaded) {
        if (
          !(
            selectedMarketingChannelFilter?.length ||
            selectedDivisionFilter?.length
          ) &&
          selectedMarketingChannelFilter.length === 0 &&
          selectedOutcomePriorityId &&
          !initialRender.current
        ) {
          setPyramidsApiLoading(true)
          setSelectedOutcomeStories([])
          // Unnecessary API Call. Need to refactor.
          pyramidApi
            .getPyramids(selectedProject.project_id)
            .then((data) => setPyramids(data))
            .then(() => {
              setPyramidsApiLoading(false)
              handleOutcomePrioritySelection(selectedOutcomePriorityId)
              return
            })
        }

        handleDateUpdate()

        if (refreshData) {
          setRefreshData(false)
          // Fixes an issue where refreshing the Execution View would load old data (kickoff date, due dates, etc.).
          setBreadcrumbContext({
            row: selectedProject,
            setBreadcrumbContext,
          })
          umbrellaApi
            .getEnterpriseUmbrellas(
              selectedProject.project_id,
              selectedProject.start_date,
              selectedProject.end_date
            )
            .then((response) => {
              setLinkedUmbrellas(response)
            })
        }
      }
    } else if (selectedProjectId) {
      projectApi.fetchProjectDetails(selectedProjectId).then((data) => {
        setSelectedProject(data)
        setBreadcrumbContext({
          row: data,
          setBreadcrumbContext,
        })
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProjectId,
    selectedProject,
    selectedMarketingChannelFilter,
    selectedMarketingPriorityFilter,
    selectedDivisionFilter,
    allFiltersLoaded,
  ])

  useEffect(() => {
    if (initialRender.current || !selectedStoryId) return

    const nextURL = `/execution/${selectedProjectId}/${selectedOutcomePriorityId}/${selectedStoryId}`

    window.history.replaceState(null, '', nextURL)
    // eslint-disable-next-line
  }, [selectedStoryId])

  useEffect(() => {
    const umbrellaId = selectedUmbrella?.planning_umbrella_id

    if (initialRender.current || !umbrellaId) return

    const nextURL = `/execution/${selectedProjectId}/${selectedOutcomePriorityId}/${selectedStoryId}/${umbrellaId}`

    window.history.replaceState(null, '', nextURL)
    // eslint-disable-next-line
  }, [selectedUmbrella])

  useEffect(() => {
    const umbrellaId = selectedUmbrella?.planning_umbrella_id

    if (initialRender.current || !recentlyClickedOffer) return

    const nextURL = `/execution/${selectedProjectId}/${selectedOutcomePriorityId}/${selectedStoryId}/${umbrellaId}/${recentlyClickedOffer}`

    window.history.replaceState(null, '', nextURL)
    // eslint-disable-next-line
  }, [recentlyClickedOffer])

  useEffect(() => {
    async function fetchMetadata() {
      const channels = await channelApi.getChannels()
      const divisions = await merchandiseApi.getDivisions()

      setChannels(channels)
      setDivisions(divisions)
    }

    fetchMetadata()
    initialRender.current = false

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!selectedProjectId) return

    setPyramids([])
    setSelectedOutcomeStories([])
    setPyramidsApiLoading(true)

    if (!selectedProject?.project_id || !allFiltersLoaded) return

    const isPriorityFilterEmpty = selectedMarketingPriorityFilter === ''
    const isPyramidOrDivisionFilterPresent =
      selectedMarketingPyramidFilter?.length > 0 ||
      selectedDivisionFilter?.length > 0
    const isChannelOrDivisionFilterPresent =
      selectedMarketingChannelFilter?.length > 0 ||
      selectedDivisionFilter?.length > 0

    if (
      (isPriorityFilterEmpty && isPyramidOrDivisionFilterPresent) ||
      isChannelOrDivisionFilterPresent
    ) {
      const channelIds = []
      if (selectedMarketingChannelFilter.length) {
        selectedMarketingChannelFilter.forEach((channelFilter) => {
          channelIds.push(channelFilter.id)
        })
      }

      pyramidApi
        .getPyramidsByFilter(
          selectedProject?.project_id,
          selectedMarketingPriorityFilter,
          selectedMarketingPyramidFilter,
          channelIds,
          selectedDivisionFilter
        )
        .then((res) => {
          setPyramids(res)
          setPyramidsApiLoading(false)
          selectedOutcomePriorityId &&
            handleOutcomePrioritySelection(selectedOutcomePriorityId)
        })
        .catch((e) => {
          console.log('e', e)
          setPyramidsApiLoading(false)
        })
    } else {
      pyramidApi
        .getPyramids(selectedProject.project_id)
        .then((data) => {
          setInitPyramids(data)
          const filteredPyramidData = selectedMarketingPyramidFilter
            ? data.filter((item) => {
                const selectedPyramidsArray = selectedMarketingPyramidFilter
                  ? selectedMarketingPyramidFilter.split(',')
                  : []

                return selectedPyramidsArray.includes(item.pyramid_type)
              })
            : data
          setPyramidsApiLoading(false)
          setPyramids(filteredPyramidData)
          selectedOutcomePriorityId &&
            handleOutcomePrioritySelection(selectedOutcomePriorityId)
          // Removed to accomodate default filter feature
          // setMarketingChannelFilter([])
          return
        })
        .catch((e) => {
          console.log('e', e)
          setPyramidsApiLoading(false)
          return
        })
    }

    // eslint-disable-next-line
  }, [
    selectedProject,
    selectedMarketingPriorityFilter,
    selectedMarketingPyramidFilter,
    selectedMarketingChannelFilter,
    selectedDivisionFilter,
    allFiltersLoaded,
  ])

  useEffect(() => {
    if (
      !isUserDefinedFilterLoading &&
      selectedProject &&
      channels.length &&
      !isEmpty(divisions)
    ) {
      setMarketingPyramidFilter(defaultPyramids?.join(',') || '')
      let defChannelFilters = formatChannels(channels).filter((channel) =>
        defaultChannels?.includes(channel.id)
      )

      setMarketingChannelFilter(defChannelFilters || [])
      let defDivisionFilters = divisionSelection(divisions).filter((division) =>
        defaultDivisions?.includes(division.id)
      )
      setSelectedDivisionFilter(defDivisionFilters || [])

      setAllFiltersLoaded(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, channels, divisions, isUserDefinedFilterLoading])

  const handleOutcomePrioritySelection = async (outcomePriorityId, reset) => {
    setSelectedOutcomePriorityId(outcomePriorityId)
    setSelectedOutcomeStories([])
    setLoadingStories(true)
    let nextURL

    const response = await pyramidApi.getPyramidDetails(
      outcomePriorityId,
      selectedProject.project_id,
      selectedChannelIds,
      selectedDivisionFilter
    )
    const {
      pyramid_name: pyramidName = '',
      pyramid_id: pyramidId = '',
      pyramid_type: pyramidType = '',
      stories = [],
    } = response
    setSelectedPyramidType(pyramidType)
    setSelectedPyramidNameId({ pyramidId, pyramidName })
    setSelectedOutcomeStories(stories)
    if (selectedStoryId) {
      setSelectedStoryId(selectedStoryId)
    } else {
      if (stories?.length > 0) {
        setSelectedStoryId(stories[0].story_id)
      } else {
        setSelectedStoryId(null)
      }
    }

    if (reset) {
      setSelectedUmbrella(null)
      setSelectedOffer(null)
      nextURL = `/execution/${selectedProjectId}/${
        selectedOutcomePriorityId ? selectedOutcomePriorityId : ''
      }`
    } else {
      const selectedStoryIdUrl = selectedStoryId ? `/${selectedStoryId}` : ''
      const selectedUmbrellaUrl = selectedUmbrellaId
        ? `/${selectedUmbrellaId}`
        : ''
      const recentlyClickedOffer = recentlyClickedOffer
        ? `/${recentlyClickedOffer}`
        : ''
      nextURL = `/execution/${selectedProjectId}/${outcomePriorityId}${selectedStoryIdUrl}${selectedUmbrellaUrl}${recentlyClickedOffer}`
    }
    setLoadingStories(false)
    return window.history.replaceState(null, '', nextURL)
  }

  const handleFilterUpdate = (filterType, value) => {
    if (
      (filterType === 'marketingChannelSelect' || filterType === 'divisions') &&
      value.length === 0 &&
      (value !== selectedMarketingChannelFilter ||
        value !== selectedDivisionFilter)
    ) {
      setPyramidsApiLoading(true)
      pyramidApi
        .getPyramidsByFilter(
          selectedProject?.project_id,
          true,
          selectedMarketingPyramidFilter,
          filterType === 'marketingChannelSelect'
            ? value.map((x) => x.id)
            : selectedChannelIds,
          selectedDivisionFilter
        )
        .then((res) => {
          setPyramids(res)
          setPyramidsApiLoading(false)
        })
      return
    }

    const nextURL = `/execution/${selectedProject?.project_id}`
    setSelectedOutcomePriorityId(null)
    setSelectedStoryId(null)
    setSelectedUmbrella(null)
    setSelectedOffer(null)
    return window.history.replaceState(null, '', nextURL)
  }

  const handleProjectUpdate = async (payload, selectedProjectId) => {
    await projectApi.putProject(payload, selectedProjectId).then((data) => {
      setSelectedProject(data)
      setBreadcrumbContext({
        row: data,
        setBreadcrumbContext,
      })
    })
    handleDateUpdate()
    setRefreshData(true)
  }

  const handleDateUpdate = () => {
    if (selectedProject?.offers_due_date != null) {
      const deadlineDate = formatToJavaScriptDate(
        selectedProject?.offers_due_date
      )
      const imagesDeadlineDate = formatToJavaScriptDate(
        selectedProject?.images_due_date
      )
      const copyDeadlineDate = formatToJavaScriptDate(
        selectedProject?.copy_due_date
      )

      // A negative value means deadline date has passed.
      const daysUntilDeadlineDate = calculateDueDates(deadlineDate)
      const daysUntilImagesDeadlineDate = calculateDueDates(imagesDeadlineDate)
      const daysUntilCopyDeadlineDate = calculateDueDates(copyDeadlineDate)

      // If today's date is Sunday - Wednesday, or Thursday before 8pm, the week of the Final Info Due Date.
      setIsWeekOfFinalDueDate(daysUntilDeadlineDate < 4)
      setIsKickoffInfoPastDue(daysUntilDeadlineDate < 0)
      setIsWeekOfImagesDueDate(daysUntilImagesDeadlineDate < 4)
      setIsImagesInfoPastDue(daysUntilImagesDeadlineDate < 0)
      setIsWeekOfCopyDueDate(daysUntilCopyDeadlineDate < 4)
      setIsCopyInfoPastDue(daysUntilCopyDeadlineDate < 0)
    }
  }

  return (
    <div data-testid="ExecutionPage">
      <MerchantViewContext.Provider
        value={{
          channels,
          divisions,
          linkedUmbrellas,
          project: selectedProject,
        }}
      >
        <div className="execution-note">
          <strong>About this view</strong>: Weekly planning space where Price &
          Promo Activation and Promo Ops will manage offer and pictured item
          details to share with Creative Teams to guide content creation.
          <p>
            <strong>Primary Inputs from these user groups</strong>: Promo Ops,
            Marketing and Admin
          </p>
        </div>

        <CanEditContext.Provider value={{ canEdit }}>
          <StickySelectAndFilter
            selectedDivisionFilter={selectedDivisionFilter}
            setSelectedDivisionFilter={setSelectedDivisionFilter}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            selectedMarketingPyramidFilter={selectedMarketingPyramidFilter}
            selectedMarketingPriorityFilter={selectedMarketingPriorityFilter}
            selectedMarketingChannelFilter={selectedMarketingChannelFilter}
            setMarketingPriorityFilter={setMarketingPriorityFilter}
            setMarketingPyramidFilter={setMarketingPyramidFilter}
            setMarketingChannelFilter={setMarketingChannelFilter}
            pyramidsApiLoading={pyramidsApiLoading}
            channels={channels}
            handleFilterUpdate={handleFilterUpdate}
            pastProject={pastProject}
            setPastProject={setPastProject}
            viewName={viewName}
            session={session}
          />
          <div className="hc-pa-normal">
            <div>
              {selectedProject ? (
                <Grid.Container
                  className="selectedProject"
                  data-testid="selectedProject"
                  spacing="none"
                >
                  <Grid.Item
                    xs={8}
                    align="left"
                    className="projectHeadingExecution"
                  >
                    <h2>{selectedProject.project_description}</h2>
                    {selectedProject.assethub_project_id ? (
                      <Fragment>
                        AssetHub ProjectId:{' '}
                        <a
                          className="C-Anchor"
                          href={`${config.assethub.uiUrl}/project/${selectedProject.assethub_project_uuid}/dashboard`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {selectedProject.assethub_project_id}
                        </a>
                      </Fragment>
                    ) : null}
                    <p className="imageTrackingList">
                      <Link
                        className="C-Anchor"
                        to={`/execution/items/${
                          selectedProject?.project_id
                        }${makeChannelIdQueryParam(selectedChannelIds)}`}
                      >
                        Image Tracking List
                      </Link>
                    </p>
                    <p className="projectSummaryList">
                      <a
                        className="C-Anchor"
                        href={`/execution/projectSummary/${
                          selectedProject?.project_id
                        }${makeChannelIdQueryParam(selectedChannelIds)}`}
                      >
                        Project Summary List
                      </a>
                    </p>
                  </Grid.Item>
                  <Grid.Item
                    xs={4}
                    align="center"
                    className="projectHeadingExecution "
                  >
                    <h2>
                      <DateFormatter
                        date={selectedProject.start_date}
                        format="MM/DD/YYYY"
                      />
                      {' - '}
                      <DateFormatter
                        date={selectedProject.end_date}
                        format="MM/DD/YYYY"
                      />
                    </h2>
                  </Grid.Item>
                  <Grid.Item xs={3} className="projectHeadingExecution">
                    <div className="info-container">
                      <div className="info-container-header">
                        <h4>Promo Priority KO: </h4>
                        <span className="date-input">
                          <Form.Field
                            id="kickoffDate"
                            type="date"
                            disabled={!canEdit}
                            data-testid="kickoffDate"
                            value={selectedProject.kickoff_date}
                            style={
                              isWeekOfFinalDueDate ? { color: '#cc0000' } : null
                            }
                            onChange={async (values) => {
                              const payload = {
                                kickoff_date: values.target.value,
                                updated_date: selectedProject?.updated_date,
                              }
                              await handleProjectUpdate(
                                payload,
                                selectedProjectId
                              )
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={3} className="projectHeadingExecution">
                    <div className="info-container">
                      <div className="info-container-header">
                        <Tooltip
                          content="Post Kickoff Changes May Require Additional Discussion with Creative"
                          location="top-left"
                        >
                          {isKickoffInfoPastDue ? (
                            <h4 className={'kickoff-info-past-due'}>
                              Final Kickoff Info Was Due:
                            </h4>
                          ) : (
                            <h4>Final Offers Due: </h4>
                          )}
                        </Tooltip>
                        <span className="date-input">
                          <Form.Field
                            id="finalOffersDue"
                            disabled={!canEdit}
                            type="date"
                            data-testid="finalOffersDue"
                            style={
                              isWeekOfFinalDueDate ? { color: '#cc0000' } : null
                            }
                            value={selectedProject.offers_due_date}
                            onChange={async (values) => {
                              const payload = {
                                offers_due_date: values.target.value,
                                updated_date: selectedProject?.updated_date,
                              }
                              await handleProjectUpdate(
                                payload,
                                selectedProjectId
                              )
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={3} className="projectHeadingExecution">
                    <div className="info-container">
                      <div className="info-container-header">
                        <Tooltip
                          content="Inventory related art changes may require additional discussion with marketing/creative."
                          location="top"
                        >
                          {isImagesInfoPastDue ? (
                            <h4 className={'kickoff-info-past-due'}>
                              Final Art Changes Were Due:
                            </h4>
                          ) : (
                            <h4>Final Art Changes Due: </h4>
                          )}
                        </Tooltip>
                        <span className="date-input">
                          <Form.Field
                            id="finalImagesDue"
                            type="date"
                            disabled={!canEdit}
                            data-testid="finalImagesDue"
                            style={
                              isWeekOfImagesDueDate
                                ? { color: '#cc0000' }
                                : null
                            }
                            value={selectedProject.images_due_date}
                            onChange={async (values) => {
                              const payload = {
                                images_due_date: values.target.value,
                                updated_date: selectedProject?.updated_date,
                              }
                              await handleProjectUpdate(
                                payload,
                                selectedProjectId
                              )
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={3} className="projectHeadingExecution">
                    <div className="info-container">
                      <div className="info-container-header">
                        <Tooltip
                          content="New Changes May Require Additional Discussion with Creative"
                          location="top"
                        >
                          {isCopyInfoPastDue ? (
                            <h4 className={'kickoff-info-past-due'}>
                              Final $/Copy Were Due:
                            </h4>
                          ) : (
                            <h4>Final $/Copy Due: </h4>
                          )}
                        </Tooltip>
                        <span className="date-input">
                          <Form.Field
                            id="copyDueDate"
                            type="date"
                            data-testid="copyDueDate"
                            disabled={!canEdit}
                            style={
                              isWeekOfCopyDueDate ? { color: '#cc0000' } : null
                            }
                            value={selectedProject.copy_due_date}
                            onChange={async (values) => {
                              const payload = {
                                copy_due_date: values.target.value,
                                updated_date: selectedProject?.updated_date,
                              }
                              await handleProjectUpdate(
                                payload,
                                selectedProjectId
                              )
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </Grid.Item>
                </Grid.Container>
              ) : null}

              {!pyramidsApiLoading ? (
                <OutcomePriorityTable
                  selectedMarketingPriorityFilter={
                    selectedMarketingPriorityFilter
                  }
                  selectedMarketingPyramidFilter={
                    selectedMarketingPyramidFilter
                  }
                  pyramids={pyramids}
                  selectedProject={selectedProject}
                  selectedOutcomePriorityId={selectedOutcomePriorityId}
                  handleOutcomePrioritySelection={
                    handleOutcomePrioritySelection
                  }
                  pyramidsApiLoading={pyramidsApiLoading}
                />
              ) : (
                <ProgressBar
                  className="merch-story-list-progress"
                  indeterminate
                />
              )}
            </div>
          </div>
          {selectedOutcomeStories && selectedOutcomeStories.length > 0 ? (
            <StoriesTable
              handleToggleChannelDialog={handleToggleChannelDialog}
              stories={selectedOutcomeStories}
              pyramidNameId={selectedPyramidNameId}
              pyramidType={selectedPyramidType}
              projectStartDate={selectedProject?.start_date}
              projectEndDate={selectedProject?.end_date}
              projectDescription={selectedProject?.project_description}
              projectId={selectedProject?.project_id}
              projectName={selectedProject?.project_name}
              outcomePriorityId={selectedOutcomePriorityId}
              handleSetOfferUmbrellaChannels={setOfferUmbrellaChannels}
              setSelectedStoryId={setSelectedStoryId}
              setIsOfferDrawerOpen={setIsOfferDrawerOpen}
              setSelectedOffer={setSelectedOffer}
              selectedMarketingChannelFilter={selectedMarketingChannelFilter}
              selectedMarketingPyramidFilter={selectedMarketingPyramidFilter}
              selectedUmbrellaId={selectedUmbrellaId}
              selectedUmbrella={selectedUmbrella}
              setSelectedUmbrella={setSelectedUmbrella}
              recentlyClickedOffer={recentlyClickedOffer}
              setRecentlyClickedOffer={setRecentlyClickedOffer}
              setSelectedUmbrellaId={setSelectedUmbrellaId}
              config={config}
              isItemImageModalOpen={isItemImageModalOpen}
              selectedOffer={selectedOffer}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setItemSelected={setItemSelected}
              updatedItemImage={updatedItemImage}
              selectedStoryId={selectedStoryId}
              session={session}
            />
          ) : (
            loadingStories && (
              <ProgressBar
                className="merch-story-list-progress"
                indeterminate
              />
            )
          )}
          <DialogView
            isVisible={channelsDialogIsVisible}
            onClose={handleToggleChannelDialog}
          >
            <UmbrellaOfferChannelSelector
              pyramidType={selectedPyramidType}
              channels={channels}
              umbrellas={offerUmbrellaChannels}
              isLoading={false}
              selectedStoryId={selectedStoryId}
              projectId={selectedProject?.project_id}
              marketingChannelFilter={selectedMarketingChannelFilter}
              story={selectedOutcomeStories.find(
                (story) => story.story_id === selectedStoryId
              )}
            />
          </DialogView>
          {isItemImageModalOpen && selectedOffer != null && (
            <AssetHubExecutionModal
              isItemImageModalOpen={isItemImageModalOpen}
              selectedOffer={selectedOffer}
              itemSelected={itemSelected}
              setItemSelected={setItemSelected}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setUpdatedItemImage={setUpdatedItemImage}
            />
          )}
        </CanEditContext.Provider>
      </MerchantViewContext.Provider>
    </div>
  )
}
export default ExecutionPage
