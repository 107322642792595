import {
  Avatar,
  Button,
  Grid,
  ProgressBar,
  Spinner,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faPalette, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState, useEffect, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import ProjectApi from '../api/creative-hub/ProjectApi'
import PublishingApi from '../api/creative-hub/PublishingApi'
import WeeklyAdAutomationApi from '../api/creative-hub/WAAutomationApi'
import PlatformApi from '../api/platform-notifications/EmailVerification'
import {
  CanEditContext,
  EffectiveRoleContext,
  WeeklyAdAutomationContext,
} from '../context/Context'
import { BreadcrumbContext } from '../context/Context'
import StickySelectAndFilter from '../creativeHubGlobal/components/StickySelectAndFilterPublishing'
import { sortPyramids } from '../creativeHubGlobal/util/CreativeHubGlobalUtil'
import { pyramid_options } from '../planning/util/ProjectDetailsUtil'
import {
  convertCategoryOptions,
  prepCategoryDateForUpdate,
} from '../publishing/util/helpers'
import { canEditWeeklyAdAutomation } from '../util/CheckRole'

import AdditionalInfoTabs from './AdditionalInfoTabs'
import BookCaptains from './BookCaptains'
import ColorPalette from './ColorPallete'
import PagesAtAGlance from './PagesAtAGlance'
import ProjectSummaryFilter from './ProjectSummaryFilter'
import { formatContacts } from './util/CaptainsUtil'
import { DEFAULT_COLOR } from './util/Constants'
import { NUMBERED } from './util/Constants'
import { ErrorHandler } from './util/ErrorHandler'
import WASummaryList from './WASummaryList'
import './scss/pages.scss'
const WeeklyAdAutomation = (session = {}) => {
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const { state } = useLocation()
  const { projectId } = useParams()
  const projectApi = useMemo(() => new ProjectApi(), [])
  const weeklyAdAutomationApi = useMemo(() => new WeeklyAdAutomationApi(), [])
  const api = useMemo(() => new PublishingApi(), [])
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const [selectedProject, setSelectedProject] = useState(state)
  const [pastProject, setPastProject] = useState(false)
  const [canEdit, setCanEdit] = useState(false)
  const [selectedProjectId] = useState(projectId)
  const [isAdditionalInfoLoading, setIsAdditionalInfoLoading] = useState(false)
  const [isPageListLoading, setIsPageListLoading] = useState(false)
  const [isSummaryListLoading, setIsSummaryListLoading] = useState(false)
  const [isIndividualPageLoading, setIsIndividualPageLoading] = useState(null)
  const [summaryData, setSummaryData] = useState(false)
  const [currentPageInfo, setCurrentPageInfo] = useState({})
  const [mediaUnits, setMediaUnits] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const { pyramids } = summaryData
  const sortedPyramids = sortPyramids(pyramids)
  const [pages, setPages] = useState([])
  const [details, setDetails] = useState([])
  const [bookCaptains, setBookCaptains] = useState({})
  const [isBookCaptainsModalVisible, setIsBookCaptainsModalVisible] =
    useState(false)
  const [colorPalette, setColorPalette] = useState({})
  const [colorPaletteSeasonsList, setColorPaletteSeasonsList] = useState([])
  const [isColorPaletteDrawerVisible, setIsColorPaletteDrawerVisible] =
    useState(false)
  const [isBookCaptainsloading, setIsBookCaptainsloading] = useState(false)
  const [isColorPaleteLoading, setIsColorPaleteLoading] = useState(false)
  const makeToast = ToastProvider.useToaster()
  const defaultChannels = [3, 4]
  const filterOptions = [
    { filterId: 4, id: 'dwa_inside', label: 'DWA Inside' },
    { filterId: 3, id: 'dwa_cover', label: 'DWA Cover' },
    { filterId: 'assigned', id: 'assigned', label: 'Assigned' },
    { filterId: 'unassigned', id: 'unassigned', label: 'Unassigned' },
    ...pyramid_options,
  ]
  const [selectedFilters, setSelectedFilters] = useState([])
  const [expandedEntities, setExpandedEntities] = useState({
    offers: [],
    pyramids: [],
    stories: [],
    umbrellas: [],
  })

  const viewName = 'Weekly Ad'
  const platformApi = new PlatformApi()
  const captains = [
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CrtvHub-CopyWriter',
    'APP-OAUTH2-CrtvHub-Marketing',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
  ]
  const bookCaptainLabels = {
    designer_captain: 'Designer Captain',
    production_designer_captain: 'Production Designer Captain',
  }
  const [contactsByEntitlement, setContactsByEntitlement] = useState()
  const entitlements = []
  const contactOptions = formatContacts(contactsByEntitlement)
  useEffect(() => {
    setCanEdit(effectiveRole?.some((role) => canEditWeeklyAdAutomation(role)))
  }, [effectiveRole, setCanEdit])
  const [avatarColors, setAvatarColors] = useState({})
  const previousFiltersRef = useRef([])

  useEffect(() => {
    if (selectedProjectId) {
      fetchPages(selectedProjectId)
      projectApi.fetchProjectDetails(selectedProjectId).then((data) => {
        setSelectedProject(data)
        setBreadcrumbContext({
          row: data,
          setBreadcrumbContext,
        })
      })
      getCategoryOptions()
      fetchCaptainsNames()
      fetchColorPaletteSeasons()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    selectedProjectId &&
      selectedFilters &&
      fetchProjectSummary(selectedProjectId, selectedFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    do {
      color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
    } while (color === '#FFFFFF')
    return color
  }
  useEffect(() => {
    const colors = {}
    Object.keys(bookCaptainLabels).forEach((key) => {
      colors[key] = getRandomColor()
    })
    setAvatarColors(colors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const mapPageId = (pages) =>
    pages.map((page) => ({ ...page, id: page.page_id }))

  const fetchPages = (projectId) => {
    setIsPageListLoading(true)
    weeklyAdAutomationApi
      .fetchPages(projectId)
      .then(({ pages = [], book_captains = {}, color_palette = [] }) => {
        setPages(mapPageId(pages))
        setBookCaptains(book_captains)
        setColorPalette(color_palette)
        setIsPageListLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsPageListLoading(false)
      })
  }

  const fetchProjectSummary = (projectId, selectedFilters) => {
    const channelIds = selectedFilters
      .filter((filter) => !isNaN(filter.filterId))
      .map((filter) => filter.filterId)

    let filterOptions = selectedFilters
      .filter((filter) => ['assigned', 'unassigned'].includes(filter.filterId))
      .map((filter) => filter.filterId)
    if (
      filterOptions.includes('assigned') &&
      filterOptions.includes('unassigned')
    ) {
      filterOptions = []
    }
    let pyramidOptions = selectedFilters
      .filter((filter) => pyramid_options.includes(filter))
      .map((filter) => filter.value)
    setIsSummaryListLoading(true)
    weeklyAdAutomationApi
      .fetchProjectSummary(
        projectId,
        channelIds.length ? channelIds : defaultChannels,
        filterOptions.length ? filterOptions : [],
        pyramidOptions.length ? pyramidOptions : []
      )
      .then((data) => {
        setSummaryData(data)
        setIsSummaryListLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsSummaryListLoading(false)
      })
  }
  const fetchCaptainsNames = () => {
    for (let i = 0; i < captains.length; i++) {
      platformApi
        .getContactsByEntitlement(captains[i])
        .then((data) => {
          entitlements.push(data)
        })
        .catch((e) =>
          makeToast({
            message: ErrorHandler(e),
            type: 'error',
          })
        )
    }

    setContactsByEntitlement(entitlements)
  }
  const fetchColorPaletteSeasons = () => {
    weeklyAdAutomationApi
      .getSeasonsList()
      .then((data) => {
        setColorPaletteSeasonsList(data)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
      })
  }

  const addEntityToPage = async (updatedPage, { payload, type }) => {
    setIsIndividualPageLoading(updatedPage?.page_id)
    setIsSummaryListLoading(true)
    await weeklyAdAutomationApi
      .addEntityToPage(updatedPage?.page_id, payload, type)
      .then((data) => {
        setPages((prevPages) =>
          mapPageId(
            prevPages?.map((page) =>
              page?.page_id === updatedPage?.page_id ? data : page
            )
          )
        )
        setIsIndividualPageLoading(null)
        fetchProjectSummary(selectedProjectId, selectedFilters)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsIndividualPageLoading(null)
        setIsSummaryListLoading(false)
      })
  }

  const createPage = async (payload) => {
    setIsPageListLoading(true)
    await weeklyAdAutomationApi
      .createPage(payload)
      .then((data) => {
        const { pages = [] } = data
        setPages((prevPages) => mapPageId([...prevPages, ...pages]))
        setIsPageListLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsPageListLoading(false)
      })
  }

  const deletePage = async (pageId) => {
    setIsPageListLoading(true)
    await weeklyAdAutomationApi
      .deletePage(pageId, selectedProjectId)
      .then((data) => {
        const { pages = [] } = data
        setPages(mapPageId(pages))
        setIsPageListLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsPageListLoading(false)
      })
  }

  const getPageDetails = (pageId) => {
    setIsAdditionalInfoLoading(true)
    weeklyAdAutomationApi
      .fetchPageDetails(pageId)
      .then((data) => {
        setDetails(data)
        setIsAdditionalInfoLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsAdditionalInfoLoading(false)
      })
  }
  const updatePageDetails = async (pageId, payload) => {
    if (Object.prototype.hasOwnProperty.call(payload, 'page_color')) {
      setIsIndividualPageLoading(pageId)
    } else {
      setIsAdditionalInfoLoading(true)
    }
    await weeklyAdAutomationApi
      .updatePageDetails(pageId, payload)
      .then((data) => {
        if (!Object.prototype.hasOwnProperty.call(payload, 'page_color')) {
          setDetails(data)
        }
        const updatedPages = pages?.map((page) => {
          const {
            page_color = DEFAULT_COLOR,
            art_director = '',
            copy_writer = '',
            page_image_link = '',
          } = data
          if (page?.page_id === pageId) {
            return {
              ...page,
              art_director: art_director,
              copy_writer: copy_writer,
              page_color: page_color,
              page_image_link: page_image_link,
            }
          }
          return page
        })
        setPages(mapPageId(updatedPages))
        setIsAdditionalInfoLoading(false)
        setIsIndividualPageLoading(null)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsAdditionalInfoLoading(false)
        setIsIndividualPageLoading(null)
      })
  }

  const deletePageSummary = async (pageId, entityPayload) => {
    setIsAdditionalInfoLoading(true)
    setIsIndividualPageLoading(pageId)
    setIsSummaryListLoading(true)
    await weeklyAdAutomationApi
      .deletePageEntity(pageId, entityPayload)
      .then((data) => {
        setPages((prevPages) =>
          mapPageId(
            prevPages?.map((page) => (page?.page_id === pageId ? data : page))
          )
        )
        setDetails((prevDetails) => ({
          ...prevDetails,
          page_title: data?.page_title,
        }))
        const { displayNumber = 1, showAdditionalInfo = true } = currentPageInfo
        setCurrentPageInfo({ ...data, displayNumber, showAdditionalInfo })
        setIsAdditionalInfoLoading(false)
        setIsIndividualPageLoading(null)
        fetchProjectSummary(selectedProjectId, selectedFilters)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsAdditionalInfoLoading(false)
        setIsIndividualPageLoading(null)
        setIsSummaryListLoading(false)
      })
  }

  const updatePageSummary = async (pageId, entityPayload) => {
    setIsAdditionalInfoLoading(true)
    setIsIndividualPageLoading(pageId)
    await weeklyAdAutomationApi
      .updatePageEntity(pageId, entityPayload)
      .then((data) => {
        setPages((prevPages) =>
          mapPageId(
            prevPages?.map((page) => (page?.page_id === pageId ? data : page))
          )
        )
        setIsAdditionalInfoLoading(false)
        setIsIndividualPageLoading(null)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsAdditionalInfoLoading(false)
        setIsIndividualPageLoading(null)
      })
  }

  const reorderWAPages = async ({
    activePage: { type = NUMBERED, id },
    overIndex,
  }) => {
    setIsPageListLoading(true)
    setIsSummaryListLoading(true)
    await weeklyAdAutomationApi
      .reorderPages(id, overIndex + 1, type)
      .then(({ pages = [] }) => {
        setPages(mapPageId(pages))
        setIsPageListLoading(false)
        fetchProjectSummary(selectedProjectId, selectedFilters)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsPageListLoading(false)
        setIsSummaryListLoading(false)
      })
  }

  const getMediaUnits = (pageId) => {
    setIsAdditionalInfoLoading(true)
    weeklyAdAutomationApi
      .getMediaUnits(pageId)
      .then((data) => {
        const { media_units } = data
        setMediaUnits(media_units)
        setIsAdditionalInfoLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsAdditionalInfoLoading(false)
      })
  }

  const createMediaUnit = (pageId, channel) => {
    setIsAdditionalInfoLoading(true)
    const payload = {
      channels: [channel],
      end_date: prepCategoryDateForUpdate(selectedProject?.end_date),
      order: mediaUnits.length + 1,
      project_id: selectedProjectId,
      start_date: prepCategoryDateForUpdate(selectedProject?.start_date),
    }
    weeklyAdAutomationApi
      .createMediaUnit(pageId, payload)
      .then((data) => {
        const createdMediaUnit = { id: data?.media_unit_uuid, name: '' }
        setMediaUnits((prevMediaUnits) => [...prevMediaUnits, createdMediaUnit])
        setIsAdditionalInfoLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsAdditionalInfoLoading(false)
      })
  }
  const deleteMediaUnit = (pageId, mediaUnitId) => {
    setIsAdditionalInfoLoading(true)
    weeklyAdAutomationApi
      .deleteMediaUnit(pageId, mediaUnitId)
      .then((data) => {
        const { media_units } = data
        setMediaUnits(media_units)
        setIsAdditionalInfoLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsAdditionalInfoLoading(false)
      })
  }
  const reorderMediaUnits = (pageId, mediaUnitId, payload) => {
    setIsAdditionalInfoLoading(true)
    weeklyAdAutomationApi
      .reorderMediaUnits(pageId, mediaUnitId, payload)
      .then((data) => {
        const { media_units } = data
        setMediaUnits(media_units)
        setIsAdditionalInfoLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsAdditionalInfoLoading(false)
      })
  }

  const getCategoryOptions = async () => {
    try {
      const categoryFilters = await api.getCategoriesByProjectId(projectId)
      setCategoryOptions(
        convertCategoryOptions(categoryFilters, 'is_deals_view')
      )
    } catch (e) {
      console.log('Error: ', e)
    }
  }
  const getInitials = (name) => {
    const nameParts = name?.split('.')
    const initials = nameParts
      ?.map((part) => part.charAt(0).toUpperCase())
      .join('')
    return initials
  }
  const updateProjectPageDetails = async (payload, type) => {
    if (type === 'ColorPalette') setIsColorPaleteLoading(true)
    if (type === 'BookCaptains') setIsBookCaptainsloading(true)
    await weeklyAdAutomationApi
      .updateProjectPageDetails(payload)
      .then((data) => {
        const { designer_captain = '', production_designer_captain = '' } = data
        setBookCaptains({ designer_captain, production_designer_captain })
        const { season_name = '', assethub_season_image_link = '' } = data
        setColorPalette({ assethub_season_image_link, season_name })
        setIsBookCaptainsloading(false)
        setIsColorPaleteLoading(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsBookCaptainsloading(false)
        setIsColorPaleteLoading(false)
      })
  }

  const handleClearFilters = () => {
    if (selectedFilters.length != 0) {
      previousFiltersRef.current = []
      setSelectedFilters([])
      fetchProjectSummary(selectedProjectId, [])
    }
  }

  const applyFilters = () => {
    const previousFilters = previousFiltersRef.current
    if (JSON.stringify(previousFilters) === JSON.stringify(selectedFilters)) {
      return
    }
    previousFiltersRef.current = selectedFilters
    fetchProjectSummary(selectedProjectId, selectedFilters)
  }

  return (
    <WeeklyAdAutomationContext.Provider
      value={{
        addEntityToPage,
        categoryOptions,
        contactOptions,
        createMediaUnit,
        createPage,
        currentPageInfo,
        deleteMediaUnit,
        deletePage,
        deletePageSummary,
        details,
        expandedEntities,
        getMediaUnits,
        getPageDetails,
        isAdditionalInfoLoading,
        isIndividualPageLoading,
        makeToast,
        mapPageId,
        mediaUnits,
        pages,
        reorderMediaUnits,
        reorderWAPages,
        selectedProject,
        setCurrentPageInfo,
        setDetails,
        setExpandedEntities,
        setIsAdditionalInfoLoading,
        setMediaUnits,
        setPages,
        sortedPyramids,
        summaryData,
        updatePageDetails,
        updatePageSummary,
        updateProjectPageDetails,
      }}
    >
      <CanEditContext.Provider value={{ canEdit }}>
        <Grid.Container>
          <Grid.Item xs={selectedProjectId ? 7 : 12}>
            <StickySelectAndFilter
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              pastProject={pastProject}
              setPastProject={setPastProject}
              session={session}
              viewName={viewName}
            />
          </Grid.Item>
          {selectedProjectId && (
            <>
              <Grid.Item xs={5} className="button-container">
                {isBookCaptainsloading ? (
                  <Grid.Item xs={4} className="button-container">
                    <Spinner className="avatar-book-captains hc-mr-xl" />
                  </Grid.Item>
                ) : (
                  Object.entries(bookCaptains)
                    .filter(([key, value]) => value)
                    .map(([key, value], index) => (
                      <Tooltip
                        key={index}
                        content={`${bookCaptainLabels[key]} - ${value}`}
                        location="bottom"
                      >
                        <Avatar
                          aria-label={`Avatar for ${value}`}
                          className="avatar-book-captains hc-mr-normal"
                          style={{ backgroundColor: avatarColors[key] }}
                        >
                          {getInitials(value)}
                        </Avatar>
                      </Tooltip>
                    ))
                )}

                <Button
                  type="secondary"
                  id={'book-captains-button'}
                  onClick={() => setIsBookCaptainsModalVisible(true)}
                  disabled={isBookCaptainsloading || isPageListLoading}
                >
                  {`Add/Edit Captains`}
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    className="group-icon-color"
                  />
                </Button>
              </Grid.Item>
            </>
          )}
        </Grid.Container>

        {selectedProjectId && (
          <Grid.Container justify="flex-start" spacing="dense">
            <Grid.Item xs={3}>
              <ProjectSummaryFilter
                filterOptions={filterOptions}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            </Grid.Item>
            <Grid.Item xs={2}>
              <Button
                type="primary"
                id={'color-palette-button'}
                onClick={applyFilters}
                disabled={isSummaryListLoading}
              >
                Apply Filters
              </Button>
              <Button
                type="secondary"
                id={'book-captains-button'}
                onClick={handleClearFilters}
                disabled={isSummaryListLoading}
              >
                Clear Filters
              </Button>
            </Grid.Item>
            <Grid.Item xs={7} className="button-container">
              <Button
                type="primary"
                id={'color-palette-button'}
                onClick={() => setIsColorPaletteDrawerVisible(true)}
                disabled={isColorPaleteLoading || isPageListLoading}
              >
                {`${
                  colorPalette?.season_name
                    ? colorPalette?.season_name
                    : 'Select Color Palette'
                }`}
                <FontAwesomeIcon
                  icon={faPalette}
                  className="group-icon-color"
                />
              </Button>
            </Grid.Item>
          </Grid.Container>
        )}

        {selectedProjectId ? (
          <Grid.Container
            xs={12}
            style={{ height: `calc(100vh - 208px)`, overflow: 'hidden' }}
          >
            <Grid.Item
              xs={3}
              className="hc-pt-expanded"
              style={{ height: '100%' }}
            >
              {isSummaryListLoading ? (
                <ProgressBar indeterminate />
              ) : (
                <WASummaryList />
              )}
            </Grid.Item>

            <Grid.Item
              xs={currentPageInfo?.showAdditionalInfo ? 2 : 9}
              className="hc-pt-expanded"
              style={{ height: '100%' }}
            >
              {isPageListLoading ? (
                <ProgressBar indeterminate />
              ) : (
                <PagesAtAGlance />
              )}
            </Grid.Item>
            {currentPageInfo?.showAdditionalInfo && (
              <Grid.Item
                sm={7}
                className="hc-pt-expanded"
                style={{ height: '100%' }}
              >
                <AdditionalInfoTabs pageInfo={currentPageInfo} />
              </Grid.Item>
            )}
          </Grid.Container>
        ) : null}

        <BookCaptains
          isBookCaptainsModalVisible={isBookCaptainsModalVisible}
          setIsBookCaptainsModalVisible={setIsBookCaptainsModalVisible}
          bookCaptains={bookCaptains}
          setBookCaptains={setBookCaptains}
          projectId={projectId}
        />
        <ColorPalette
          projectId={selectedProjectId}
          colorPaletteSeasonsList={colorPaletteSeasonsList}
          setColorPalette={setColorPalette}
          colorPalette={colorPalette}
          isColorPaletteDrawerVisible={isColorPaletteDrawerVisible}
          setIsColorPaletteDrawerVisible={setIsColorPaletteDrawerVisible}
          isColorPaleteLoading={isColorPaleteLoading}
        />
      </CanEditContext.Provider>
    </WeeklyAdAutomationContext.Provider>
  )
}

export default WeeklyAdAutomation
