import {
  Grid,
  Table,
  Tooltip,
  Button,
  Input,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import {
  faCircleCheck,
  faPencilAlt,
  faTrashAlt,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState, useEffect, useMemo } from 'react'

import ChangeMgmtApi from '../api/creative-hub/ChangeMgmtApi'
import { CanEditContext, EffectiveRoleContext } from '../context/Context'
import {
  getOfferChangeType,
  getChangeSummaryFieldValue,
  getSelectedChannelName,
  groupByChangeRecords,
  isChangeSummaryFirstPage,
  isChangeSummaryLastPage,
  sortNotesByCreatedTime,
  VIEW_LINK_NAMES,
  statusChangePayload,
  activityChangePayload,
  approveAllPayload,
  formatUpdateTime,
  formatEmailToUsername,
  formatDcpi,
  categorizeOfferStatus,
  groupByTcin,
} from '../creativeHubGlobal/util/ChangeSummaryUtil'
import { getDivisionName } from '../creativeHubGlobal/util/MerchandiseUtil'
import InlineEditInput from '../planning/components/InlineEditInput'
import { getPyramidFullName } from '../planning/util/ProjectDetailsUtil'
import { Email } from '../util/AuthUtils'
import {
  isMarketing,
  isCreativeArt,
  isCreativeProducer,
  isCreativeProjectManager,
  isAdmin,
  isCreativeCopyWriter,
} from '../util/CheckRole'
import { formatToDateTime } from '../util/MomentUtil'
import '../scss/changeSummary.scss'

const BundledChangeSummaryView = (props) => {
  const changeMgmtApi = useMemo(() => new ChangeMgmtApi(), [])

  const [setOfferAuditData] = useState()
  const [setOnFirstPage] = useState(true)
  const [setOnLastPage] = useState(false)
  const [setPageNumber] = useState(1)
  const [refreshData, setRefreshData] = useState(true)
  const { canEdit } = useContext(CanEditContext)
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const canApprove = effectiveRole?.some((role) => isMarketing(role))
  const [toggleExpand, setToggleExpand] = useState(false)
  const canCheckArt = effectiveRole?.some(
    (role) =>
      isCreativeArt(role) ||
      isCreativeProducer(role) ||
      isCreativeProjectManager(role) ||
      isAdmin(role)
  )
  const canCheckCopy = effectiveRole?.some(
    (role) =>
      isCreativeCopyWriter(role) ||
      isCreativeProducer(role) ||
      isCreativeProjectManager(role) ||
      isAdmin(role)
  )
  const setToaster = ToastProvider.useToaster()
  const {
    channels = [],
    divisions = {},
    history: hist = {},
    uniqueKey,
    makeChangeSummaryPayload,
    changePage,
    curProjectIds = {},
    query,
    pageNumber,
  } = props
  const [history, setHistory] = useState(hist)
  const [, setNotes] = useState([])

  const currentUserEmail = Email()
  const [editNotes, setEditNotes] = useState('')

  const [editIndex, setEditIndex] = useState(null)
  const [editRowIndex, setEditRowIndex] = useState(null)

  const [offerUnitData, setOfferUnitData] = useState()

  const approvalOptions = [
    {
      id: 1,
      label: 'Pending',
      value: 'pending',
    },
    {
      id: 2,
      label: 'Approved',
      value: 'approved',
    },
    {
      id: 3,
      label: 'Not Approved',
      value: 'cancelled',
    },
  ]

  const {
    changes = [],
    date_changed = '',
    deep_links = {},
    offer_id = 0,
    offer_uuid = '',
    offer_name = '',
    marketing_channels = [],
    media_units = [],
    priority = '',
    project_id = '',
    project_desc = '',
    pyramid_id = '',
    pyramid_name = '',
    story_id = '',
    story_name = '',
    umbrella_id = '',
    umbrella_name = '',
    associated_buyer = '',
    division = '',
    start_date = new Date(),
    is_approve_all = false,
  } = history || {}

  const buildPayload = (changeRecord, fieldName, updatedNotes) => ({
    change_time: changeRecord.date_changed,
    field_notes: {
      [fieldName]: updatedNotes,
    },
    offer_id,
    offer_uuid,
    priority,
    project_id,
    pyramid_id,
    review: {
      status: changeRecord.review_status?.status,
    },
    story_id,
    umbrella_id,
  })

  const handleDelete = async ({
    fieldName,
    changeRecord,
    fieldValueIndex = 0,
  }) => {
    const filedNameValues = changeRecord.fields_changed.filter(
      (field) => field.field === fieldName
    )
    const updatedNotes = filedNameValues[0]?.notes.filter(
      (_, i) => i !== fieldValueIndex
    )
    const payload = buildPayload(changeRecord, fieldName, updatedNotes)

    const response = await changeMgmtApi.bundledUpdates(payload)
    response?.data && setHistory(response?.data)
    setNotes(sortNotesByCreatedTime(updatedNotes))
  }

  const handleSaveEdit = async ({
    fieldName,
    changeRecord,
    fieldValueIndex = 0,
  }) => {
    if (fieldValueIndex !== null) {
      const filedNameValues = changeRecord.fields_changed.filter(
        (field) => field.field === fieldName
      )
      const updatedNotes = filedNameValues[0]?.notes
      updatedNotes[fieldValueIndex].value = editNotes
      const payload = buildPayload(changeRecord, fieldName, updatedNotes)

      const response = await changeMgmtApi.bundledUpdates(payload)
      response?.data && setHistory(response?.data)
      setNotes(sortNotesByCreatedTime(updatedNotes))
      setEditIndex(null)
      setEditNotes('')
    }
  }
  const handleEdit = (index, rowIndex) => {
    setEditIndex(index)
    setEditRowIndex(rowIndex)
  }

  useEffect(() => {
    if (!refreshData) {
      const payload = makeChangeSummaryPayload({
        changePage,
        channels,
        curProjectIds,
        pageNumber,
        query,
      })
      changeMgmtApi.getBundledChangeSummary(payload).then((res) => {
        setOfferAuditData(res.data)
        setOnFirstPage(isChangeSummaryFirstPage(res.page_num))
        setPageNumber(res.page_num)
        setOnLastPage(
          isChangeSummaryLastPage({
            pageNum: res.page_num,
            totalRecords: res.total,
          })
        )
        setHistory(res)
      })
      setRefreshData(true)
    }
  }, [
    refreshData,
    changeMgmtApi,
    setOfferAuditData,
    setOnFirstPage,
    setOnLastPage,
    setPageNumber,
    changePage,
    channels,
    curProjectIds,
    makeChangeSummaryPayload,
    pageNumber,
    query,
    history,
  ])

  const handleStatusChange = async ({ value, fieldName, changeRecord }) => {
    const payload = statusChangePayload({
      changeRecord,
      fieldName,
      fieldValueIndex: 0,
      history,
      value,
    })

    const response = await changeMgmtApi.bundledUpdates(payload)

    response?.data && setHistory(response?.data)
  }
  const handleChannelActivityChange = async ({
    activityName,
    channelName,
    value,
    fieldName,
    changeRecord,
  }) => {
    const payload = activityChangePayload({
      activityName,
      changeRecord,
      channelName,
      currentUserEmail,
      fieldName,
      fieldValueIndex: 0,
      history,
      value,
    })

    const response = await changeMgmtApi.bundledUpdates(payload)

    response?.data && setHistory(response?.data)
  }

  const expandOfferView = (offer_id) => {
    if (!toggleExpand) {
      setToggleExpand(true)
      setOfferUnitData(offer_id)
    } else {
      setToggleExpand(false)
      setOfferUnitData(null)
    }
  }

  const handleApproveAll = async () => {
    const payload = approveAllPayload(history)
    const response = await changeMgmtApi.approveAllChanges(payload, setToaster)
    response?.data && setHistory(response?.data)
  }

  const result = groupByChangeRecords(history)

  const offerStatusCategory = categorizeOfferStatus(
    result,
    canCheckArt,
    canCheckCopy
  )

  const fullNameUpper = getPyramidFullName(pyramid_name).toUpperCase()
  let pyramidName

  if (fullNameUpper === 'FOOD & BEVERAGE') {
    pyramidName = 'F&B'
  } else if (fullNameUpper === 'APPAREL & ACCESSORIES') {
    pyramidName = 'A&A'
  } else if (fullNameUpper === 'ESSENTIALS') {
    pyramidName = 'ESS'
  } else {
    pyramidName = fullNameUpper
  }

  const { groupedByTcin, sortedTcinKeys } = groupByTcin(result)

  const dateFields = ['Offer Start Date', 'Offer End Date']

  return (
    <div
      className="timeline-item tableRow"
      key={`${date_changed}-${offer_id}-${changes[0]?.fields_changed[0]?.field}`}
    >
      <Grid.Container xs={12} className="flex-center">
        <Grid.Item xs className="timeline-item--content title-icon ">
          <FontAwesomeIcon
            onClick={() => expandOfferView(offer_id)}
            style={{ fill: 'black' }}
            className={`media-unit-expand-icon ${
              toggleExpand
                ? 'rotate-clockwise-45'
                : 'rotate-counter-clockwise-45'
            }`}
            icon={faAngleDown}
            size="lg"
          />
          <span className="font-header">
            PROJECT:{' '}
            <span className="timeline-item--value">{project_desc}</span>{' '}
            {offer_id !== -1 ? (
              <span className="timeline-item--value">
                OFFER START DATE:{' '}
                <DateFormatter
                  date={start_date}
                  format="MM/DD/YYYY"
                  timezone="America/Los_Angeles"
                />{' '}
              </span>
            ) : null}
            OFFER: <span className="timeline-item--value">{offer_name}</span>{' '}
          </span>
        </Grid.Item>
        <Grid.Item xs={4} className="timeline-item--content">
          <h5 className="timeline-item--title offer-list-data">
            <Grid.Item xs={5} className="timeline-item--content">
              <span className="timeline-item--value">
                <strong>{getDivisionName(divisions, division)}</strong>
              </span>{' '}
            </Grid.Item>
            <Grid.Item xs={3} className="timeline-item--content">
              <span className="timeline-item--value">
                <strong>{pyramidName}</strong>
              </span>
            </Grid.Item>
            <Grid.Item xs={4} className="timeline-item--content">
              <span
                className="timeline-item--value"
                style={{
                  color:
                    offerStatusCategory === 'Auto-approved'
                      ? 'gray'
                      : offerStatusCategory === 'Not Approved'
                        ? 'black'
                        : offerStatusCategory === 'Pending Changes' ||
                            offerStatusCategory === 'Pending Approval'
                          ? 'red'
                          : offerStatusCategory === 'Completed'
                            ? 'green'
                            : 'black',
                  fontSize: '14px',
                }}
              >
                {offerStatusCategory}
              </span>
            </Grid.Item>
          </h5>
        </Grid.Item>
      </Grid.Container>
      <br />
      {toggleExpand && offerUnitData === offer_id && (
        <>
          <Grid.Container xs={12} className="offer-details-container">
            <Grid.Item xs={4} className="timeline-item--content">
              <h5 className="timeline-item--title">
                IVY OFFER ID:{' '}
                <span className="timeline-item--value">{offer_id}</span> <br />
                PRIORITY:{' '}
                <span className="timeline-item--value">{priority}</span>
                <br />
                MKTG CHANNELS:{' '}
                {marketing_channels?.map((channelId, i) => (
                  <span
                    className="timeline-item--value"
                    key={`market-channel-${i}`}
                  >
                    {getSelectedChannelName(channels, channelId) + '; '}
                  </span>
                ))}{' '}
                <br />
              </h5>
            </Grid.Item>
            <Grid.Item xs={4} className="timeline-item--content">
              <h5 className="timeline-item--title">
                ASSOC. BUYER:{' '}
                <span className="timeline-item--value">{associated_buyer}</span>{' '}
                <br />
                MKTG STORY:{' '}
                <span className="timeline-item--value">{story_name}</span>{' '}
                <br />
                UMBRELLA:{' '}
                <span className="timeline-item--value">
                  {umbrella_name}
                </span>{' '}
                <br />
              </h5>
            </Grid.Item>

            <Grid.Item xs={2}>
              {offer_id !== -1 ? (
                <>
                  <h5 className="timeline-item--title">MKTG Approve All: </h5>
                  <span className="mktg-approve-all">
                    <Input.Select
                      id="marketingApproval"
                      options={[
                        {
                          label: '',
                          value: '',
                        },
                        {
                          label: 'Approve All',
                          value: 'approve_all',
                        },
                      ]}
                      onChange={handleApproveAll}
                      data-testid="marketingApproveAll"
                      disabled={!canApprove || is_approve_all === true}
                    />
                  </span>{' '}
                </>
              ) : (
                ''
              )}
            </Grid.Item>
            <Grid.Item xs={2} className="timeline-item--content">
              {Object.keys(deep_links).map((deepLink = {}) => (
                <span className="timeline-item--value">
                  <a
                    className="C-Anchor"
                    href={`${deep_links[deepLink]}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {VIEW_LINK_NAMES[deepLink]}
                  </a>
                </span>
              ))}
            </Grid.Item>
          </Grid.Container>
          <br />
          <Grid.Container xs={12} className="offer-details-container">
            <Grid.Item>
              <h5 className="timeline-item--title">
                {media_units?.length < 1 ? (
                  <>
                    MEDIA UNIT NAME:{' '}
                    <span className="timeline-item--value">N/A</span>
                  </>
                ) : (
                  media_units?.map((item) => (
                    <>
                      MEDIA UNIT NAME:{' '}
                      <span className="timeline-item--value">{item?.name}</span>
                      <br />
                    </>
                  ))
                )}{' '}
              </h5>
            </Grid.Item>
            <Grid.Item>
              <h5 className="timeline-item--title">
                {media_units?.length < 1 ? (
                  <>
                    CATEGORIES:{' '}
                    <span className="timeline-item--value">N/A</span>
                  </>
                ) : (
                  media_units?.map((item) => (
                    <>
                      CATEGORIES:{' '}
                      {item?.categories?.map((category, i) => (
                        <span
                          className="timeline-item--value"
                          key={`category-${i}`}
                        >
                          {category}
                        </span>
                      ))}
                      <br />
                    </>
                  ))
                )}{' '}
                <br />
              </h5>
            </Grid.Item>
          </Grid.Container>

          {sortedTcinKeys.map((tcin, index) => (
            <Grid.Container xs={12} className="result-container" key={index}>
              <Grid.Item xs={12} className="timeline-item--content">
                <h4
                  className="timeline-item--date"
                  style={{
                    backgroundColor: offer_id === -1 ? '#ffeef9' : null,
                  }}
                >
                  <span>
                    {' '}
                    {getOfferChangeType(
                      groupedByTcin[tcin][0]?.origin,
                      offer_id
                    )}
                    :
                  </span>
                  {` ${formatToDateTime(
                    groupedByTcin[tcin][0]?.date_changed
                  )} `}
                  {groupedByTcin[tcin][0]?.post_ad_close}
                  {groupedByTcin[tcin][0]?.origin === 'CreativeHub' ? (
                    <span>
                      CHANGED BY: {groupedByTcin[tcin][0]?.changed_by}
                    </span>
                  ) : null}
                </h4>
              </Grid.Item>
              <Table
                alternateRowColor={false}
                style={{ paddingBottom: '10' }}
                className="table-container"
              >
                <Table.Head
                  key={`table-head-${groupedByTcin[tcin][0]?.dpci}-${uniqueKey}`}
                  className="table-head"
                >
                  {!groupedByTcin[tcin][0]?.notIncluded ? (
                    <Table.Row
                      key={`item-${groupedByTcin[tcin][0]?.dpci}-${uniqueKey}`}
                      align="center"
                      clickable={false}
                    >
                      <h5 className="timeline-item--title">
                        DPCI:{' '}
                        <span className="timeline-item--value">
                          {groupedByTcin[tcin][0]?.dpci};
                        </span>
                        TCIN:{' '}
                        <span className="timeline-item--value">
                          {groupedByTcin[tcin][0]?.tcin};
                        </span>
                        DESCRIPTION:{' '}
                        <span
                          className="timeline-item--value"
                          dangerouslySetInnerHTML={{
                            __html: groupedByTcin[tcin][0]?.description,
                          }}
                        />
                      </h5>
                    </Table.Row>
                  ) : null}
                  {groupedByTcin[tcin][0]?.fields_changed?.length > 0 ? (
                    <Table.Row
                      key={`${tcin?.date_changed}-${tcin?.offer_id}-${uniqueKey}`}
                    >
                      <Table.Header
                        key={`field-header-offer-${uniqueKey}`}
                        xs={2}
                      >
                        <strong>Field</strong>
                      </Table.Header>
                      <Table.Header
                        key={`old-value-header-offer-${uniqueKey}`}
                        xs={2}
                      >
                        <strong>Old Value</strong>
                      </Table.Header>
                      <Table.Header
                        key={`new-value-header-offer-${uniqueKey}`}
                        xs={2}
                      >
                        <strong>New Value</strong>
                      </Table.Header>
                      {offer_id !== -1 ? (
                        <Table.Header
                          key={`mktg-approval-header-offer-${uniqueKey}`}
                          xs={2}
                        >
                          <strong>MKTG APPROVAL</strong>
                        </Table.Header>
                      ) : (
                        ''
                      )}
                      {offer_id !== -1 ? (
                        <Table.Header
                          key={`dwa-checkbox-header-offer-${uniqueKey}`}
                          xs={2}
                        >
                          <strong>DWA</strong>
                        </Table.Header>
                      ) : (
                        ''
                      )}
                      <Table.Header
                        key={`notes-header-offer-${uniqueKey}`}
                        xs={2}
                      >
                        <strong>Creative Notes</strong>
                      </Table.Header>
                    </Table.Row>
                  ) : null}
                </Table.Head>

                {groupedByTcin[tcin]?.map((row, rowIndex) => (
                  <Table.Body className="POP-table">
                    {row?.fields_changed?.map((fieldChanged, changeIndex) => (
                      <Table.Row
                        key={`${rowIndex}-${fieldChanged?.date_changed}-${uniqueKey}`}
                        align="top"
                        clickable={false}
                        className="table-row-field-changed"
                      >
                        <Table.Data
                          xs={2}
                          key={`${rowIndex}-offer-field-${row?.offer_id}+ ${changeIndex}`}
                        >
                          {fieldChanged?.field}
                        </Table.Data>
                        <Table.Data
                          xs={2}
                          key={`${rowIndex}-ofv-${row?.offer_id}+ ${changeIndex}`}
                        >
                          {getChangeSummaryFieldValue(
                            fieldChanged?.field,
                            fieldChanged?.old_value,
                            divisions
                          )}
                          {fieldChanged?.old_value &&
                            fieldChanged.offer_field_change_details &&
                            formatDcpi(
                              fieldChanged.offer_field_change_details,
                              1
                            ).map((change, index) => (
                              <div key={`old-value-${index}`}>
                                <h4
                                  className={
                                    change.deleted
                                      ? 'deleted-text-old-value-heading'
                                      : ''
                                  }
                                >
                                  {change.dpci}
                                </h4>
                                <p
                                  className={
                                    change.deleted
                                      ? 'deleted-text-old-value-text'
                                      : 'old_new_margin'
                                  }
                                >
                                  {change.value}
                                </p>
                              </div>
                            ))}
                        </Table.Data>
                        <Table.Data
                          xs={2}
                          key={`${rowIndex}-nfv-${row?.offer_id}+ ${changeIndex}`}
                        >
                          {getChangeSummaryFieldValue(
                            fieldChanged?.field,
                            fieldChanged?.new_value,
                            divisions
                          )}
                          {fieldChanged?.new_value &&
                            fieldChanged.offer_field_change_details &&
                            formatDcpi(
                              fieldChanged.offer_field_change_details,
                              0
                            ).map((change, index) => (
                              <div key={`new-value-${index}`}>
                                <h4
                                  className={
                                    change.added
                                      ? 'added-text-new-value-heading'
                                      : ''
                                  }
                                >
                                  {change.dpci}
                                </h4>
                                <p
                                  className={
                                    change.added
                                      ? 'added-text-new-value-text'
                                      : 'old_new_margin'
                                  }
                                >
                                  {change.value}
                                </p>
                              </div>
                            ))}
                        </Table.Data>
                        {offer_id !== -1 && (
                          <Table.Data
                            xs={2}
                            key={`${rowIndex}-mktg-approval-${row?.offer_id}+ ${changeIndex}`}
                          >
                            {fieldChanged?.review?.status ===
                            'Auto Approved' ? (
                              <Input.Select
                                id="marketingApprovalSelect"
                                options={[
                                  {
                                    id: '1',
                                    label: 'Auto Approved',
                                    value: 'auto',
                                  },
                                ]}
                                value="auto"
                                data-testid="marketingApproval"
                                disabled={true}
                              />
                            ) : (
                              <Tooltip
                                content={`${formatUpdateTime(
                                  fieldChanged?.review?.updated_at
                                )},
                                ${formatEmailToUsername(
                                  fieldChanged?.review?.updated_by
                                )}`}
                                location="bottom"
                              >
                                <Input.Select
                                  id="marketingApprovalSelect"
                                  options={approvalOptions}
                                  className={
                                    fieldChanged?.review?.status ===
                                    'Not Approved'
                                      ? 'C_NotApproved'
                                      : fieldChanged?.review?.status ===
                                          'Approved'
                                        ? 'C_Approved'
                                        : ''
                                  }
                                  onChange={(e) => {
                                    handleStatusChange({
                                      changeRecord: row,
                                      fieldName: fieldChanged?.field,
                                      fieldValueIndex: changeIndex,
                                      value: e.target.value,
                                    })
                                  }}
                                  value={
                                    fieldChanged?.review?.status
                                      ? approvalOptions.filter(
                                          (element) =>
                                            fieldChanged?.review?.status ===
                                            element.label
                                        )[0]?.value
                                      : 'pending'
                                  }
                                  data-testid="marketingApproval"
                                  disabled={!canApprove}
                                />
                              </Tooltip>
                            )}
                          </Table.Data>
                        )}
                        <Table.Data
                          xs={2}
                          key={`${rowIndex}-mktg-channel-${row?.offer_id}+ ${changeIndex}`}
                        >
                          {fieldChanged?.channel_activities?.map((channel) => (
                            <>
                              {channel &&
                                channel?.activities?.map((activity, index) => (
                                  <div key={index} className="checkbox-parent">
                                    <Input.Checkbox
                                      checked={activity.status === true}
                                      disabled={
                                        (fieldChanged?.review?.status !==
                                          'Approved' &&
                                          fieldChanged?.review?.status !==
                                            'Auto Approved') ||
                                        (activity.name === 'Art'
                                          ? !canCheckArt
                                          : !canCheckCopy)
                                      }
                                      onChange={(e) => {
                                        console.log(e)
                                        handleChannelActivityChange({
                                          activityName: activity.name,
                                          changeRecord: row,
                                          channelName: channel.channel_name,
                                          fieldName: fieldChanged?.field,
                                          fieldValueIndex: changeIndex,
                                          value: e.target.checked,
                                        })
                                      }}
                                    />
                                    <Input.Text
                                      value={activity.name}
                                      readOnly
                                    />
                                  </div>
                                ))}
                            </>
                          ))}
                        </Table.Data>
                        <Table.Data
                          xs={2}
                          key={`${rowIndex}-notes-${row?.offer_id}+ ${changeIndex}`}
                        >
                          <div className="main-container">
                            <ul style={{ padding: '0' }}>
                              {sortNotesByCreatedTime(fieldChanged?.notes).map(
                                (note, index) => (
                                  <li
                                    key={`offer-notes-${row?.offer_id}-${rowIndex}-${changeIndex}-${index}`}
                                  >
                                    {index === editIndex &&
                                    rowIndex === editRowIndex ? (
                                      <div>
                                        <input
                                          className="edit-textbox"
                                          type="text"
                                          value={editNotes}
                                          onChange={(e) =>
                                            setEditNotes(e.target.value)
                                          }
                                        />
                                        <Button
                                          className="C-EditButton"
                                          onClick={() =>
                                            handleSaveEdit({
                                              changeRecord: row,
                                              fieldName: fieldChanged?.field,
                                              fieldValueIndex: index,
                                            })
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faCircleCheck}
                                          />
                                        </Button>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="notes_info">
                                          <div>
                                            <span
                                              style={{
                                                color: 'black',
                                                fontSize: '14px',
                                              }}
                                            >
                                              {note.value}
                                            </span>{' '}
                                            <br />
                                            {`${
                                              note.written_by.split('@', 1)[0]
                                            } ${formatToDateTime(
                                              note.written_at
                                            )} `}
                                          </div>
                                          {note.written_by ===
                                            currentUserEmail && (
                                            <div className="icons">
                                              <Button
                                                className="C-MenuButton"
                                                style={{
                                                  marginRight: '4px',
                                                }}
                                                onClick={() => {
                                                  setEditNotes(note.value)
                                                  handleEdit(index, rowIndex)
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  color="black"
                                                  icon={faPencilAlt}
                                                />
                                              </Button>
                                              <Button
                                                className="C-MenuButton"
                                                onClick={() =>
                                                  handleDelete({
                                                    changeRecord: row,
                                                    fieldName:
                                                      fieldChanged?.field,
                                                    fieldValueIndex: index,
                                                  })
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  color="black"
                                                  icon={faTrashAlt}
                                                />
                                              </Button>
                                            </div>
                                          )}
                                          <br />
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                          <InlineEditInput
                            fieldName={fieldChanged?.field}
                            notes={sortNotesByCreatedTime(fieldChanged?.notes)}
                            setNotes={setNotes}
                            history={history}
                            setHistory={setHistory}
                            data={row}
                            id="text-box"
                            field="creative_notes"
                            placeholder="Add a note"
                            type={undefined}
                            isDeactivated={!canEdit}
                          />
                        </Table.Data>
                      </Table.Row>
                    ))}
                    {row?.items_changed?.length > 0 ? (
                      <Table.Head
                        key={`table-head-${row?.items_changed[0].dpci}-${uniqueKey}`}
                      >
                        <Table.Row
                          key={`item-${row?.items_changed[0].dpci}-${uniqueKey}`}
                          align="center"
                          clickable={false}
                        >
                          <h5 className="timeline-item--title">
                            DPCI:{' '}
                            <span className="timeline-item--value">
                              {row?.items_changed[0].dpci};
                            </span>
                            TCIN:{' '}
                            <span className="timeline-item--value">
                              {row?.items_changed[0].tcin};
                            </span>
                            DESCRIPTION:{' '}
                            <span
                              className="timeline-item--value"
                              dangerouslySetInnerHTML={{
                                __html: row?.items_changed[0].description,
                              }}
                            />
                          </h5>
                        </Table.Row>
                        <Table.Row
                          key={`Field-${row?.items_changed[0].dpci}-${uniqueKey}`}
                        >
                          <Table.Header
                            key={`field-header-item-${uniqueKey}`}
                            xs={2}
                          >
                            <strong>Field</strong>
                          </Table.Header>
                          <Table.Header
                            key={`old-value-header-item-${uniqueKey}`}
                            xs={2}
                          >
                            <strong>Old Value</strong>
                          </Table.Header>
                          <Table.Header
                            key={`new-value-header-item-${uniqueKey}`}
                            xs={2}
                          >
                            <strong>New Value</strong>
                          </Table.Header>
                          {offer_id !== -1 ? (
                            <Table.Header
                              key={`mktg-approval-header-item-${uniqueKey}`}
                              xs={2}
                            >
                              <strong>MKTG APPROVAL</strong>
                            </Table.Header>
                          ) : (
                            ''
                          )}
                          {offer_id !== -1 ? (
                            <Table.Header
                              key={`dwa-checkbox-header-item-${uniqueKey}`}
                              xs={2}
                            >
                              <strong>DWA</strong>
                            </Table.Header>
                          ) : (
                            ''
                          )}
                          <Table.Header
                            key={`notes-header-item-${uniqueKey}`}
                            xs={2}
                          >
                            <strong>Creative Notes</strong>
                          </Table.Header>
                        </Table.Row>
                        {row?.items_changed[0]?.fields_changed?.map(
                          (item, index) => (
                            <Table.Row
                              className="timeline-item--value"
                              key={`${index}-${item.dpci}-${item?.field}-${uniqueKey}`}
                            >
                              <Table.Data xs={2} key={index + 'item-field'}>
                                {item?.field}
                              </Table.Data>
                              <Table.Data xs={2} key={index + 'oiv'}>
                                {item?.old_value}
                              </Table.Data>
                              <Table.Data xs={2} key={index + 'niv'}>
                                {item?.new_value}
                              </Table.Data>
                              <Table.Data
                                xs={2}
                                key={`${rowIndex}-mktg-approval-item-${row?.offer_id}+ ${index}`}
                              >
                                {item?.review?.status === 'Auto Approved' ? (
                                  <Input.Select
                                    id="marketingApprovalSelect"
                                    options={[
                                      {
                                        id: '1',
                                        label: 'Auto Approved',
                                        value: 'auto',
                                      },
                                    ]}
                                    value="auto"
                                    data-testid="marketingApproval"
                                    disabled={true}
                                  />
                                ) : (
                                  <Tooltip
                                    content={`${formatUpdateTime(
                                      item?.review?.updated_at
                                    )}
                              ${formatEmailToUsername(
                                item?.review?.updated_by
                              )}`}
                                    location="bottom"
                                  >
                                    <Input.Select
                                      id="marketingApprovalSelect"
                                      options={approvalOptions}
                                      className={
                                        item?.review?.status === 'Not Approved'
                                          ? 'C_NotApproved'
                                          : item?.review?.status === 'Approved'
                                            ? 'C_Approved'
                                            : ''
                                      }
                                      onChange={(e) => {
                                        handleStatusChange({
                                          changeRecord: row,
                                          fieldName: item?.field,
                                          fieldValueIndex: index,
                                          value: e.target.value,
                                        })
                                      }}
                                      value={
                                        item?.review?.status
                                          ? approvalOptions.filter(
                                              (element) =>
                                                item?.review?.status ===
                                                element.label
                                            )[0]?.value
                                          : 'pending'
                                      }
                                      data-testid="marketingApproval"
                                      disabled={!canApprove}
                                    />
                                  </Tooltip>
                                )}
                              </Table.Data>
                              <Table.Data
                                xs={2}
                                key={`${rowIndex}-mktg-channel-item-${row?.offer_id}+ ${index}`}
                              >
                                {item?.channel_activities?.map((channel) => (
                                  <>
                                    {channel &&
                                      channel?.activities?.map(
                                        (activity, index) => (
                                          <div
                                            key={index}
                                            className="checkbox-parent"
                                          >
                                            <Input.Checkbox
                                              checked={activity.status === true}
                                              disabled={
                                                (item?.review?.status !==
                                                  'Approved' &&
                                                  item?.review?.status !==
                                                    'Auto Approved') ||
                                                (activity.name === 'Art'
                                                  ? !canCheckArt
                                                  : !canCheckCopy)
                                              }
                                              onChange={(e) => {
                                                handleChannelActivityChange({
                                                  activityName: activity.name,
                                                  changeRecord: row,
                                                  channelName:
                                                    channel.channel_name,
                                                  fieldName: item?.field,
                                                  fieldValueIndex: index,
                                                  value: e.target.checked,
                                                })
                                              }}
                                            />
                                            <Input.Text
                                              value={activity.name}
                                              readOnly
                                            />
                                          </div>
                                        )
                                      )}
                                  </>
                                ))}
                              </Table.Data>
                              <Table.Data xs={2} key={index + 'notes'}>
                                <ul>
                                  {sortNotesByCreatedTime(item?.notes).map(
                                    (note, index) => (
                                      <li
                                        key={`item-notes-${item.id}-${index}-${uniqueKey}`}
                                      >
                                        {note.value}
                                        <div className="notes_info">
                                          {`${
                                            note.written_by.split('@', 1)[0]
                                          } ${formatToDateTime(
                                            note.written_at
                                          )} `}
                                        </div>
                                        <br />
                                      </li>
                                    )
                                  )}
                                </ul>
                                <InlineEditInput
                                  fieldName={item?.field}
                                  notes={sortNotesByCreatedTime(item?.notes)}
                                  setHistory={setHistory}
                                  history={history}
                                  setNotes={setNotes}
                                  data={row}
                                  id="text-box"
                                  field="creative_notes"
                                  placeholder="Add a note"
                                  type={undefined}
                                  isDeactivated={!canEdit}
                                />
                              </Table.Data>
                            </Table.Row>
                          )
                        )}
                      </Table.Head>
                    ) : null}

                    <div
                      key={`${rowIndex}-item-${row?.offer_id}-${uniqueKey}`}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100% !important',
                      }}
                    >
                      {row?.notIncluded ? (
                        <Table.Head key={`table-head--${uniqueKey}`}>
                          {row?.rowNum === 0 && (
                            <Table.Row key={`Field-${uniqueKey}`}>
                              <Table.Header
                                key={`field-header-item-${uniqueKey}`}
                                xs={2}
                              >
                                <strong>Field</strong>
                              </Table.Header>
                              <Table.Header
                                key={`old-value-header-item-${uniqueKey}`}
                                xs={2}
                              >
                                <strong>Old Value</strong>
                              </Table.Header>
                              <Table.Header
                                key={`new-value-header-item-${uniqueKey}`}
                                xs={2}
                              >
                                <strong>New Value</strong>
                              </Table.Header>
                              {offer_id !== -1 ? (
                                <Table.Header
                                  key={`mktg-approval-header-item-${uniqueKey}`}
                                  xs={2}
                                >
                                  <strong>MKTG APPROVAL</strong>
                                </Table.Header>
                              ) : (
                                ''
                              )}
                              {offer_id !== -1 ? (
                                <Table.Header
                                  key={`dwa-checkbox-header-item-${uniqueKey}`}
                                  xs={2}
                                >
                                  <strong>DWA</strong>
                                </Table.Header>
                              ) : (
                                ''
                              )}
                              <Table.Header
                                key={`notes-header-item-${uniqueKey}`}
                                xs={2}
                              >
                                <strong>Creative Notes</strong>
                              </Table.Header>
                            </Table.Row>
                          )}

                          <Table.Row
                            key={`${index}-${row.dpci}-${row?.field}-${uniqueKey}`}
                          >
                            <Table.Data
                              xs={2}
                              key={index + 'row-field'}
                              className="table-row-field-changed-Not"
                            >
                              {row?.field}
                            </Table.Data>
                            <Table.Data
                              xs={2}
                              key={index + 'oiv'}
                              className="table-row-field-changed-Not"
                            >
                              {dateFields.includes(row?.field) ? (
                                <DateFormatter
                                  date={row?.old_value}
                                  format="YYYY-MM-DD"
                                  timezone="America/Los_Angeles"
                                />
                              ) : (
                                row?.old_value
                              )}
                            </Table.Data>
                            <Table.Data
                              xs={2}
                              key={index + 'niv'}
                              className="table-row-field-changed-Not"
                            >
                              {dateFields.includes(row?.field) ? (
                                <DateFormatter
                                  date={row?.new_value}
                                  format="YYYY-MM-DD"
                                  timezone="America/Los_Angeles"
                                />
                              ) : (
                                row?.new_value
                              )}
                            </Table.Data>
                            <Table.Data
                              xs={2}
                              key={`${rowIndex}-mktg-approval-row-${row?.offer_id}+ ${index}`}
                              className="table-row-field-changed-Not"
                            >
                              {row.review?.status === 'Auto Approved' ? (
                                <Input.Select
                                  id="marketingApprovalSelect"
                                  options={[
                                    {
                                      id: '1',
                                      label: 'Auto Approved',
                                      value: 'auto',
                                    },
                                  ]}
                                  value="auto"
                                  data-testid="marketingApproval"
                                  disabled={true}
                                />
                              ) : (
                                <Tooltip
                                  content={`${formatUpdateTime(
                                    row.review?.updated_at
                                  )}
                                  ${formatEmailToUsername(
                                    row.review?.updated_by
                                  )}`}
                                  location="bottom"
                                >
                                  <Input.Select
                                    id="marketingApprovalSelect"
                                    options={approvalOptions}
                                    className={
                                      row.review?.status === 'Not Approved'
                                        ? 'C_NotApproved'
                                        : row.review?.status === 'Approved'
                                          ? 'C_Approved'
                                          : ''
                                    }
                                    onChange={(e) => {
                                      handleStatusChange({
                                        changeRecord: row,
                                        fieldName: row.field,
                                        fieldValueIndex: index,
                                        value: e.target.value,
                                      })
                                    }}
                                    value={
                                      row.review?.status
                                        ? approvalOptions.filter(
                                            (element) =>
                                              row.review?.status ===
                                              element.label
                                          )[0]?.value
                                        : 'pending'
                                    }
                                    data-testid="marketingApproval"
                                    disabled={!canApprove}
                                  />
                                </Tooltip>
                              )}
                            </Table.Data>
                            <Table.Data
                              xs={2}
                              key={`${rowIndex}-mktg-channel-row-${row?.offer_id}+ ${index}`}
                              className="table-row-field-changed-Not"
                            >
                              {row.channel_activities?.map((channel) => (
                                <>
                                  {channel &&
                                    channel?.activities?.map(
                                      (activity, index) => (
                                        <div
                                          key={index}
                                          className="checkbox-parent"
                                        >
                                          <Input.Checkbox
                                            checked={activity.status === true}
                                            disabled={
                                              (row.review?.status !==
                                                'Approved' &&
                                                row.review?.status !==
                                                  'Auto Approved') ||
                                              (activity.name === 'Art'
                                                ? !canCheckArt
                                                : !canCheckCopy)
                                            }
                                            onChange={(e) => {
                                              handleChannelActivityChange({
                                                activityName: activity.name,
                                                changeRecord: row,
                                                channelName:
                                                  channel.channel_name,
                                                fieldName: row.field,
                                                fieldValueIndex: index,
                                                value: e.target.checked,
                                              })
                                            }}
                                          />
                                          <Input.Text
                                            value={activity.name}
                                            readOnly
                                          />
                                        </div>
                                      )
                                    )}
                                </>
                              ))}
                            </Table.Data>
                            <Table.Data
                              xs={2}
                              key={index + 'notes'}
                              className="table-row-field-changed-Not"
                            >
                              <ul>
                                {sortNotesByCreatedTime(row.notes).map(
                                  (note, index) => (
                                    <li
                                      key={`item-notes-${row.id}-${index}-${uniqueKey}`}
                                    >
                                      {note.value}
                                      <div className="notes_info">
                                        {`${
                                          note.written_by.split('@', 1)[0]
                                        } ${formatToDateTime(
                                          note.written_at
                                        )} `}
                                      </div>
                                      <br />
                                    </li>
                                  )
                                )}
                              </ul>
                              <InlineEditInput
                                fieldName={row.field}
                                notes={sortNotesByCreatedTime(row?.notes)}
                                setHistory={setHistory}
                                history={history}
                                setNotes={setNotes}
                                data={row}
                                id="text-box"
                                field="creative_notes"
                                placeholder="Add a note"
                                type={undefined}
                                isDeactivated={!canEdit}
                              />
                            </Table.Data>
                          </Table.Row>
                        </Table.Head>
                      ) : null}
                    </div>
                  </Table.Body>
                ))}
              </Table>
            </Grid.Container>
          ))}
        </>
      )}
    </div>
  )
}

export default BundledChangeSummaryView
