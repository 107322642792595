import {
  Grid,
  Card,
  Button,
  ProgressBar,
  Form,
  Heading,
  Timeline,
  Input,
  Chip,
  ExpandableSection,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useEffect, useState, Fragment, useMemo, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ChangeMgmtApi from '../../api/creative-hub/ChangeMgmtApi'
import ChannelApi from '../../api/creative-hub/ChannelApi'
import MerchandiseApi from '../../api/creative-hub/MerchandiseApi'
import ProjectApi from '../../api/creative-hub/ProjectApi'
import BundledChangeSummaryView from '../../components/BundledChangeSummaryView'
import {
  BreadcrumbContext,
  CanEditContext,
  EffectiveRoleContext,
} from '../../context/Context'
import {
  BUNDLE_PAGE_SIZE,
  createProjectsIdsArray,
  fieldOptions,
  formatProjectOptions,
  isChangeSummaryFirstPage,
  isBundleLastPage,
  getSelectedChannels,
  getSelectedDivisions,
  getSelectedPyramids,
  makeChangeSummaryPayload,
  pyramidOptions,
  mktgApprovalStatusOptions,
  creativeArtOptions,
  creativeCopyOptions,
} from '../../creativeHubGlobal/util/ChangeSummaryUtil'
import { getDefaultSettings } from '../../creativeHubGlobal/util/DefaultFiltersUtil'
import { divisionSelection } from '../../creativeHubGlobal/util/MerchandiseUtil'
import { getChipValues } from '../../planning/util/ProjectDetailsUtil'
import { canEditChangeSummary } from '../../util/CheckRole'
import {
  handleFireflyInit,
  handleFireflyFailure,
  handleFireflySuccess,
} from '../../util/FireflyHelper'
import {
  dateFormatForHistoryViewFilter,
  formatToUi,
} from '../../util/MomentUtil'
import '../../scss/changeSummary.scss'

const reviewedOptions = [
  { id: 1, label: 'Reviewed', value: 'true' },
  { id: 2, label: 'Not Reviewed', value: 'false' },
]

const BundledChangeSummary = function ({ session = {} }) {
  const [canEdit, setCanEdit] = useState(false)
  const { effectiveRole, userDefinedFilters } = useContext(EffectiveRoleContext)

  const { search } = useLocation()

  const query = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [isLoading, setLoading] = useState(false)

  const [divisions, setDivisions] = useState([])
  const displayDivisions = divisionSelection(divisions)
  const changeMgmtApi = useMemo(() => new ChangeMgmtApi(), [])
  const channelApi = new ChannelApi()
  const merchandiseApi = useMemo(() => new MerchandiseApi(), [])
  const projectApi = useMemo(() => new ProjectApi(), [])
  // eslint-disable-next-line
  const [channels, setChannels] = useState([])
  const [channelOptions, setChannelOptions] = useState([])
  const [currentProjectIds, setCurrentProjectIds] = useState([])
  const [offerAuditData, setOfferAuditData] = useState()
  const [onFirstPage, setOnFirstPage] = useState(true)
  const [onLastPage, setOnLastPage] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [projectSelectValues, setProjectSelectValues] = useState([])
  const [descendingDateClicked, setDescendingDateClicked] = useState(false)
  const [isSortButtonClicked, setIsSortButtonClicked] = useState(false)
  const [fetchFirstPage, setFetchFirstPage] = useState(false)
  // eslint-disable-next-line
  const [pastProject, setPastProject] = useState(false)
  // eslint-disable-next-line
  const [selectedChannels, setSelectedChannels] = useState([])
  // eslint-disable-next-line
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const [originalListOfCurrentProjectIds, setOriginalListOfCurrentProjectIds] =
    useState([])
  const [changeStatus, setChangeStatus] = useState({})
  const pageSize = BUNDLE_PAGE_SIZE
  const viewName = 'Bundled Change Summary'
  const setToaster = ToastProvider.useToaster()

  const statusKeyMap = {
    completed: 'Completed',
    not_approved: 'Not Approved',
    pending: 'Pending Approval',
    pending_post_ad_close: 'Pending Post Ad Close',
    pending_pre_ad_close: 'Pending Pre Ad Close',
    // eslint-disable-next-line sort-keys, sort-keys-fix/sort-keys-fix
    past_due: 'Past Due',
    total: 'Total',
  }

  const { defaultChannels, defaultDivisions, defaultPyramids } =
    getDefaultSettings(userDefinedFilters, 'CHANGE_SUMMARY_VIEW')

  useEffect(() => {
    setCanEdit(effectiveRole.some((role) => canEditChangeSummary(role)))
  }, [effectiveRole, setCanEdit])
  useEffect(() => {
    if (userDefinedFilters) {
      defaultChannels.length &&
        query.set(
          'marketing_channels',
          defaultChannels?.map((option) => option).join(',')
        )
      defaultDivisions.length &&
        query.set(
          'divisions',
          defaultDivisions?.map((option) => option).join(',')
        )
      defaultPyramids.length && query.set('pyramid_name', defaultPyramids)
      navigate(`/changeSummary?${query.toString()}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefinedFilters])

  //Loading change summary data and getting channels
  useEffect(() => {
    handleFireflyInit('pageload', viewName, 'success', session)
    projectApi
      .findProjects({ past_project: pastProject, project_description: '' })
      .then((data) => {
        const curProjectIds = createProjectsIdsArray(data.projects)
        setProjectSelectValues(formatProjectOptions(data.projects))
        setOriginalListOfCurrentProjectIds(curProjectIds)
        setCurrentProjectIds(curProjectIds)

        fetchChangeSummary({ curProjectIds, query })
        fetchChangeSummaryStatus({ curProjectIds, query })
        handleFireflySuccess('pageload', viewName, 'success', session)
      })
      .catch((e) => {
        handleFireflyFailure('pageload', viewName, 'error', session)
      })
    channelApi.getChannels().then((response) => {
      let chnlOpts = []
      if (response && channelOptions.length === 0) {
        response.forEach((channel = {}) => {
          chnlOpts.push({
            id: channel.channel_id,
            label: channel.channel_name,
            value: channel.channel_id,
          })
        })
        setChannelOptions(chnlOpts)
      }
      setChannels(response)
    })
    merchandiseApi.getDivisions().then((response) => setDivisions(response))
    // eslint-disable-next-line
  }, [pastProject])

  const fetchChangeSummaryStatus = ({ curProjectIds, query }) => {
    const payload = makeChangeSummaryPayload({
      curProjectIds,
      query,
    })
    changeMgmtApi
      .getChangeStatus(payload, setToaster)
      .then((res) => setChangeStatus(res))
  }
  const fetchChangeSummary = ({
    channels,
    changePage,
    curProjectIds,
    query,
    sortBy = false,
  }) => {
    setOfferAuditData([])
    setLoading(true)
    const payload = makeChangeSummaryPayload({
      BUNDLE_PAGE_SIZE,
      changePage,
      channels,
      curProjectIds,
      pageNumber,
      pageSize,
      query,
      sortBy,
    })
    changeMgmtApi.getBundledChangeSummary(payload).then((res = {}) => {
      if (res.data && res.data.length <= 0 && pageNumber > 1) {
        setPageNumber(1)
        setFetchFirstPage(true)
        return
      } else {
        setOfferAuditData(res.data)
        setOnFirstPage(isChangeSummaryFirstPage(res.page_num))
        setPageNumber(res.page_num)
        setOnLastPage(
          isBundleLastPage({
            pageNum: res.page_num,
            totalRecords: res.total,
          })
        )
        setFetchFirstPage(false)
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    if (pageNumber === 1 && fetchFirstPage) {
      handleRequest(query)
      setFetchFirstPage(false)
    }
    // eslint-disable-next-line
  }, [pageNumber])

  const handleSortByStartDate = async () => {
    setDescendingDateClicked(!descendingDateClicked)
    try {
      fetchChangeSummary({
        channels,
        curProjectIds: currentProjectIds,
        query,
        sortBy: !descendingDateClicked,
      })
      setIsSortButtonClicked(!isSortButtonClicked)
    } catch (error) {
      console.error('Error fetching sorted change summary:', error)
      throw error
    }
  }

  const handleRequest = (query, changePage) => {
    fetchChangeSummary({
      changePage,
      channels,
      curProjectIds: currentProjectIds,
      query,
      sortBy: isSortButtonClicked
        ? { field: 'start_date', order: 'asc' }
        : null,
    })
    fetchChangeSummaryStatus({ curProjectIds: currentProjectIds, query })
    navigate(`/changeSummary?${query.toString()}`)
  }

  const handleClearFilters = () => {
    fetchChangeSummary({ curProjectIds: originalListOfCurrentProjectIds })
    fetchChangeSummaryStatus({
      curProjectIds: originalListOfCurrentProjectIds,
    })
    setCurrentProjectIds(originalListOfCurrentProjectIds)
    setPageNumber(1)
    navigate(`/changeSummary`)
  }

  const [showFilters, setshowFilters] = useState(true)
  const handleToggleChange = () => {
    setshowFilters((current) => !current)
  }

  const handleSubmit = (values = {}) => {
    !isEmpty(values.associated_buyer)
      ? query.set('associated_buyer', values.associated_buyer)
      : query.delete('associated_buyer')

    !isEmpty(values.field_name)
      ? query.set('field_name', values.field_name)
      : query.delete('field_name')

    !isEmpty(values.pyramid_name)
      ? query.set(
          'pyramid_name',
          values.pyramid_name.map((option) => option.value).join(',')
        )
      : query.delete('pyramid_name')

    !isEmpty(values.marketing_channels)
      ? query.set(
          'marketing_channels',
          values.marketing_channels.map((option) => option.id).join(',')
        )
      : query.delete('marketing_channels')

    !isEmpty(values.offer_id)
      ? query.set('offer_id', values.offer_id)
      : query.delete('offer_id')

    !isEmpty(values.change_date)
      ? query.set(
          'change_date',
          dateFormatForHistoryViewFilter(values?.change_date)
        )
      : query.delete('change_date')

    !isEmpty(values.divisions)
      ? query.set(
          'divisions',
          values.divisions.map((option) => option.id).join(',')
        )
      : query.delete('divisions')

    !isEmpty(values.offer_name)
      ? query.set('offer_name', values.offer_name)
      : query.delete('offer_name')

    !isEmpty(values.project_id)
      ? query.set('project_id', values.project_id.value)
      : query.delete('project_id')

    !isEmpty(values.changed_by)
      ? query.set('changed_by', values.changed_by)
      : query.delete('changed_by')

    !isEmpty(values.reviewed)
      ? query.set('reviewed', values.reviewed.value)
      : query.delete('reviewed')

    !isEmpty(values.change_review_status)
      ? query.set('change_review_status', values.change_review_status)
      : query.delete('change_review_status')

    !isEmpty(values.creative_copy)
      ? query.set('creative_copy', values.creative_copy)
      : query.delete('creative_copy')

    !isEmpty(values.creative_art)
      ? query.set('creative_art', values.creative_art)
      : query.delete('creative_art')

    navigate(`/changeSummary?${query.toString()}`)

    handleRequest(query)
  }

  const reviewedIntialVal = reviewedOptions.find(
    (app) => app.value === query.get('reviewed')
  )

  const handlePastProject = () => {
    setPastProject(!pastProject)
  }

  return offerAuditData ? (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          associated_buyer: query.get('associated_buyer')
            ? query.get('associated_buyer')
            : '',

          change_date: query.get('change_date')
            ? formatToUi(query.get('change_date'))
            : '',

          change_review_status: query.get('change_review_status')
            ? query.get('change_review_status')
            : '',
          changed_by: query.get('changed_by') ? query.get('changed_by') : '',

          creative_art: query.get('creative_art')
            ? query.get('creative_art')
            : '',

          creative_copy: query.get('creative_copy')
            ? query.get('creative_copy')
            : '',

          divisions: query.get('divisions')
            ? getSelectedDivisions(
                query.get('divisions').split(','),
                displayDivisions
              )
            : [],

          field_name: query.get('field_name') ? query.get('field_name') : '',

          marketing_channels: query.get('marketing_channels')
            ? getSelectedChannels(
                query.get('marketing_channels').split(','),
                channelOptions
              )
            : [],
          offer_id: query.get('offer_id') ? query.get('offer_id') : '',
          offer_name: query.get('offer_name') ? query.get('offer_name') : '',
          project_id: query.get('project_id')
            ? projectSelectValues.find(
                (opt) => opt.value === query.get('project_id')
              )
            : '',

          pyramid_name: query.get('pyramid_name')
            ? getSelectedPyramids(
                query.get('pyramid_name').split(','),
                pyramidOptions
              )
            : [],

          query: query.toString(),

          reviewed: reviewedIntialVal ? reviewedIntialVal : '',
        }}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
          errors,
          touched,
        }) => (
          <>
            <div className="hc-pa-normal hc-pa-none">
              <Grid.Container
                padding="dense"
                spacing="none"
                justify="flex-end"
                align="flex-end"
              >
                <Grid.Item>
                  <Button
                    disabled={onFirstPage}
                    onClick={() => {
                      handleRequest(query, -1)
                    }}
                  >
                    {`Prev ${BUNDLE_PAGE_SIZE} Changes`}
                  </Button>
                  <Button
                    disabled={onLastPage}
                    onClick={() => {
                      handleRequest(query, 1)
                    }}
                  >
                    {`Next ${BUNDLE_PAGE_SIZE} Changes`}
                  </Button>
                </Grid.Item>
                <Grid.Item xs={12}>
                  <Card>
                    <div className="hc-pa-normal">
                      <Grid.Container
                        padding="dense"
                        spacing="none"
                        justify="space-between"
                        align="flex-end"
                      >
                        <Grid.Item xs={7} className="hc-ta-right">
                          <Heading>Change Summary</Heading>
                        </Grid.Item>
                        <Grid.Item xs={6} style={{ marginLeft: '15px' }}>
                          <Input.Toggle
                            id="view-ivy-umbrellas-toggle"
                            label="View Filters"
                            checked={showFilters}
                            onChange={handleToggleChange}
                          />
                        </Grid.Item>
                        <Grid.Item
                          xs={4}
                          className="autocomplete-alignment hc-ta-right"
                          style={{ marginRight: '40px' }}
                        >
                          <Button
                            id="past-project"
                            className="secondary"
                            onClick={handlePastProject}
                          >
                            {!pastProject ? (
                              <span> View Past Projects </span>
                            ) : (
                              <span> View Upcoming Projects </span>
                            )}
                          </Button>
                        </Grid.Item>
                      </Grid.Container>
                    </div>
                  </Card>
                </Grid.Item>
                <Grid.Container style={{ marginLeft: '0px' }}>
                  <Grid.Item xs={2}>
                    <Grid.Container direction="column">
                      <Grid.Item
                        style={{
                          backgroundColor: '#f2f2f2',
                          display: showFilters ? 'block' : 'none',
                          marginTop: '25px',
                          padding: '0px 5px',
                        }}
                      >
                        <ExpandableSection expanded padding="dense">
                          <h4>Status Summary</h4>
                          <ExpandableSection.Content>
                            {Object.entries(changeStatus || {}).length > 0 ? (
                              Object.entries(statusKeyMap)?.map(
                                ([key, value], idx) => (
                                  <Grid.Container spacing="dense">
                                    <Grid.Item
                                      xs={7}
                                      style={{
                                        backgroundColor:
                                          idx % 2 === 0 ? '#f5f5f5' : '#e6e6e6',
                                        color:
                                          key === 'past_due'
                                            ? '#ff0000'
                                            : 'black',
                                        fontSize: 13,
                                        fontWeight: key === 'total' ? 600 : 400,
                                      }}
                                    >
                                      {statusKeyMap[key]}
                                    </Grid.Item>
                                    <Grid.Item
                                      xs={5}
                                      style={{
                                        backgroundColor:
                                          idx % 2 === 0 ? '#f5f5f5' : '#e6e6e6',
                                        color:
                                          key === 'past_due'
                                            ? '#ff0000'
                                            : 'black',
                                        fontSize: 13,
                                        fontWeight: key === 'total' ? 600 : 400,
                                      }}
                                    >
                                      {changeStatus[key]}
                                    </Grid.Item>
                                  </Grid.Container>
                                )
                              )
                            ) : (
                              <span>No data found</span>
                            )}
                          </ExpandableSection.Content>
                        </ExpandableSection>
                      </Grid.Item>
                      <Grid.Item
                        xs
                        style={{
                          backgroundColor: 'white',
                          display: showFilters ? 'block' : 'none',
                          marginTop: '5px',
                          padding: '0px 12px',
                        }}
                      >
                        <div className="hc-pa-normal">
                          <Grid.Container
                            align="center"
                            justify="center"
                            padding="dense"
                            spacing="dense"
                          >
                            <Grid.Item
                              xs={12}
                              className="autocomplete-alignment"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Grid.Item xs={6}>
                                <Button
                                  type="primary"
                                  onClick={handleSubmit}
                                  style={{ width: '100%' }}
                                >
                                  Apply Filters
                                </Button>
                              </Grid.Item>
                              <Grid.Item xs={6}>
                                <Button
                                  style={{ width: '95%' }}
                                  type="secondary"
                                  className="marginLeft"
                                  onClick={() => {
                                    handleReset()
                                    handleClearFilters()
                                  }}
                                >
                                  Clear Filters
                                </Button>
                              </Grid.Item>
                            </Grid.Item>
                            <Grid.Item
                              xs={12}
                              className="autocomplete-alignment"
                            >
                              <Autocomplete
                                id="project_id"
                                data-cy="selectProject"
                                value={values.project_id}
                                placeholder="< Projects >"
                                filter={Autocomplete.filters.contains}
                                onUpdate={(id, value) => {
                                  if (!value) {
                                    setFieldValue('project_id', '')
                                    setCurrentProjectIds(
                                      originalListOfCurrentProjectIds
                                    )
                                  } else {
                                    setFieldValue(id, value)
                                    setCurrentProjectIds([value.value])
                                  }
                                }}
                                options={projectSelectValues}
                              />
                            </Grid.Item>
                            <Grid.Item xs={12}>
                              <Form.Field
                                id="offer_id"
                                placeholder="Offer ID"
                                value={values.offer_id}
                                onChange={handleChange}
                              />
                            </Grid.Item>
                            <Grid.Item xs={12}>
                              <Form.Field
                                type="select"
                                id="change_review_status"
                                onUpdate={(id, value) => {
                                  setFieldValue(id, value)
                                }}
                                options={mktgApprovalStatusOptions}
                                value={values.change_review_status || ''}
                                defaultValue=""
                              />
                            </Grid.Item>
                            <Grid.Item
                              className="pyramid-and-division-container"
                              xs={12}
                            >
                              <Autocomplete
                                style={{ marginRight: '3px' }}
                                id="pyramid_name"
                                placeholder="Pyramid"
                                onUpdate={(id, value) => {
                                  setFieldValue(id, value)
                                }}
                                grow
                                renderChip={(cat, onRequestDelete) => (
                                  <Chip
                                    size="dense"
                                    style={{
                                      backgroundColor: cat.color,
                                      border: '0px',
                                      color: 'white',
                                    }}
                                    onRequestDelete={onRequestDelete}
                                  >
                                    {getChipValues(cat.value)}
                                  </Chip>
                                )}
                                options={pyramidOptions}
                                value={values.pyramid_name || []}
                                data-testid="pyramidFilter"
                                multiselect
                              />
                            </Grid.Item>
                            <Grid.Item xs={12}>
                              <Autocomplete
                                id="divisions"
                                placeholder="Divisions"
                                multiselect
                                grow
                                onUpdate={(id, value) => {
                                  setFieldValue(id, value)
                                }}
                                value={values.divisions || []}
                                options={displayDivisions}
                              />
                            </Grid.Item>

                            <Grid.Item xs={12}>
                              <Form.Field
                                id="offer_name"
                                placeholder="Offer Name"
                                value={values.offer_name}
                                onChange={handleChange}
                              />
                            </Grid.Item>
                            <Grid.Item xs={12}>
                              <Form.Field
                                id="associated_buyer"
                                value={values.associated_buyer}
                                placeholder="Associate Buyer Name"
                                onChange={handleChange}
                              />
                            </Grid.Item>

                            <Grid.Item
                              xs={12}
                              className="autocomplete-alignment-channel"
                            >
                              <Autocomplete
                                allowCustomInputValue={true}
                                id="marketing_channels"
                                multiselect
                                chipHeight="dense"
                                value={values.marketing_channels}
                                onUpdate={(id, value) => {
                                  setFieldValue(id, value)
                                  setSelectedChannels(value)
                                }}
                                grow
                                options={channelOptions}
                                placeholder="Choose Channel"
                                data-testid="marketingChannelSelect"
                              />
                            </Grid.Item>
                            <Grid.Item
                              xs={12}
                              className="autocomplete-alignment"
                            >
                              <Form.Field
                                id="changed_by"
                                value={values.changed_by}
                                placeholder="Changed By"
                                onChange={handleChange}
                              />
                            </Grid.Item>
                            <Grid.Item
                              xs={12}
                              className="autocomplete-alignment"
                            >
                              <DatePicker
                                error={errors.date && touched.date}
                                id="change_date"
                                label=""
                                aria-label="select date"
                                placeholder="Date of Change"
                                className="datepicker-icon"
                                value={values.change_date}
                                onUpdate={(id, value) => {
                                  setFieldValue(id, value)
                                }}
                                location="bottom-left"
                              />
                            </Grid.Item>
                            <Grid.Item
                              xs={12}
                              className="autocomplete-alignment"
                            >
                              <Form.Field
                                type="select"
                                id="field_name"
                                onUpdate={(id, value) => {
                                  setFieldValue(id, value)
                                }}
                                options={fieldOptions}
                                value={values.field_name || ''}
                                defaultValue=""
                              />
                            </Grid.Item>
                            <Grid.Item
                              xs={12}
                              className="autocomplete-alignment"
                            >
                              <Form.Field
                                type="select"
                                id="creative_copy"
                                onUpdate={(id, value) => {
                                  setFieldValue(id, value)
                                }}
                                options={creativeCopyOptions}
                                value={values.creative_copy || ''}
                                defaultValue=""
                              />
                            </Grid.Item>
                            <Grid.Item
                              xs={12}
                              className="autocomplete-alignment"
                            >
                              <Form.Field
                                type="select"
                                id="creative_art"
                                onUpdate={(id, value) => {
                                  setFieldValue(id, value)
                                }}
                                options={creativeArtOptions}
                                value={values.creative_art || ''}
                                defaultValue=""
                              />
                            </Grid.Item>
                          </Grid.Container>
                        </div>
                      </Grid.Item>
                    </Grid.Container>
                  </Grid.Item>
                  <Grid.Item
                    className={`${!showFilters ? 'filters-hidden' : 'filters-showing'}`}
                  >
                    <CanEditContext.Provider value={{ canEdit }}>
                      <Grid.Item
                        xs={2}
                        style={{ margin: '13px 0px' }}
                        className={!pastProject && 'sortContainer'}
                      >
                        {!pastProject && (
                          <Button
                            className="dkgrey-btn-sort"
                            style={{
                              width: '100%',
                            }}
                            onClick={handleSortByStartDate}
                          >
                            {descendingDateClicked ? (
                              <FontAwesomeIcon icon={faArrowUp} />
                            ) : null}
                            <span
                              style={{
                                marginLeft: descendingDateClicked ? '10px' : '',
                              }}
                            >
                              Sort by Offer Start Date
                            </span>
                          </Button>
                        )}
                      </Grid.Item>
                      <Grid.Item xs={12}>
                        {isLoading ? (
                          <ProgressBar indeterminate />
                        ) : (
                          <>
                            {offerAuditData.length ? (
                              <Card
                                elevation={2}
                                corners="top"
                                className="hc-pa-dense"
                                xs={12}
                              >
                                <Timeline
                                  style={{
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                  }}
                                >
                                  {offerAuditData.map((history, index) => (
                                    <BundledChangeSummaryView
                                      history={history}
                                      index={index}
                                      uniqueKey={index}
                                      key={index}
                                      channels={channels}
                                      divisions={divisions}
                                      fetchChangeSummary={fetchChangeSummary}
                                      makeChangeSummaryPayload={
                                        makeChangeSummaryPayload
                                      }
                                      curProjectIds={currentProjectIds}
                                      query={query}
                                      pageNumber={pageNumber}
                                    />
                                  ))}
                                </Timeline>
                              </Card>
                            ) : (
                              <div>
                                <Card
                                  elevation={2}
                                  corners="top"
                                  className="hc-pa-dense hc-ta-center"
                                  xs={12}
                                >
                                  No results found.
                                </Card>
                              </div>
                            )}
                          </>
                        )}
                      </Grid.Item>
                    </CanEditContext.Provider>
                  </Grid.Item>
                </Grid.Container>
                <Grid.Item>
                  <Button
                    disabled={onFirstPage}
                    onClick={() => {
                      handleRequest(query, -1)
                    }}
                  >
                    {`Prev ${BUNDLE_PAGE_SIZE} Changes`}
                  </Button>
                  <Button
                    disabled={onLastPage}
                    onClick={() => {
                      handleRequest(query, 1)
                    }}
                  >
                    {`Next ${BUNDLE_PAGE_SIZE} Changes`}
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </div>
          </>
        )}
      </Formik>
    </Fragment>
  ) : null
}

export default BundledChangeSummary
