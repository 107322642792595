import { Grid } from '@enterprise-ui/canvas-ui-react'
import { useState, useContext } from 'react'

import './scss/EditablePageSummary.scss'
import {
  CanEditContext,
  WeeklyAdAutomationContext,
} from '../../context/Context'
import { PYRAMID, STORY, UMBRELLA, OFFER } from '../util/Constants'

import InlineLabeledInputWithButton from './InlineLabeledInputWithButton'

const EditablePageSummary = ({ pageId }) => {
  const {
    mapPageId,
    deletePageSummary,
    expandedEntities,
    setExpandedEntities,
    updatePageSummary,
    loading,
    pages,
    setPages,
  } = useContext(WeeklyAdAutomationContext)
  const { canEdit } = useContext(CanEditContext)
  const [clickedEntityId, setClickedEntityId] = useState({
    offerId: null,
    pyramidId: null,
    storyId: null,
    umbrellaId: null,
  })
  const handleExpandCollapse = (type, ids) => {
    setExpandedEntities((prevState) => {
      const newState = { ...prevState }
      const entity = {
        offerId: ids[3] || null,
        pyramidId: ids[0],
        storyId: ids[1] || null,
        umbrellaId: ids[2] || null,
      }

      const updateState = (entityType, filterConditions) => {
        newState[entityType] = toggleEntityInArray(newState[entityType], entity)
        if (!isExpanded(type, ids)) {
          filterConditions.forEach((condition) => {
            newState[condition.entityType] = newState[
              condition.entityType
            ].filter((item) => !condition.filter(item, entity))
          })
        }
      }

      if (type === PYRAMID) {
        updateState('pyramids', [
          {
            entityType: 'stories',
            filter: (item, entity) => item.pyramidId === entity.pyramidId,
          },
          {
            entityType: 'umbrellas',
            filter: (item, entity) => item.pyramidId === entity.pyramidId,
          },
          {
            entityType: 'offers',
            filter: (item, entity) => item.pyramidId === entity.pyramidId,
          },
        ])
      } else if (type === STORY) {
        updateState('stories', [
          {
            entityType: 'umbrellas',
            filter: (item, entity) =>
              item.pyramidId === entity.pyramidId &&
              item.storyId === entity.storyId,
          },
          {
            entityType: 'offers',
            filter: (item, entity) =>
              item.pyramidId === entity.pyramidId &&
              item.storyId === entity.storyId,
          },
        ])
      } else if (type === UMBRELLA) {
        updateState('umbrellas', [
          {
            entityType: 'offers',
            filter: (item, entity) =>
              item.pyramidId === entity.pyramidId &&
              item.storyId === entity.storyId &&
              item.umbrellaId === entity.umbrellaId,
          },
        ])
      }
      return newState
    })
  }
  const toggleEntityInArray = (array, entity) => {
    const index = array.findIndex(
      (item) =>
        item.pyramidId === entity.pyramidId &&
        item.storyId === entity.storyId &&
        item.umbrellaId === entity.umbrellaId &&
        item.offerId === entity.offerId
    )
    if (index !== -1) {
      return array.filter((_, i) => i !== index)
    } else {
      return [...array, entity]
    }
  }

  const isExpanded = (type, ids) => {
    const entity = {
      offerId: ids[3] || null,
      pyramidId: ids[0],
      storyId: ids[1] || null,
      umbrellaId: ids[2] || null,
    }

    const checkExpansion = (entityType, conditions) =>
      expandedEntities[entityType].some((item) =>
        conditions.every((condition) => condition(item, entity))
      )

    if (type === PYRAMID) {
      return checkExpansion('pyramids', [
        (item, entity) => item.pyramidId === entity.pyramidId,
      ])
    } else if (type === STORY) {
      return checkExpansion('stories', [
        (item, entity) => item.pyramidId === entity.pyramidId,
        (item, entity) => item.storyId === entity.storyId,
      ])
    } else if (type === UMBRELLA) {
      return checkExpansion('umbrellas', [
        (item, entity) => item.pyramidId === entity.pyramidId,
        (item, entity) => item.storyId === entity.storyId,
        (item, entity) => item.umbrellaId === entity.umbrellaId,
      ])
    } else if (type === OFFER) {
      return checkExpansion('offers', [
        (item, entity) => item.pyramidId === entity.pyramidId,
        (item, entity) => item.storyId === entity.storyId,
        (item, entity) => item.umbrellaId === entity.umbrellaId,
        (item, entity) => item.offerId === entity.offerId,
      ])
    }
    return false
  }

  const handleClickorHover = (pyramidId, storyId, umbrellaId, offerId) => {
    const obj = {
      offerId: offerId,
      pyramidId: pyramidId,
      storyId: storyId,
      umbrellaId: umbrellaId,
    }
    setClickedEntityId(obj)
  }
  const checkClickedorHoveredEntity = (
    pyramidId,
    storyId,
    umbrellaId,
    offerId
  ) => {
    const {
      pyramidId: clickedPyramidId = '',
      storyId: clickedStoryId = '',
      umbrellaId: clickedUmbrellaId = '',
      offerId: clickedOfferId = '',
    } = clickedEntityId || {}
    return (
      pyramidId === clickedPyramidId &&
      storyId === clickedStoryId &&
      umbrellaId === clickedUmbrellaId &&
      offerId === clickedOfferId
    )
  }
  const handleChange = (
    e,
    entity,
    pageId,
    pyramidId,
    storyId,
    umbrellaId,
    offerId
  ) => {
    setPages((prevData) =>
      mapPageId(
        prevData.map((page) => {
          const { page_id } = page
          if (page_id === pageId) {
            return {
              ...page,
              pyramids: page.pyramids.map((pyramid) => {
                const { pyramid_id = '' } = pyramid
                if (pyramid_id === pyramidId) {
                  if (entity === PYRAMID) {
                    return {
                      ...pyramid,
                      pyramid_name: e.target.value,
                    }
                  } else if (entity === STORY) {
                    return {
                      ...pyramid,
                      stories: pyramid.stories.map((story) => {
                        const { story_id = '' } = story
                        if (story_id === storyId) {
                          return {
                            ...story,
                            story_name: e.target.value,
                          }
                        }
                        return story
                      }),
                    }
                  } else if (entity === UMBRELLA) {
                    return {
                      ...pyramid,
                      stories: pyramid.stories.map((story) => {
                        const { story_id = '' } = story
                        if (story_id === storyId) {
                          return {
                            ...story,
                            umbrellas: story.umbrellas.map((umbrella) => {
                              const { umbrella_id = '' } = umbrella
                              if (umbrella_id === umbrellaId) {
                                return {
                                  ...umbrella,
                                  umbrella_name: e.target.value,
                                }
                              }
                              return umbrella
                            }),
                          }
                        }
                        return story
                      }),
                    }
                  } else if (entity === OFFER) {
                    return {
                      ...pyramid,
                      stories: pyramid.stories.map((story) => {
                        const { story_id = '' } = story
                        if (story_id === storyId) {
                          return {
                            ...story,
                            umbrellas: story.umbrellas.map((umbrella) => {
                              const { umbrella_id = '' } = umbrella
                              if (umbrella_id === umbrellaId) {
                                return {
                                  ...umbrella,
                                  offers: umbrella.offers.map((offer) => {
                                    const { offer_id = '' } = offer
                                    if (offer_id === offerId) {
                                      return {
                                        ...offer,
                                        offer_name: e.target.value,
                                      }
                                    }
                                    return offer
                                  }),
                                }
                              }
                              return umbrella
                            }),
                          }
                        }
                        return story
                      }),
                    }
                  }
                }
                return pyramid
              }),
            }
          }
          return page
        })
      )
    )
  }

  const handleBlur = (
    e,
    entity,
    pageId,
    customName,
    pyramidId,
    storyId,
    umbrellaId,
    offerId
  ) => {
    const payload = {
      custom_name: customName,
      entity_type: entity,
      offer_id: offerId,
      pyramid_id: pyramidId,
      story_id: storyId,
      umbrella_id: umbrellaId,
    }
    updatePageSummary(pageId, payload)
  }
  const handleClick = (
    e,
    entity,
    pageId,
    pyramidId,
    storyId,
    umbrellaId,
    offerId
  ) => {
    const payload = {
      entity_type: entity,
      offer_id: offerId,
      pyramid_id: pyramidId,
      story_id: storyId,
      umbrella_id: umbrellaId,
    }
    deletePageSummary(pageId, payload)
  }
  const handleHideShowEntity = (
    e,
    entity,
    hide,
    pageId,
    pyramidId,
    storyId,
    umbrellaId,
    offerId
  ) => {
    const payload = {
      entity_type: entity,
      hidden: hide,
      offer_id: offerId,
      pyramid_id: pyramidId,
      story_id: storyId,
      umbrella_id: umbrellaId,
    }
    updatePageSummary(pageId, payload)
  }

  const shouldRenderEntity = (
    isPyramidExpanded,
    isStoryExpanded,
    isUmbrellaExpanded
  ) => !isPyramidExpanded && !isStoryExpanded && !isUmbrellaExpanded
  return (
    <Grid.Container
      direction="column"
      spacing="none"
      className="rounded-container"
      style={{ backgroundColor: !canEdit ? '#f0f0f0' : 'inherit' }}
      onMouseLeave={() => {
        setClickedEntityId({
          offerId: null,
          pyramidId: null,
          storyId: null,
          umbrellaId: null,
        })
      }}
    >
      {!loading && (
        <div>
          {pages
            .filter((page) => page?.page_id === pageId)
            .map((page = {}, ind) => {
              const { pyramids = [], page_id = '' } = page || {}
              return pyramids?.map((pyramid = {}, ind) => {
                const {
                  pyramid_type = '',
                  pyramid_name = '',
                  pyramid_order = 0,
                  pyramid_id = '',
                  pyramid_hidden = false,
                  stories = [],
                } = pyramid || {}
                const isPyramidExpanded = isExpanded(PYRAMID, [pyramid_id])
                return (
                  <Grid.Item
                    direction="column"
                    spacing="none"
                    key={`MP${pyramid_id}`}
                  >
                    <div
                      onClick={() => {
                        handleClickorHover(pyramid_id, null, null, null)
                      }}
                      onMouseEnter={() => {
                        handleClickorHover(pyramid_id, null, null, null)
                      }}
                    >
                      <InlineLabeledInputWithButton
                        expand={isPyramidExpanded}
                        hidden={pyramid_hidden}
                        label={`${
                          pyramid_type === 'ent'
                            ? `EGO ${pyramid_order}:`
                            : `${pyramid_type?.toUpperCase()} MP${pyramid_order}:`
                        }`}
                        value={`${pyramid_name}`}
                        type={PYRAMID}
                        onChange={(e) => {
                          handleChange(e, PYRAMID, page_id, pyramid_id)
                        }}
                        isHovered={checkClickedorHoveredEntity(
                          pyramid_id,
                          null,
                          null,
                          null
                        )}
                        onDeleteButtonClick={(e) => {
                          handleClick(e, PYRAMID, page_id, pyramid_id)
                        }}
                        onHideShowButtonClick={(e) => {
                          handleHideShowEntity(
                            e,
                            PYRAMID,
                            !pyramid_hidden,
                            page_id,
                            pyramid_id
                          )
                        }}
                        onExpandCollapseButtonClick={(e) => {
                          handleExpandCollapse(PYRAMID, [pyramid_id])
                        }}
                        onBlur={(e) => {
                          handleBlur(
                            e,
                            PYRAMID,
                            page_id,
                            pyramid_name,
                            pyramid_id
                          )
                        }}
                      />
                    </div>
                    <Grid.Item key={`S${pyramid_id}`}>
                      {stories?.map((story = {}, ind) => {
                        const {
                          story_hidden = false,
                          story_order = 0,
                          story_name = '',
                          umbrellas = [],
                          story_id = '',
                        } = story
                        const isStoryExpanded = isExpanded(STORY, [
                          pyramid_id,
                          story_id,
                        ])
                        return (
                          <Grid.Item key={`S${story_id}`}>
                            <div
                              onClick={() => {
                                handleClickorHover(
                                  pyramid_id,
                                  story_id,
                                  null,
                                  null
                                )
                              }}
                              onMouseEnter={() => {
                                handleClickorHover(
                                  pyramid_id,
                                  story_id,
                                  null,
                                  null
                                )
                              }}
                            >
                              {shouldRenderEntity(
                                isPyramidExpanded,
                                false,
                                false
                              ) && (
                                <InlineLabeledInputWithButton
                                  expand={isStoryExpanded}
                                  hidden={story_hidden}
                                  label={`S${story_order}:`}
                                  value={`${story_name}`}
                                  type={STORY}
                                  onChange={(e) => {
                                    handleChange(
                                      e,
                                      STORY,
                                      page_id,
                                      pyramid_id,
                                      story_id
                                    )
                                  }}
                                  isHovered={checkClickedorHoveredEntity(
                                    pyramid_id,
                                    story_id,
                                    null,
                                    null
                                  )}
                                  onDeleteButtonClick={(e) => {
                                    handleClick(
                                      e,
                                      STORY,
                                      page_id,
                                      pyramid_id,
                                      story_id
                                    )
                                  }}
                                  onHideShowButtonClick={(e) => {
                                    handleHideShowEntity(
                                      e,
                                      STORY,
                                      !story_hidden,
                                      page_id,
                                      pyramid_id,
                                      story_id
                                    )
                                  }}
                                  onBlur={(e) => {
                                    handleBlur(
                                      e,
                                      STORY,
                                      page_id,
                                      story_name,
                                      pyramid_id,
                                      story_id
                                    )
                                  }}
                                  onExpandCollapseButtonClick={(e) => {
                                    handleExpandCollapse(STORY, [
                                      pyramid_id,
                                      story_id,
                                    ])
                                  }}
                                />
                              )}
                            </div>
                            {umbrellas?.map((umbrella = {}, ind) => {
                              const {
                                umbrella_hidden = false,
                                umbrella_name = '',
                                offers = [],
                                umbrella_order = 0,
                                umbrella_id = '',
                                status = '',
                              } = umbrella
                              const isUmbrellaExpanded = !isExpanded(UMBRELLA, [
                                pyramid_id,
                                story_id,
                                umbrella_id,
                              ])
                              return (
                                <Grid.Item key={`U${umbrella_id}`}>
                                  <div
                                    onClick={() => {
                                      handleClickorHover(
                                        pyramid_id,
                                        story_id,
                                        umbrella_id,
                                        null
                                      )
                                    }}
                                    onMouseEnter={() => {
                                      handleClickorHover(
                                        pyramid_id,
                                        story_id,
                                        umbrella_id,
                                        null
                                      )
                                    }}
                                  >
                                    {shouldRenderEntity(
                                      isPyramidExpanded,
                                      isStoryExpanded,
                                      false
                                    ) && (
                                      <InlineLabeledInputWithButton
                                        expand={isUmbrellaExpanded}
                                        hidden={umbrella_hidden}
                                        status={status.toLowerCase()}
                                        label={`U${umbrella_order}:`}
                                        value={`${umbrella_name}`}
                                        type={UMBRELLA}
                                        onChange={(e) => {
                                          handleChange(
                                            e,
                                            UMBRELLA,
                                            page_id,
                                            pyramid_id,
                                            story_id,
                                            umbrella_id
                                          )
                                        }}
                                        isHovered={checkClickedorHoveredEntity(
                                          pyramid_id,
                                          story_id,
                                          umbrella_id,
                                          null
                                        )}
                                        onHideShowButtonClick={(e) => {
                                          handleHideShowEntity(
                                            e,
                                            UMBRELLA,
                                            !umbrella_hidden,
                                            page_id,
                                            pyramid_id,
                                            story_id,
                                            umbrella_id
                                          )
                                        }}
                                        onDeleteButtonClick={(e) => {
                                          handleClick(
                                            e,
                                            UMBRELLA,
                                            page_id,
                                            pyramid_id,
                                            story_id,
                                            umbrella_id
                                          )
                                        }}
                                        onBlur={(e) => {
                                          handleBlur(
                                            e,
                                            UMBRELLA,
                                            page_id,
                                            umbrella_name,
                                            pyramid_id,
                                            story_id,
                                            umbrella_id
                                          )
                                        }}
                                        onExpandCollapseButtonClick={(e) => {
                                          handleExpandCollapse(UMBRELLA, [
                                            pyramid_id,
                                            story_id,
                                            umbrella_id,
                                          ])
                                        }}
                                      />
                                    )}
                                  </div>
                                  {offers?.map((offer, ind) => {
                                    const {
                                      offer_hidden = false,
                                      offer_name = '',
                                      offer_order = 0,
                                      offer_id = '',
                                      offer_status = '',
                                    } = offer
                                    return (
                                      <Grid.Item key={`O${offer_id}`}>
                                        <div
                                          onClick={() => {
                                            handleClickorHover(
                                              pyramid_id,
                                              story_id,
                                              umbrella_id,
                                              offer_id
                                            )
                                          }}
                                          onMouseEnter={() => {
                                            handleClickorHover(
                                              pyramid_id,
                                              story_id,
                                              umbrella_id,
                                              offer_id
                                            )
                                          }}
                                        >
                                          {shouldRenderEntity(
                                            isPyramidExpanded,
                                            isStoryExpanded,
                                            isUmbrellaExpanded
                                          ) && (
                                            <InlineLabeledInputWithButton
                                              hidden={offer_hidden}
                                              status={offer_status.toLowerCase()}
                                              label={`O${offer_order}:`}
                                              value={`${offer_name}`}
                                              type={OFFER}
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  OFFER,
                                                  page_id,
                                                  pyramid_id,
                                                  story_id,
                                                  umbrella_id,
                                                  offer_id
                                                )
                                              }}
                                              isHovered={checkClickedorHoveredEntity(
                                                pyramid_id,
                                                story_id,
                                                umbrella_id,
                                                offer_id
                                              )}
                                              onDeleteButtonClick={(e) => {
                                                handleClick(
                                                  e,
                                                  OFFER,
                                                  page_id,
                                                  pyramid_id,
                                                  story_id,
                                                  umbrella_id,
                                                  offer_id
                                                )
                                              }}
                                              onHideShowButtonClick={(e) => {
                                                handleHideShowEntity(
                                                  e,
                                                  OFFER,
                                                  !offer_hidden,
                                                  page_id,
                                                  pyramid_id,
                                                  story_id,
                                                  umbrella_id,
                                                  offer_id
                                                )
                                              }}
                                              onBlur={(e) => {
                                                handleBlur(
                                                  e,
                                                  OFFER,
                                                  page_id,
                                                  offer_name,
                                                  pyramid_id,
                                                  story_id,
                                                  umbrella_id,
                                                  offer_id
                                                )
                                              }}
                                            />
                                          )}
                                        </div>
                                      </Grid.Item>
                                    )
                                  })}
                                </Grid.Item>
                              )
                            })}
                          </Grid.Item>
                        )
                      })}
                    </Grid.Item>
                  </Grid.Item>
                )
              })
            })}
        </div>
      )}
    </Grid.Container>
  )
}

export default EditablePageSummary
