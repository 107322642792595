import {
  Card,
  Chip,
  Button,
  Dialog,
  Grid,
  Heading,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState, useMemo, useContext } from 'react'

import '../../scss/DefaultFilters.scss'

import ChannelApi from '../../api/creative-hub/ChannelApi'
import DefaultFilterApi from '../../api/creative-hub/DefaultFilterApi'
import MerchandiseApi from '../../api/creative-hub/MerchandiseApi'
import { EffectiveRoleContext } from '../../context/Context'
import { divisionSelection } from '../../creativeHubGlobal/util/MerchandiseUtil'
import { formatChannels } from '../../ImageTracking/util/ItemUtil'
import {
  getChipValues,
  pyramid_options,
} from '../../planning/util/ProjectDetailsUtil'
import { getPyramidOptionsByValues } from '../../planning/util/ProjectDetailsUtil'
import { ErrorHandler } from '../../WeeklyAdAutomation/util/ErrorHandler'

import {
  PYRAMID,
  CHANNEL,
  DIVISION,
  PROMO_OFFERS_VIEW,
  MKTG_PRIORITIES_VIEW,
  DEFAULT_FILTERS_VIEWS,
} from './DefaultFilterConstants'

const DefaultFilter = () => {
  const channelApi = useMemo(() => new ChannelApi(), [])
  const merchandiseApi = useMemo(() => new MerchandiseApi(), [])
  const defaultFilterApi = useMemo(() => new DefaultFilterApi(), [])

  const makeToast = ToastProvider.useToaster()

  const [loading, setLoading] = useState(false)
  const [defaultFilterData, setDefaultFilterData] = useState([])
  const [channelOptions, setChannelOptions] = useState([])
  const [divisionOptions, setDivisionOptions] = useState([])
  const [clearFilterDialog, setClearFilterDialog] = useState(false)
  const { userDefinedFilters, setUserDefinedFilters } =
    useContext(EffectiveRoleContext)

  const fetchChannels = async () => {
    setLoading(true)
    try {
      const response = await channelApi.getChannels()
      setChannelOptions(formatChannels(response))
    } catch (error) {
      makeToast({ message: ErrorHandler(error), type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const fetchDivisions = async () => {
    setLoading(true)
    try {
      const response = await merchandiseApi.getDivisions()
      setDivisionOptions(divisionSelection(response))
    } catch (error) {
      makeToast({ message: ErrorHandler(error), type: 'error' })
    } finally {
      setLoading(false)
    }
  }
  const fetchDefaultFilters = () => {
    setLoading(true)
    try {
      const viewFilters = Object.keys(userDefinedFilters).map((viewName) => ({
        filters: userDefinedFilters[viewName]?.filters,
        view_name: viewName,
      }))
      setDefaultFilterData(
        viewFilters.length > 0 ? viewFilters : DEFAULT_FILTERS_VIEWS
      )
    } catch (error) {
      makeToast({ message: ErrorHandler(error), type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const updateDefaultFilters = async (payload) => {
    let reqbody = { view_filters: payload }
    setLoading(true)
    try {
      const response = await defaultFilterApi.putDefaults(reqbody)
      const { view_filters: viewFilters = [] } = response
      setDefaultFilterData(viewFilters)
      let filterObj = {}
      viewFilters?.forEach((filter) => {
        filterObj[filter.view_name] = {
          filters: filter.filters,
          view_name: filter.view_name,
        }
      })
      setUserDefinedFilters(filterObj || {})
    } catch (error) {
      makeToast({ message: ErrorHandler(error), type: 'error' })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchDefaultFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefinedFilters])
  useEffect(() => {
    fetchChannels()
    fetchDivisions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getChannelOptionsByIds = (ids = []) =>
    channelOptions.filter((channel) => ids.includes(channel.id))

  const getDivsionOptionsByIds = (ids = []) =>
    divisionOptions.filter((div) => ids.includes(div.id))

  const handleCopyValues = (categoryKey, field) => {
    const valuesToCopy = defaultFilterData.find(
      (data) => data.view_name === categoryKey
    )?.filters[field]

    const updatedDefaultFilterData = defaultFilterData?.map((defaultData) => ({
      ...defaultData,
      filters: {
        ...defaultData.filters,
        [field]: valuesToCopy,
      },
    }))
    setDefaultFilterData(updatedDefaultFilterData)
  }

  const handleApplyFilter = () => {
    updateDefaultFilters(defaultFilterData)
  }

  const clearFilter = () => {
    const updatedDefaultFilterData = defaultFilterData?.map((defaultData) => ({
      ...defaultData,
      filters: {
        channels: [],
        divisions: [],
        pyramids: [],
      },
    }))
    setDefaultFilterData(updatedDefaultFilterData)
    updateDefaultFilters(updatedDefaultFilterData)
  }

  const handleFieldUpdate = (value, viewName, type) => {
    const updatedDefaultFilterData = defaultFilterData?.map((data) => {
      if (data.view_name === viewName) {
        return {
          ...data,
          filters: {
            ...data.filters,
            [type]:
              type === PYRAMID
                ? value?.map((v) => v.value)
                : value?.map((v) => v.id),
          },
        }
      }
      return data
    })
    setDefaultFilterData(updatedDefaultFilterData)
  }

  return (
    <Card className={`full-screen-card ${loading ? '' : 'scrollable'}`}>
      {loading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <div className="hc-pa-normal">
          <Grid.Container>
            {defaultFilterData?.map((defaultData, index) => {
              const { view_name: viewName, filters = [] } = defaultData
              const { channels = [], pyramids = [], divisions = [] } = filters
              const PageName = viewName?.split('_').join(' ')
              return (
                <Grid.Item className="hc-ta-center" xs={12} key={index}>
                  <Heading size={4} className="hc-mb-expanded hc-fs-md">
                    {PageName}
                  </Heading>
                  <Grid.Container spacing="dense">
                    <Grid.Item xs={4}>
                      <Autocomplete
                        multiselect
                        chipHeight="dense"
                        data-testid={`Test ${CHANNEL} ${viewName}`}
                        className="hc-mb-dense hc-mt-dense"
                        onUpdate={(id, val) =>
                          handleFieldUpdate(val, viewName, CHANNEL)
                        }
                        value={getChannelOptionsByIds(channels)}
                        options={channelOptions}
                        label={'Channel'}
                      />
                      {viewName === PROMO_OFFERS_VIEW && (
                        <div className="right-aligned-container">
                          <Button
                            id={'apply-to-all-views-button'}
                            data-testid={`Copy ${CHANNEL} ${viewName}`}
                            type="ghost"
                            aria-label={'Copy'}
                            onClick={() => handleCopyValues(viewName, CHANNEL)}
                          >
                            <FontAwesomeIcon
                              icon={faCopy}
                              className="copy-button"
                            />
                            <span className="apply-to-all-views-label">
                              Apply to All Views
                            </span>
                          </Button>
                        </div>
                      )}
                    </Grid.Item>
                    <Grid.Item xs={4}>
                      <Autocomplete
                        multiselect
                        chipHeight="dense"
                        data-testid={`Test ${PYRAMID} ${viewName}`}
                        className="hc-mb-dense hc-mt-dense"
                        onUpdate={(id, val) =>
                          handleFieldUpdate(val, viewName, PYRAMID)
                        }
                        renderChip={(cat, onRequestDelete) => (
                          <Chip
                            size="dense"
                            style={{
                              backgroundColor: cat.color,
                              border: '0px',
                              color: 'white',
                            }}
                            onRequestDelete={onRequestDelete}
                          >
                            {getChipValues(cat.value)}
                          </Chip>
                        )}
                        value={getPyramidOptionsByValues(pyramids)}
                        options={pyramid_options}
                        label={'Pyramid'}
                      />
                      {viewName === PROMO_OFFERS_VIEW && (
                        <div className="right-aligned-container">
                          <Button
                            id={'apply-to-all-views-button'}
                            data-testid={`Copy ${PYRAMID} ${viewName}`}
                            type="ghost"
                            aria-label={'Copy'}
                            onClick={() => handleCopyValues(viewName, PYRAMID)}
                          >
                            <FontAwesomeIcon
                              icon={faCopy}
                              className="copy-button"
                            />
                            <span className="apply-to-all-views-label">
                              Apply to All Views
                            </span>
                          </Button>
                        </div>
                      )}
                    </Grid.Item>
                    {viewName !== MKTG_PRIORITIES_VIEW && (
                      <>
                        <Grid.Item xs={4}>
                          <Autocomplete
                            multiselect
                            chipHeight="dense"
                            data-testid={`Test ${DIVISION} ${viewName}`}
                            className="hc-mb-dense hc-mt-dense"
                            onUpdate={(id, val) =>
                              handleFieldUpdate(val, viewName, DIVISION)
                            }
                            value={getDivsionOptionsByIds(divisions)}
                            options={divisionOptions}
                            label={'Division'}
                          />
                          {viewName === PROMO_OFFERS_VIEW && (
                            <div className="right-aligned-container">
                              <Button
                                id={'apply-to-all-views-button'}
                                data-testid={`Copy ${DIVISION} ${viewName}`}
                                type="ghost"
                                aria-label={'Copy'}
                                onClick={() =>
                                  handleCopyValues(viewName, DIVISION)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  className="copy-button"
                                />
                                <span className="apply-to-all-views-label">
                                  Apply to All Views
                                </span>
                              </Button>
                            </div>
                          )}
                        </Grid.Item>
                      </>
                    )}
                  </Grid.Container>
                </Grid.Item>
              )
            })}
            <Grid.Container
              xs={12}
              align="center"
              justify="center"
              className="button-container"
            >
              <div className="bottom-buttons">
                <Grid.Item>
                  <Button
                    type="primary"
                    data-testid="save-button"
                    id={'color-palette-button'}
                    onClick={() => handleApplyFilter()}
                  >
                    Save Filters
                  </Button>
                  <Button
                    type="secondary"
                    data-testid="clear-button"
                    id={'book-captains-button'}
                    onClick={() => setClearFilterDialog(true)}
                  >
                    Clear Filters
                  </Button>
                </Grid.Item>
              </div>
              <Dialog
                headingText={'Are you sure you want to delete all filters?'}
                isVisible={clearFilterDialog}
                onRefuse={() => {
                  setClearFilterDialog(false)
                }}
                refuseButtonText={'Cancel'}
                approveButtonText={'OK'}
                onApprove={() => {
                  clearFilter()
                  setClearFilterDialog(false)
                }}
              />
            </Grid.Container>
          </Grid.Container>
        </div>
      )}
    </Card>
  )
}
export default DefaultFilter
