import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useContext } from 'react'

import { WeeklyAdAutomationContext } from '../../context/Context'
import './scss/AdditionalInfoTabs.scss'

const Captains = ({ canEdit, pageId = { pageId } }) => {
  const {
    contactOptions,
    details,
    setDetails,
    updatePageDetails,
    isAdditionalInfoLoading,
  } = useContext(WeeklyAdAutomationContext)
  const { CreativeArt = [], Copywriter = [], Marketing = [] } = contactOptions
  const { art_director = '', copy_writer = '', ena_manager = '' } = details
  const designations = [
    { id: 'art_director', label: 'Art Director (AD)' },
    { id: 'copy_writer', label: 'Copy Writer (CW)' },
    { id: 'ena_manager', label: 'E & A Manager' },
  ]
  const formatAutocompleteValue = (value) => {
    if (!value) return []
    return value?.split(', ').map((value) => ({
      id: value,
      isNew: true,
      label: value,
      value,
    }))
  }
  const handleClick = () => {
    const payload = { art_director, copy_writer, ena_manager }
    updatePageDetails(pageId, payload)
  }
  const handleUpdate = (field, value) => {
    const options = value?.map((item) => item.value).join(', ') || ''
    setDetails({
      ...details,
      [field]: options,
    })
  }
  const renderAutocomplete = (id, label, field) => {
    let options = []
    if (id === 'art_director') {
      options = CreativeArt
    } else if (id === 'copy_writer') {
      options = Copywriter
    } else if (id === 'ena_manager') {
      options = Marketing
    }

    return (
      <div style={{ marginBottom: '40px' }}>
        <label className="C-Input--type-label" htmlFor={id}>
          {label}
        </label>
        <Autocomplete
          id={id}
          data-testid={`test-${id}`}
          multiselect
          chipHeight="dense"
          onUpdate={(id, value) => handleUpdate(field, value)}
          value={formatAutocompleteValue(details?.[field])}
          options={options}
          grow
          noResultsMessage="Use Outlook Name"
          disabled={!canEdit}
        />
      </div>
    )
  }
  return (
    <div>
      {!isAdditionalInfoLoading && (
        <>
          <Grid.Container spacing="none" direction="column">
            <Grid.Item className="centered-captains">
              {designations.map((designation, index) => (
                <div key={index}>
                  {renderAutocomplete(
                    designation.id,
                    designation.label,
                    designation.id
                  )}
                </div>
              ))}
            </Grid.Item>
            <Grid.Item>
              <Button
                type="primary"
                onClick={handleClick}
                fullWidth
                disabled={!canEdit}
                data-testid="save-button"
              >
                Save
              </Button>
            </Grid.Item>
          </Grid.Container>
        </>
      )}
    </div>
  )
}

export default Captains
