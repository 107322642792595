import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Card,
  Button,
  Grid,
  Heading,
  Image,
  Modal,
  Spinner,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'
import { faPalette, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useContext } from 'react'

import { CanEditContext, WeeklyAdAutomationContext } from '../context/Context'

import CustomColorPicker from './CustomColorPicker'
import {
  NUMBERED,
  LETTERED,
  DEFAULT_COLOR,
  DEACTIVATED,
} from './util/Constants'

import './scss/pages.scss'

const DraggablePage = ({
  data,
  id,
  displayNumber,
  onDetailsClick,
  pageIndex,
  pageRef,
}) => {
  const [isColorModalOpen, setIsColorModalOpen] = useState(false)
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false)
  const {
    addEntityToPage,
    setCurrentPageInfo,
    currentPageInfo,
    setExpandedEntities,
    isIndividualPageLoading,
    deletePage,
  } = useContext(WeeklyAdAutomationContext)
  const { canEdit } = useContext(CanEditContext)
  const [dropZoneStyle, setDropZoneStyle] = useState({})
  const {
    page_id = '',
    page_color = DEFAULT_COLOR,
    art_director = '',
    copy_writer = '',
    page_image_link = '',
  } = data
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id })
  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  }
  const handleDrop = (event, pageData) => {
    let data
    if (event.dataTransfer.types.includes('page-entity')) {
      data = event.dataTransfer.getData('page-entity')
      addEntityToPage(pageData, JSON.parse(data))
    }
  }
  const renderPageContent = (pageData) => {
    const { type = NUMBERED } = pageData
    if (isIndividualPageLoading === pageData?.page_id) {
      return (
        <div className="fabContainer">
          <Spinner size="expanded" />
        </div>
      )
    } else if (type === LETTERED && data.pyramids?.length === 0) {
      return <Image alt="Not Found" width="100%" src={page_image_link} />
    } else {
      return pageData?.pyramids?.map((pyramid = {}, ind) => {
        const {
          pyramid_id = '',
          pyramid_type = '',
          pyramid_name = '',
          pyramid_order = 0,
          pyramid_hidden = false,
          stories = [],
        } = pyramid
        return (
          <Grid.Container
            spacing="none"
            align="center"
            justify="flex-start"
            xs={12}
            key={pyramid_id}
          >
            {!pyramid_hidden && (
              <Heading size={6}>
                {pyramid_type === 'ent'
                  ? 'EGO'
                  : `${pyramid_type?.toUpperCase()} MP`}
                {pyramid_order}: {pyramid_name}
              </Heading>
            )}
            <Grid.Item xs={12} key={`${pyramid_order}-${ind}`}>
              {stories?.map((story = {}, ind) => {
                const {
                  story_order = 0,
                  story_name = '',
                  story_hidden = false,
                  umbrellas = [],
                } = story
                return (
                  <Grid.Item key={`${story_order}-${ind}`}>
                    <Grid.Item>
                      {!story_hidden && (
                        <Heading size={6}>
                          {' '}
                          S{story_order}: {story_name}
                        </Heading>
                      )}
                    </Grid.Item>
                    {umbrellas?.map((umbrella = {}, ind) => {
                      const {
                        umbrella_name = '',
                        offers = [],
                        umbrella_order = 0,
                        umbrella_hidden = false,
                        status = '',
                      } = umbrella
                      return (
                        <Grid.Item key={`${umbrella_order}-${ind}`}>
                          <Grid.Item
                            className={
                              status.toLowerCase() === DEACTIVATED
                                ? 'deactivated'
                                : ''
                            }
                          >
                            {' '}
                            {!umbrella_hidden
                              ? `U${umbrella_order}: ${umbrella_name}`
                              : ''}
                          </Grid.Item>
                          {offers?.map((offer, ind) => {
                            const {
                              offer_name = '',
                              offer_order = 0,
                              circle_offer = false,
                              offer_status = '',
                              offer_hidden = false,
                            } = offer
                            return (
                              <Grid.Item
                                key={`${offer_order}-${ind}`}
                                className={
                                  offer_status.toLowerCase() === DEACTIVATED
                                    ? 'deactivated'
                                    : ''
                                }
                                style={{
                                  ...(circle_offer && {
                                    backgroundColor: '#E7E7E7',
                                    color: '#F3383D',
                                  }),
                                }}
                              >
                                {!offer_hidden
                                  ? `O${offer_order}: ${offer_name}`
                                  : ''}
                              </Grid.Item>
                            )
                          })}
                        </Grid.Item>
                      )
                    })}
                  </Grid.Item>
                )
              })}
            </Grid.Item>
          </Grid.Container>
        )
      })
    }
  }
  return (
    <>
      <div
        className="draggablePageContainer"
        style={{
          ...style,
          cursor:
            canEdit && !currentPageInfo?.showAdditionalInfo
              ? 'grab'
              : 'default',
          width: currentPageInfo?.showAdditionalInfo ? '100%' : 220,
        }}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        onDragEnter={(event) => {
          if (
            event.dataTransfer.types.includes('page-entity') &&
            !currentPageInfo?.showAdditionalInfo
          ) {
            setDropZoneStyle({ border: '1px solid black' })
          }
        }}
        onDragOver={(event) => {
          if (
            event.dataTransfer.types.includes('page-entity') &&
            !currentPageInfo?.showAdditionalInfo
          ) {
            setDropZoneStyle({ border: '1px solid black' })
          }
          event.dataTransfer.types.includes('page-entity') &&
            event.preventDefault()
        }}
        onDrop={(event) => {
          handleDrop(event, data)
          setDropZoneStyle({})
        }}
        onDragLeave={(event) => {
          setDropZoneStyle({})
        }}
      >
        <Card
          style={{
            aspectRatio: '25/34',
            backgroundColor:
              (data?.pyramids?.length ?? 0) != 0 ? page_color : DEFAULT_COLOR,
            border:
              currentPageInfo?.page_id === id
                ? '1px solid #3d70d6'
                : '1px solid #fff',
            ...dropZoneStyle,
          }}
        >
          <div
            style={{
              aspectRatio: '69/80',
              overflow: 'scroll',
              padding: 5,
            }}
            ref={pageRef}
          >
            {renderPageContent(data)}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 5,
            }}
          >
            <div>
              <Button
                iconOnly
                type="ghost"
                aria-label="Page Information Icon"
                onClick={(e) => {
                  setCurrentPageInfo({
                    ...data,
                    displayNumber,
                    showAdditionalInfo: true,
                  })
                  onDetailsClick(pageIndex)
                  setExpandedEntities({
                    offers: [],
                    pyramids: [],
                    stories: [],
                    umbrellas: [],
                  })
                }}
              >
                <EnterpriseIcon
                  size="sm"
                  icon={InfoIcon}
                  className="icon-color"
                />
              </Button>
              {(data?.pyramids?.length ?? 0) != 0 &&
                !currentPageInfo?.showAdditionalInfo && (
                  <Button
                    iconOnly
                    type="ghost"
                    aria-label="Color Palette Icon"
                    onClick={(e) => {
                      setIsColorModalOpen(true)
                    }}
                  >
                    <FontAwesomeIcon className="icon-color" icon={faPalette} />
                  </Button>
                )}
              {canEdit &&
              !(isIndividualPageLoading === data?.page_id) &&
              !currentPageInfo?.showAdditionalInfo &&
              (data?.pyramids?.length ?? 0) === 0 ? (
                <>
                  <Button
                    iconOnly
                    type="ghost"
                    aria-label="Delete Page Icon"
                    onClick={() => {
                      setIsDeleteDialogVisible(true)
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} className="icon-color" />
                  </Button>
                  <Modal
                    className="color-picker-modal"
                    headingText={`PAGE ${displayNumber} - Confirm Delete Action`}
                    isVisible={isDeleteDialogVisible}
                    onRefuse={() => {
                      setIsDeleteDialogVisible(false)
                    }}
                    size="dense"
                  >
                    <div className="hc-pa-normal">
                      <Grid.Container direction="row-reverse" spacing="dense">
                        <Grid.Item>
                          <Button
                            onClick={() => {
                              deletePage(page_id)
                            }}
                            type="primary"
                          >
                            DELETE
                          </Button>
                        </Grid.Item>
                        <Grid.Item>
                          <Button
                            onClick={() => setIsDeleteDialogVisible(false)}
                            type="secondary"
                          >
                            CANCEL
                          </Button>
                        </Grid.Item>
                      </Grid.Container>
                    </div>
                  </Modal>
                </>
              ) : null}
            </div>
            <div className="page-label">
              <Heading size={5} className="label-text">
                {displayNumber}
              </Heading>
            </div>
          </div>
        </Card>
        <div>
          <div className="textStyles">AD:{art_director}</div>
          <div className="textStyles">CW:{copy_writer}</div>
        </div>
      </div>
      <div>
        <CustomColorPicker
          isColorModalOpen={isColorModalOpen}
          setIsColorModalOpen={setIsColorModalOpen}
          pageId={page_id}
          pageNumber={displayNumber}
          pageColor={page_color === '' ? DEFAULT_COLOR : page_color}
        />
      </div>
    </>
  )
}

export default DraggablePage
