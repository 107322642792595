import { Modal, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import './AdditionalInfoTabs/scss/AdditionalInfoTabs.scss'
import { useRef, useContext, useEffect } from 'react'

import { CanEditContext, WeeklyAdAutomationContext } from '../context/Context'

const BookCaptains = ({
  isBookCaptainsModalVisible,
  setIsBookCaptainsModalVisible,
  bookCaptains,
  setBookCaptains,
  projectId,
}) => {
  const initialValuesRef = useRef({ ...bookCaptains })
  const { updateProjectPageDetails } = useContext(WeeklyAdAutomationContext)
  const { canEdit } = useContext(CanEditContext)
  useEffect(() => {
    if (isBookCaptainsModalVisible) {
      initialValuesRef.current = { ...bookCaptains }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBookCaptainsModalVisible])
  const { contactOptions } = useContext(WeeklyAdAutomationContext)
  const { CreativeArt = [], CreativeProd = [] } = contactOptions
  const designations = [
    { id: 'designer_captain', label: 'Designer Captain (DC)' },
    {
      id: 'production_designer_captain',
      label: 'Production Designer Captain (PDC)',
    },
  ]
  const renderAutocomplete = (id, label, field) => {
    let options = []
    if (id === 'designer_captain') {
      options = CreativeArt
    } else if (id === 'production_designer_captain') {
      options = CreativeProd
    }
    const formatAutocompleteValue = (value) => {
      if (!value) return []
      return [
        {
          id: value,
          isNew: true,
          label: value,
          value,
        },
      ]
    }
    const handleUpdate = (field, value) => {
      const { value: option = '' } = value[0] || {}
      setBookCaptains({
        ...bookCaptains,
        [field]: value?.length ? option : '',
      })
    }
    return (
      <div style={{ marginBottom: '40px' }}>
        <label className="C-Input--type-label" htmlFor={id}>
          {label}
        </label>
        <Autocomplete
          id={id}
          data-testid={`test-${id}`}
          multiselect
          chipHeight="dense"
          onUpdate={(id, value) => handleUpdate(field, value)}
          maxSelectedOptions={1}
          value={formatAutocompleteValue(bookCaptains?.[field])}
          options={options}
          grow
          disabled={!canEdit}
          noResultsMessage="Use Outlook Name"
        />
      </div>
    )
  }
  const handleClose = () => {
    setBookCaptains(initialValuesRef.current)
    setIsBookCaptainsModalVisible(false)
  }
  const handleClick = () => {
    let payload = { project_id: projectId, ...bookCaptains }
    updateProjectPageDetails(payload, 'BookCaptains')
    handleClose()
  }
  return (
    <Modal
      isVisible={isBookCaptainsModalVisible}
      headingText={`Select Book Captains`}
      onRefuse={handleClose}
      size="dense"
    >
      <Grid.Container spacing="none" direction="column">
        <Grid.Item className="book-captains">
          {designations.map((designation, index) => (
            <div key={index}>
              {renderAutocomplete(
                designation.id,
                designation.label,
                designation.id
              )}
            </div>
          ))}

          <Button
            type="primary"
            fullWidth
            onClick={handleClick}
            disabled={!canEdit}
            data-testid="save-button"
          >
            Save
          </Button>
        </Grid.Item>
      </Grid.Container>
    </Modal>
  )
}
export default BookCaptains
