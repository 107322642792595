import {
  Dropdown,
  Button,
  Form,
  Grid,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import {
  faEllipsisH,
  faAngleDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../publishing/scss/Category.scss'
import TextField from '@mui/material/TextField'
import { ModalDrawer } from 'nicollet-react'
import { useState, useMemo, useEffect, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'

import OfferApi from '../../api/creative-hub/OfferApi'
import WeeklyAdAutomationApi from '../../api/creative-hub/WAAutomationApi'
import {
  CanEditContext,
  WeeklyAdAutomationContext,
} from '../../context/Context'
import AssetHubExecutionModal from '../../execution/components/AssetHubExecutionModal'
import CreativeContent from '../../publishing/components/CreativeContent'
import MarketingDetails from '../../publishing/components/MarketingDetails'
import Offer from '../../publishing/components/Offer'
import PublishItemsBucket from '../../publishing/components/PublishItemsBucket'
import {
  buildUrl,
  consolidateWeeklyMediaUnitOffers,
  offerToMediaUnitXfer,
  returnDateNoTime,
  adPatchCheck,
} from '../../publishing/util/helpers'
import { ErrorHandler } from '../util/ErrorHandler'

const WeeklyAdMediaUnit = ({
  pageId,
  mediaUnit = {},
  index,
  setIsDraggable,
}) => {
  const { canEdit } = useContext(CanEditContext)
  const {
    selectedProject,
    makeToast,
    categoryOptions,
    deleteMediaUnit,
    mediaUnits,
    setMediaUnits,
  } = useContext(WeeklyAdAutomationContext)
  const weeklyAdAutomationApi = useMemo(() => new WeeklyAdAutomationApi(), [])
  const { projectId } = useParams()
  const [expandMediaUnit, setExpandMediaUnit] = useState(false)
  const [itemSelected, setItemSelected] = useState()
  const [updatedItemImage, setUpdatedItemImage] = useState()
  const [detailedMediaUnit, setDetailedMediaUnit] = useState([])
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [isOfferDrawerOpen, setIsOfferDrawerOpen] = useState(false)
  const [changeDetected, setChangeDetected] = useState(false)
  const [updating, setUpdating] = useState(false)
  const offerApi = useMemo(() => new OfferApi(), [])

  const [hasFeaturedItems, setHasFeaturedItems] = useState(false)
  const [mediaUnitHeight, setMediaUnitHeight] = useState(0)
  const [showMediaUnitFlyout, setShowMediaUnitFlyout] = useState(false)
  const [isItemImageModalOpen, setIsItemImageModalOpen] = useState(false)
  const [editCategories, setEditCategories] = useState(false)
  const [dropZoneStyle, setDropZoneStyle] = useState({})
  const { id: mediaUnitId, name: mediaUnitName } = mediaUnit
  const {
    boosted_tcins = '',
    landing_url = '',
    notes = '',
    categories = [],
    eyebrow = '',
    feature_offer = '',
    body_copy = '',
    offers = [],
    images = [],
    promotion_message = '',
  } = detailedMediaUnit || {}
  const creativeImage = images[0]?.external_url ?? ''
  const ref = useRef(null)
  const [initialDetailedMediaUnitName, setInitialDetailedMediaUnitName] =
    useState(mediaUnitName)

  useEffect(() => {
    const handleResize = () => {
      setMediaUnitHeight(ref?.current?.offsetHeight)
    }
    handleResize()
    window.addEventListener('click', handleResize)

    return () => {
      window.removeEventListener('click', handleResize)
    }
  })

  useEffect(() => {
    if (expandMediaUnit) {
      setUpdating(true)
      weeklyAdAutomationApi
        .getMediaUnitdetails(pageId, mediaUnitId)
        .then((data) => {
          setDetailedMediaUnit(data)
          setUpdating(false)
        })
        .catch((error) => {
          makeToast({
            message: ErrorHandler(error),
            type: 'error',
          })
          setUpdating(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandMediaUnit, mediaUnitId])

  useEffect(() => {
    if (changeDetected === true) {
      updateMediaUnits(pageId, detailedMediaUnit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDetected])

  useEffect(() => {
    var hasAdPatches = false

    detailedMediaUnit?.offers?.forEach((offer) => {
      if (offer?.ad_patches?.length > 0) {
        hasAdPatches = true
      }
    })
    if (detailedMediaUnit?.offers) {
      hasAdPatches = adPatchCheck(detailedMediaUnit?.offers)
    }

    setHasFeaturedItems(hasAdPatches)
  }, [detailedMediaUnit?.offers])

  const updateField = (id, value) => {
    setDetailedMediaUnit((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }))
  }
  const updateImageField = (id, value) => {
    setDetailedMediaUnit((prevDetails) => {
      const updatedImages =
        prevDetails.images && prevDetails.images.length > 0
          ? prevDetails.images.map((image, index) =>
              index === 0 ? { ...image, external_url: value } : image
            )
          : [{ [id]: value, id: null, internal_url: '' }]

      return {
        ...prevDetails,
        images: updatedImages,
      }
    })
  }
  const dragCheck = (event) => {
    if (
      event.dataTransfer.types.includes('drag-offer') ||
      event.dataTransfer.types.includes('drag-umbrella')
    ) {
      setDropZoneStyle('insideStyle')
      event.preventDefault()
    }
  }

  const dragLeave = () => {
    setDropZoneStyle({})
  }

  const extractDroppedOfferInfo = (sortedOffers) => {
    if (sortedOffers?.length > 0) {
      const url = buildUrl(sortedOffers, detailedMediaUnit)
      updateField('landing_url', url)
      var offerDataArray = offerToMediaUnitXfer(sortedOffers)
      offerDataArray.forEach((update) => {
        switch (update) {
          case 'sale_handle':
            updateField('eyebrow', 'Sale')
            break
          default:
            break
        }
      })
    } else {
      updateField('landing_url', '')
    }
  }

  const handleMediaUnitNameChange = (e) => {
    updateField('name', e.target.value)
    const updatedMediaUnits = mediaUnits.map((unit) =>
      unit.id === mediaUnitId ? { ...unit, name: e.target.value } : unit
    )
    setMediaUnits(updatedMediaUnits)
  }
  const handleMediaUnitNameSave = (e) => {
    if (mediaUnitName !== initialDetailedMediaUnitName) {
      if (expandMediaUnit) {
        setChangeDetected(true)
      } else if (expandMediaUnit === false) {
        let payload = { ...mediaUnit, project_id: projectId }
        updateMediaUnits(pageId, payload)
      }
      setInitialDetailedMediaUnitName(detailedMediaUnit?.name || mediaUnitName)
    }
  }
  const updateMediaUnits = (pageId, payload) => {
    setUpdating(true)
    weeklyAdAutomationApi
      .updateMediaUnit(pageId, payload)
      .then((data) => {
        setDetailedMediaUnit((prevDetails) => ({
          ...data,
          offers: data.offers.map((offer) => {
            const existingOffer = prevDetails?.offers?.find(
              (prevOffer) => prevOffer.id === offer.id
            )
            return existingOffer
              ? {
                  ...existingOffer,
                  deleted_from_page: offer?.deleted_from_page,
                }
              : offer
          }),
        }))
        setUpdating(false)
        setChangeDetected(false)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setUpdating(false)
        setChangeDetected(false)
      })
  }

  const handleDrop = async (event) => {
    setDropZoneStyle({})
    let dragOffers = []
    if (event.dataTransfer.types.includes('drag-offer')) {
      dragOffers = event.dataTransfer.getData('drag-offer')
      addOffers(dragOffers, event.dataTransfer.types.includes('drag-umbrella'))
    } else if (event.dataTransfer.types.includes('drag-umbrella')) {
      dragOffers = event.dataTransfer.getData('drag-umbrella')
      if (JSON.parse(dragOffers).length > 0) {
        addOffers(
          dragOffers,
          event.dataTransfer.types.includes('drag-umbrella')
        )
      } else {
        makeToast({
          message:
            'No offers available for this umbrella. Please Drop one with offers',
          type: 'error',
        })
      }
    }
  }

  const addOffers = async (offers, umbrellaType) => {
    setUpdating(true)
    let offersObj = JSON.parse(offers)
    let offer_ids = offersObj.map((offer = {}) => offer.offer_id)
    const offerDetails = await offerApi
      .getAllOfferDetailsByUUID(offer_ids)
      .then((res) => {
        if (umbrellaType) {
          offerApi
            .getOfferPromoMessage(res[0]?.umbrella_id, projectId)
            .then((res) => {
              if (res.error) throw new Error(res.error)
              updateField('promotion_message', res?.promo_message)
              setChangeDetected(true)
            })
            .catch((error) => {
              makeToast({
                message:
                  'Error Pulling Promo Message from Umbrella: ' +
                  ErrorHandler(error),
                type: 'error',
              })
            })
        } else {
          setChangeDetected(true)
        }
        if (res.error) throw new Error(res.error)
        return res
      })
      .catch((error) => {
        makeToast({
          message: 'Error Offer details: ' + ErrorHandler(error),
          type: 'error',
        })
      })
    const newOffers = consolidateWeeklyMediaUnitOffers(
      offerDetails,
      offersObj,
      detailedMediaUnit?.offers
    )
    let sortOffers = detailedMediaUnit?.offers?.concat(newOffers)
    sortOffers?.sort((a, b) => (a.offer_order > b.offer_order ? 1 : -1))
    const startDate = sortOffers?.[0]?.start_date
    const endDate = sortOffers?.[0]?.end_date

    updateField(
      'start_date',
      startDate && !isNaN(Date.parse(startDate))
        ? returnDateNoTime(startDate)
        : ''
    )
    updateField(
      'end_date',
      endDate && !isNaN(Date.parse(endDate)) ? returnDateNoTime(endDate) : ''
    )
    extractDroppedOfferInfo(sortOffers)
    updateField('offers', sortOffers)
    setUpdating(false)
  }

  const removeOffer = (e, offerId) => {
    const afterDeleteOffers = detailedMediaUnit?.offers?.filter(
      (offer) => offer.id !== offerId
    )
    updateField('offers', afterDeleteOffers)
    extractDroppedOfferInfo(afterDeleteOffers)
    setChangeDetected(true)
  }

  const handleDelete = async (id) => deleteMediaUnit(pageId, id)
  return (
    <div>
      <div className={`media-unit-container`} data-testid="media-unit">
        <Grid.Container>
          <Grid.Item className="media-unit-name" xs={6}>
            <Grid.Container>
              <Grid.Item
                data-testid="media-unit-expand-button"
                onClick={() => {
                  setExpandMediaUnit(!expandMediaUnit)
                  setIsDraggable(expandMediaUnit)
                }}
                xs={1}
              >
                <FontAwesomeIcon
                  style={{ fill: 'black' }}
                  className={`media-unit-expand-icon ${
                    expandMediaUnit
                      ? 'rotate-clockwise-45'
                      : 'rotate-counter-clockwise-45'
                  }`}
                  icon={faAngleDown}
                  size="lg"
                />
              </Grid.Item>
              <Grid.Item xs={11} className="media-unit-name-grid-item">
                <Form.Field
                  data-testid="media-name-text"
                  className="media-unit-name-input"
                  value={mediaUnitName}
                  onChange={handleMediaUnitNameChange}
                  onBlur={handleMediaUnitNameSave}
                  placeholder={`Media Unit ${index + 1}`}
                  disabled={!canEdit}
                  id={`media-unit-name`}
                  key={`media-unit-name `}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
          <Grid.Item xs={6}>
            <Grid.Container justify="flex-end">
              <Grid.Item className="media-unit-button-container">
                <Dropdown location="bottom-right">
                  <Button className="media-unit-button-elipse">
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      size="lg"
                      onClick={() => {}}
                      id="publishing-dropdown"
                      data-testid="media-unit-options-dropdown"
                      data-cy={`publishing-dropdown`}
                    />
                  </Button>
                  <Dropdown.Menu className="mu-ellipsis-dropdown">
                    <Dropdown.MenuItem
                      disabled={!canEdit}
                      onClick={() => handleDelete(mediaUnitId)}
                    >
                      Delete
                    </Dropdown.MenuItem>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        {updating ? (
          <Grid.Container justify="space-between">
            <Grid.Item xs={12}>
              <ProgressBar indeterminate error />
            </Grid.Item>
          </Grid.Container>
        ) : null}
        <div
          className={
            showMediaUnitFlyout ? 'weeklyad-media-unit-container-content' : ''
          }
          ref={ref}
        >
          {expandMediaUnit ? (
            <div
              data-testid={'media-unit-offer-bucket'}
              className={`publishing-drag ${dropZoneStyle}`}
              onDragEnter={(event) => dragCheck(event)}
              onDragOver={(event) => dragCheck(event)}
              onDragLeave={dragLeave}
              onDrop={(event) => handleDrop(event, 1)}
            >
              <Grid.Container
                className="media-unit-text"
                justify="space-between"
              >
                {offers.length === 0 ? (
                  <Grid.Item xs={12} className="drag-text" justify="center">
                    Drag and drop Offers or Umbrellas here
                  </Grid.Item>
                ) : null}
                <Grid.Item xs={12}>
                  {offers?.length > 0 && hasFeaturedItems > 0 ? (
                    <div className="linked-offers-header">
                      <div className="linked-offers-text">Linked offers</div>
                      <Button
                        data-testid={'show-all-items'}
                        className="publishing-show-all-items-button"
                        onClick={() => {
                          setSelectedOffer(offers)
                          setShowMediaUnitFlyout(!showMediaUnitFlyout)
                        }}
                      >
                        {showMediaUnitFlyout
                          ? 'Hide All Featured Items'
                          : 'Show All Featured Items'}
                      </Button>
                    </div>
                  ) : null}
                  <div className="offer-bg">
                    {offers?.length > 0
                      ? offers.map((offer, i) => (
                          <div key={i}>
                            <Offer
                              offer={offer}
                              weeklyAdPage={true}
                              removeOffer={removeOffer}
                              setSelectedOffer={setSelectedOffer}
                              setIsOfferDrawerOpen={setIsOfferDrawerOpen}
                              selectedProject={selectedProject}
                              canRemoveOffer={canEdit}
                            />
                          </div>
                        ))
                      : null}
                  </div>
                </Grid.Item>
                {promotion_message ? (
                  <Grid.Item xs={12} className="promo-message-umbrella">
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id="promoMessage"
                      name="promoMessage"
                      label="Promo Message from Umbrella"
                      value={promotion_message}
                      InputProps={{
                        readOnly: true,
                        style: { fontSize: 14, padding: '10px' },
                      }}
                    />
                  </Grid.Item>
                ) : null}
              </Grid.Container>
            </div>
          ) : null}
          {expandMediaUnit ? (
            <>
              <MarketingDetails
                dwaChannels={true}
                setEditCategories={setEditCategories}
                editCategories={editCategories}
                mediaUnitData={detailedMediaUnit}
                categoryOptions={categoryOptions ?? []}
                disabled={updating || !canEdit}
                destinationUrl={landing_url}
                setDestinationUrl={(value) => updateField('landing_url', value)}
                notesInput={notes}
                setNotesInput={(value) => updateField('notes', value)}
                categoriesSelected={categories}
                setCategoriesSelected={(value) =>
                  updateField('categories', value)
                }
                setChangeDetected={setChangeDetected}
                isPriorityCategory={true}
              />
              <CreativeContent
                mediaUnitData={detailedMediaUnit}
                disabled={!canEdit}
                eyebrowInput={eyebrow}
                setEyebrowInput={(value) => updateField('eyebrow', value)}
                featureOffer={feature_offer}
                setFeatureOffer={(value) => updateField('feature_offer', value)}
                bodyCopyInput={body_copy}
                setBodyCopyInput={(value) => updateField('body_copy', value)}
                creativeImage={creativeImage}
                setCreativeImage={(value) =>
                  updateImageField('external_url', value)
                }
                setChangeDetected={setChangeDetected}
              />
            </>
          ) : null}
        </div>
        {showMediaUnitFlyout && expandMediaUnit && (
          <div>
            <div className="media-unit-row-container">
              <div className="media-unit-item-title"> All Featured Items</div>
              <div className="close-item-flyout">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => {
                    setShowMediaUnitFlyout(false)
                  }}
                  size="lg"
                />
              </div>
            </div>
            <div
              className="wa-mu-container-flyout"
              data-testid="media-unit-flyout"
              style={{
                height: mediaUnitHeight - 90,
              }}
            >
              <PublishItemsBucket
                canEdit={canEdit}
                selectedOffer={offers}
                setIsItemImageModalOpen={setIsItemImageModalOpen}
                setItemSelected={setItemSelected}
                updatedItemImage={updatedItemImage}
                boostedTcins={boosted_tcins}
                setBoostedTcins={(value) => updateField('boosted_tcins', value)}
                showPriority={true}
                setChangeDetected={setChangeDetected}
              />
            </div>
          </div>
        )}
        <ModalDrawer
          isOpen={isOfferDrawerOpen}
          iconButtonCloseOnClick={() =>
            setIsOfferDrawerOpen(!isOfferDrawerOpen)
          }
          scroll={true}
          onRequestClose={() => {
            setIsOfferDrawerOpen(false)
          }}
          headingText={
            selectedOffer &&
            selectedOffer.adPatches &&
            selectedOffer.adPatches.length > 1
              ? 'Art Patches'
              : 'Featured Items'
          }
          sm={12}
          md={6}
          lg={4}
        >
          <PublishItemsBucket
            selectedOffer={selectedOffer}
            setIsItemImageModalOpen={setIsItemImageModalOpen}
            setItemSelected={setItemSelected}
            updatedItemImage={updatedItemImage}
            showPriority={false}
            canEdit={canEdit}
          />
        </ModalDrawer>
        {isItemImageModalOpen && selectedOffer != null && (
          <AssetHubExecutionModal
            isItemImageModalOpen={isItemImageModalOpen}
            selectedOffer={selectedOffer}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            setIsItemImageModalOpen={setIsItemImageModalOpen}
            setUpdatedItemImage={setUpdatedItemImage}
          />
        )}
      </div>
    </div>
  )
}

export default WeeklyAdMediaUnit
