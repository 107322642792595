import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getCategoryLabels } from '../util/helpers'

import CategorySelect from './CategorySelect'

import '../../planning/scss/InlineEditInput.scss'

const ContentDetailCategory = ({
  categoriesSelected,
  setCategoriesSelected,
  categoryOptions,
  disabled,
  setChangeDetected,
  setEditCategories,
  editCategories,
  mediaUnitDataId,
}) => (
  <Grid.Container className="category-container-row">
    <Grid.Item className="category-label" xs={2}>
      Categories{' '}
      <span
        onClick={() => {
          setEditCategories(!editCategories)
        }}
        data-testid="edit-media-unit-categories"
        className="media-unit-market-edit"
      >
        <FontAwesomeIcon
          icon={faEdit}
          color={editCategories ? '#cc0000' : '#000000'}
        />
      </span>
    </Grid.Item>
    <Grid.Item className="category-input" xs={10}>
      {editCategories ? (
        <CategorySelect
          categoriesSelected={categoriesSelected}
          setCategoriesSelected={setCategoriesSelected}
          categoryOptions={categoryOptions}
          disabled={disabled}
          setChangeDetected={setChangeDetected}
        />
      ) : (
        <Form.Field
          id={mediaUnitDataId + '-markets'}
          data-testid="category-input-read-only"
          value={
            getCategoryLabels(categoriesSelected, categoryOptions)
              ?.map((cat) => cat.label)
              .join(', ') || ''
          }
          disabled={true}
        />
      )}
    </Grid.Item>
  </Grid.Container>
)

export default ContentDetailCategory
