import {
  Button,
  Card,
  Tabs,
  Input,
  ProgressBar,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CancelIcon } from '@enterprise-ui/icons'
import { useState, useContext, useEffect } from 'react'

import {
  CanEditContext,
  WeeklyAdAutomationContext,
} from '../../context/Context'

import Captains from './Captains'
import Details from './Details'
import WACreativeSummary from './WACreativeSummary'
import './scss/AdditionalInfoTabs.scss'
import WeeklyAdMediaUnitList from './WeeklyAdMediaUnitList'

const AdditionalInfoTabs = ({
  pageInfo: {
    page_id: pageId,
    type,
    displayNumber,
    page_sequence: pageSequence,
  } = {},
}) => {
  const [activeTab, setActiveTab] = useState('details')
  const {
    details,
    setDetails,
    getPageDetails,
    updatePageDetails,
    isAdditionalInfoLoading,
    setCurrentPageInfo,
  } = useContext(WeeklyAdAutomationContext)
  const [initialPageTitle, setInitialPageTitle] = useState(details?.page_title)
  const { canEdit } = useContext(CanEditContext)

  useEffect(() => {
    getPageDetails(pageId)
    setActiveTab('details')
    setInitialPageTitle(details?.page_title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId])

  const handlePageTitleChange = (e) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      page_title: e.target.value,
    }))
  }

  const handlePageTitleBlur = () => {
    if (details?.page_title !== initialPageTitle) {
      const payload = { custom_page_title: details?.page_title }
      updatePageDetails(pageId, payload)
      setInitialPageTitle(details?.page_title)
    }
  }

  return (
    <>
      <Card className="additionalInfo">
        <div
          className="infoHeader"
          style={{
            alignItems: 'center',
            backgroundColor: '#F2F3F3',
            display: 'flex',
            height: 50,
            justifyContent: 'space-between',
          }}
        >
          <div className={`editable-input-container`}>
            <label className="page-title-label">{`PAGE ${displayNumber} -`}</label>{' '}
            <Input.Text
              id={'editable-page-title'}
              disabled={!canEdit || isAdditionalInfoLoading}
              value={details?.page_title || ''}
              onChange={handlePageTitleChange}
              onBlur={handlePageTitleBlur}
            />
          </div>
          <div style={{ padding: 15 }}>
            <Button
              iconOnly
              type="ghost"
              aria-label="Close Additional Info View"
              onClick={(e) => setCurrentPageInfo({})}
            >
              <EnterpriseIcon size="md" icon={CancelIcon} />
            </Button>
          </div>
        </div>
        <div className="tabs-container">
          <Tabs
            className="details-tabs"
            defaultTab={'details'}
            activeTab={activeTab}
            onTabSelect={(e, tab) => {
              setActiveTab(tab?.name)
            }}
            justified
            disableAdaptive
          >
            <Tabs.Item name="details">DETAILS</Tabs.Item>
            <Tabs.Item name="captains">CAPTAINS</Tabs.Item>
            <Tabs.Item name="mediaunits">MEDIA UNITS</Tabs.Item>
            <Tabs.Item name="summary">SUMMARY</Tabs.Item>
          </Tabs>
          {isAdditionalInfoLoading && <ProgressBar indeterminate />}
          {activeTab === 'details' && (
            <Details canEdit={canEdit} pageId={pageId} type={type} />
          )}
          {activeTab === 'captains' && (
            <Captains canEdit={canEdit} pageId={pageId} />
          )}
          {activeTab === 'summary' && <WACreativeSummary />}
          {activeTab === 'mediaunits' && (
            <WeeklyAdMediaUnitList
              pageId={pageId}
              pageSequence={pageSequence}
            />
          )}
        </div>
      </Card>
    </>
  )
}

export default AdditionalInfoTabs
