const commonConfig = {
  adGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-MARKETING',
    'APP-OAUTH2-CRTVHUB-MERCH',
    'APP-OAUTH2-CRTVHUB-READONLY',
    'APP-OAUTH2-CRTVHUB-GROUPOFFERS',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  adminGroups: ['APP-OAUTH2-CRTVHUB-ADMIN'],
  backend: {
    catalogs: {
      catalog: '',
      distinctVendors: 'distinctVendors/',
      exportCatalog: 'exports/pagination/',
      history: 'history?',
      lastPlaceholdDpci: 'products/lastplaceholdDpci/',
      page: 'pages/',
      product: 'products/',
      vendor: 'vendors/',
    },
    comment: 'comments/',
    cut: 'cuts/',
    cutPatch: 'cutPatches/',
    defaultContacts: 'default-contacts',
    defaultContactsPage: 'contacts',
    dpciSearch: 'dpciSearch',
    dummy: 'dummies/',
    dwa: 'dwa/',
    dwaOffer: 'dwaOffer',
    export: 'export/',
    exportPage: 'export/page/',
    item: 'items/',
    itemPatch: 'itemPatches/',
    lateItems: 'lateItems/',
    moveCut: 'moveCut/',
    offer: 'offers/',
    page: 'pages/',
    pagination: 'pagination/',
    props: 'props/',
    samples: 'samples/',
    spawnArtPatches: 'spawnArtPatches/',
    story: 'stories/',
    system: 'system/',
    weeklyAd: 'wads/',
    weeklyAdItemHistory: 'change_history',
    whitebox: 'whitebox/',
  },
  bcmGroup: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
  ],
  catAmpViewGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-MARKETING',
    'APP-OAUTH2-CRTVHUB-MERCH',
    'APP-OAUTH2-CRTVHUB-READONLY',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  catalogCaptain: ['APP-OAUTH2-CRTVHUB-ADMIN', 'APP-OAUTH2-CRTVHUB-PROJMGR'],
  creativeGroups: [
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-ADMIN',
  ],
  dummyDivisions: [12, 13, 14, 17],
  editCatAmpGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-MARKETING',
    'APP-OAUTH2-CRTVHUB-MERCH',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  editCatalogPage: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
  ],
  editChangeSummaryGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-MARKETING',
  ],
  editExecutionViewGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-MARKETING',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  editImageTrackingGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
  ],
  editPastCatalogsGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
  ],
  editPlanningChannelGridGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-MARKETING',
  ],
  editPlanningViewGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-MARKETING',
  ],
  editSystemAndProjectContacts: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-MARKETING',
  ],
  editUpcomingCatalogsGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-MERCH',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  editWeeklyAdAutomation: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-MARKETING',
  ],
  editWeeklyAdsPast: ['APP-OAUTH2-CRTVHUB-ADMIN', 'APP-OAUTH2-CRTVHUB-PROJMGR'],
  editWeeklyAdsUpcoming: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-MARKETING',
    'APP-OAUTH2-CRTVHUB-MERCH',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  executionViewGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-MARKETING',
    'APP-OAUTH2-CRTVHUB-MERCH',
    'APP-OAUTH2-CRTVHUB-GROUPOFFERS',
    'APP-OAUTH2-CRTVHUB-READONLY',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  groupedOfferAllowedGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-GROUPOFFERS',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-PAO-WRITE-PROD',
    'APP-PAO-WRITE-NON-PROD',
  ],
  groupedOfferOnlyGroups: [
    'APP-OAUTH2-CRTVHUB-GROUPOFFERS',
    'APP-PAO-WRITE-PROD',
    'APP-PAO-WRITE-NON-PROD',
  ],
  lateLinkUnlinkUmbrellaDays: 56,
  marketingGroups: [
    'APP-OAUTH2-CRTVHUB-MARKETING',
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
  ],
  merchGroups: [
    'APP-OAUTH2-CRTVHUB-MERCH',
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  planningViewGroups: [
    'APP-OAUTH2-CRTVHUB-ADMIN',
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-MARKETING',
    'APP-OAUTH2-CRTVHUB-MERCH',
    'APP-OAUTH2-CRTVHUB-READONLY',
    'APP-OAUTH2-CRTVHUB-GROUPOFFERS',
    'APP-OAUTH2-CRTVHUB-PROMOOPS',
  ],
  readGroups: ['APP-OAUTH2-CRTVHUB-READONLY', 'APP-OAUTH2-CRTVHUB-ADMIN'],
  samplesGroups: ['APP-OAUTH2-CRTVHUB-ADMIN', 'APP-OAUTH2-CRTVHUB-PROJMGR'],
  vendorGroup: ['APP-POL-USERS', 'APP-CTLG'],
  vendorPlusGroup: ['APP-POL-USERS', 'APP-OAUTH2-CRTVHUB-ADMIN', 'APP-CTLG'],
  weeklyAdOnlyGroup: [
    'APP-OAUTH2-CRTVHUB-BCM',
    'APP-OAUTH2-CRTVHUB-COPYWRITER',
    'APP-OAUTH2-CRTVHUB-CREATIVEART',
    'APP-OAUTH2-CRTVHUB-CREATIVEPROD',
    'APP-OAUTH2-CRTVHUB-PROJMGR',
    'APP-OAUTH2-CRTVHUB-MARKETING',
  ],
}
export default commonConfig
