import {
  Button,
  Form,
  GridContainer,
  Grid,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import {
  faPlus,
  faMinus,
  faKey,
  faExclamationTriangle,
  faClipboard,
  faTimesCircle,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import OfferApi from '../../api/creative-hub/OfferApi'
import appConfig from '../../config/appConfig'
import { IvyChannels } from '../../creativeHubGlobal/util/CreativeHubGlobalUtil'
import {
  getOnlineOnlyOffers,
  checkForAllMarkets,
  isOfferAddedAfterProjectKODate,
} from '../../creativeHubGlobal/util/OfferUtil'
import ExpandableOfferComment from '../../execution/components/ExpandableOfferComment'
import { fetchAllPicturedItems } from '../../execution/util/ItemUtil'
import InlineEditInput from '../../planning/components/InlineEditInput'
import { formatToUi } from '../../util/MomentUtil'

import '../../execution/scss/Offer.scss'

const Offer = (props) => {
  const {
    weeklyAdPage = false,
    canRemoveOffer,
    canEdit,
    offer = {},
    umbrella,
    storyId,
    outcomePriorityId,
    setSelectedOffer,
    isFollower,
    removeOffer,
    setIsOfferDrawerOpen,
    selectedProject,
  } = props
  const {
    ad_patches = [],
    mixed_discount = false,
    copy = '',
    created_date,
    deleted = false,
    invalid_dates = false,
    ivy_channel = '',
    ivy_offer_id = -1,
    merch_notes = '',
    name = '',
    project_id = '',
    offer_order,
    key = false,
    sale_handle = '',
    status,
    ab_email = '',
    promo_content = '',
    discount_type = '',
    markets = [],
    price_patches = [],
    variable_profitability = {},
    offer_items_count = 0,
    deleted_from_page = false,
  } = offer

  const itemCount = fetchAllPicturedItems(offer).length

  //  query params used when user landing through email links
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const canExpandOffer = query.get('canExpandOffer')

  const config = appConfig()
  const isPlaceholderOffer = ivy_offer_id === -1
  const isDeactivated = status === 'deactivated' || deleted || deleted_from_page
  const [isOfferExpanded, setIsOfferExpanded] = useState(canExpandOffer)
  const [saleHandleDropdown, setSaleHandleDropdown] = useState(sale_handle)
  const [offerCopy, setOfferCopy] = useState(copy)
  const [offerMerchNotes, setOfferMerchNotes] = useState(merch_notes)
  const [toggleExpand, setToggleExpand] = useState(false)

  const handleCopyChange = (input) => setOfferCopy(input)
  const handleMerchNotesChange = (input) => setOfferMerchNotes(input)

  const offerApi = useMemo(() => new OfferApi(), [])

  const ivyChannelDisplay = IvyChannels[ivy_channel]

  const showPicturedItems = (offer) => {
    setSelectedOffer([offer])
    setIsOfferDrawerOpen(true)
  }

  const updateValue = async (data = {}, field, value) => {
    if (data[field] !== value) {
      data[field] = value
      //update data
      try {
        const resp = await offerApi.updateOffers([data])
        return resp
      } catch (error) {
        console.log(error)
        return null
      }
    }
  }

  return (
    // <ExpandableSection
    //   className="media-unit-offer"
    //   padding="none"
    //   onClick={() => {
    //     setRecentlyClickedOffer(offer?.id)
    //   }}
    //   startExpanded={recentlyClickedOffer === offer.id}
    // ><div>
    <div className="media-unit-offer">
      <GridContainer
        data-testid="offer"
        spacing="none"
        align="center"
        justify="space-around"
        xs={12}
      >
        <Grid.Item
          align="left"
          className={`headerRow${
            isDeactivated ? ' deactivated-offer-header' : ''
          } ${deleted ? 'invalidDates' : ''}`}
          data-cy={`offer${offer_order}`}
          xs={2}
          onClick={(e) => {
            setToggleExpand(!toggleExpand)
          }}
          data-testid="expandButton"
        >
          <FontAwesomeIcon
            icon={toggleExpand ? faChevronDown : faChevronRight}
            size="sm"
            className="media-unit-offer-expand-icon"
          />
          {isPlaceholderOffer ? (
            <Tooltip content="Placeholder Offer" location="bottom">
              <FontAwesomeIcon
                icon={faClipboard}
                className="fa-duotone key"
                size="lg"
                color="#aaaaaa"
              />
            </Tooltip>
          ) : invalid_dates ? (
            <Tooltip
              content="This offer's run dates were changed to a different week.  Please check Ivy for more details"
              location="bottom"
              className="invalidDatesPublishing"
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="fa-duotone key"
                size="lg"
                color="#aaaaaa"
              />
            </Tooltip>
          ) : deleted ? (
            <Tooltip
              content="This offer was removed from this umbrella.  Please check Ivy for more details"
              location="bottom"
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="fa-duotone key"
                size="lg"
                color="#aaaaaa"
              />
            </Tooltip>
          ) : deleted_from_page ? (
            <Tooltip
              content="This offer was removed from this Page"
              location="bottom"
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="fa-duotone key"
                size="lg"
                color="#aaaaaa"
              />
            </Tooltip>
          ) : isDeactivated ? (
            'Deactivated'
          ) : isFollower ? (
            ''
          ) : (
            `${ivy_offer_id}`
          )}
        </Grid.Item>
        <Grid.Item
          xs={6}
          align="left"
          className={`headerRow${
            isDeactivated ? ' deactivated-offer-header' : ''
          }`}
        >
          <div className="offerName">
            <a
              target="_blank"
              href={`${config.ivy.uiUrl}promotion/${ivy_offer_id}/edit/general`}
              rel="noreferrer"
              draggable={false}
              className={`${
                isDeactivated ? 'deactivated-offer-header' : 'offerLink'
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {key ? (
                <FontAwesomeIcon
                  icon={faKey}
                  className="fa-duotone key"
                  size="lg"
                  color="black"
                />
              ) : null}
              {deleted && !invalid_dates ? (
                ivy_offer_id
              ) : invalid_dates ? (
                <span>
                  <span className={invalid_dates ? 'invalidDates' : null}>
                    {name}
                  </span>{' '}
                  <span className="invalidDatesText">INVALID DATES</span>
                </span>
              ) : isOfferAddedAfterProjectKODate(
                  selectedProject?.offers_due_date,
                  created_date
                ) ? (
                <span
                  title={`Added to CH ${formatToUi(created_date)}`}
                  className="late_offer_background"
                >
                  {name}
                </span>
              ) : (
                name
              )}
            </a>
          </div>
        </Grid.Item>
        <Grid.Item xs={3}>
          <GridContainer spacing="none" align="left" justify="right" xs={12}>
            <Grid.Item align="left" className="headerRow headerButtonText">
              {itemCount > 0 ? (
                <Button
                  size="dense"
                  onClick={() => showPicturedItems(offer)}
                  className={`${
                    isDeactivated
                      ? ' deactivated-offer'
                      : ad_patches && ad_patches.length > 1
                        ? ' adPatch-offer'
                        : !mixed_discount &&
                            price_patches &&
                            price_patches.length > 1
                          ? ' pricePatch-offer'
                          : ''
                  }`}
                >
                  <p
                    className={`${
                      isDeactivated
                        ? 'deactivated-offer-header'
                        : 'headerButtonText'
                    }`}
                  >
                    {ad_patches && ad_patches.length > 1
                      ? `Patches (${itemCount})`
                      : `Featured (${itemCount})`}
                  </p>
                </Button>
              ) : null}
            </Grid.Item>
            <Grid.Item align="left" className="headerRow headerButtonText">
              {offer_items_count > 0 && ad_patches.length > 0 ? (
                <p
                  className={`ivyTotalItems${
                    isDeactivated ? ' deactivated-offer-header' : ''
                  }`}
                >
                  Total ({offer_items_count})
                </p>
              ) : null}
            </Grid.Item>
          </GridContainer>
        </Grid.Item>
        <Grid.Item xs={1} align="center" className="headerRow">
          {canRemoveOffer && (
            <FontAwesomeIcon
              data-testid={'remove-offer-btn'}
              icon={faTimesCircle}
              onClick={(e) => {
                removeOffer(e, offer.id)
              }}
            />
          )}
        </Grid.Item>
      </GridContainer>
      {toggleExpand && (
        <Grid.Container xs={12} className="offer">
          <Grid.Item className="hc-bg-grey05" xs={2}>
            <h4>
              Sale Handle{' '}
              {canEdit ? (
                <span class="C-FormField__Label__Required">*</span>
              ) : (
                <span></span>
              )}
            </h4>
            {canEdit ? (
              <span class="C-FormField__Label__saleHandleRequired">
                Required Field
              </span>
            ) : (
              <span></span>
            )}
          </Grid.Item>
          <Grid.Item className="hc-bg-contrast-weak06" xs={2}>
            <Form.Field
              id="saleHandle"
              type="select"
              disabled={!canEdit}
              value={saleHandleDropdown}
              options={[
                {
                  label: '',
                  value: '',
                },
                {
                  label: 'Yes',
                  value: 'Yes',
                },
                {
                  label: 'No',
                  value: 'No',
                },
              ]}
              onUpdate={(id, value) => {
                setSaleHandleDropdown(value)
                updateValue(offer, id, value)
              }}
            />
          </Grid.Item>
          <Grid.Item className="hc-bg-grey05" xs={2}>
            <h4>Promo $</h4>
          </Grid.Item>
          <Grid.Item className="hc-bg-contrast-weak06" xs={2}>
            {mixed_discount
              ? 'See featured items for promo $ values'
              : promo_content}
          </Grid.Item>
          <Grid.Item className="hc-bg-grey05" xs={2}>
            <h4>Discount Type</h4>
          </Grid.Item>
          <Grid.Item className="hc-bg-contrast-weak06" xs={2}>
            {discount_type}
          </Grid.Item>
          <Grid.Item className="hc-bg-grey05" xs={2}>
            <h4>Copy Direction</h4>
          </Grid.Item>
          <Grid.Item className="hc-bg-contrast-weak06" xs={10}>
            {weeklyAdPage ? (
              <Form.Field value={offerCopy || ''} disabled />
            ) : (
              <InlineEditInput
                data={offer}
                field="copy"
                value={offerCopy}
                handleCopyChange={handleCopyChange}
                multiline={false}
                placeholder=""
                data-testid="copyField"
                isDeactivated={isDeactivated || !canEdit}
              />
            )}
          </Grid.Item>
          <Grid.Item className="hc-bg-grey05" xs={2}>
            <h4>Offer Notes</h4>
          </Grid.Item>
          <Grid.Item className="hc-bg-contrast-weak06" xs={10}>
            {weeklyAdPage ? (
              <Form.Field value={offerMerchNotes || ''} disabled />
            ) : (
              <InlineEditInput
                data={offer}
                field="merchNotes"
                value={offerMerchNotes || ''}
                handleMerchNotesChange={handleMerchNotesChange}
                multiline={false}
                placeholder=""
                isDeactivated={isDeactivated || !canEdit}
              />
            )}
          </Grid.Item>

          <Grid.Item className="hc-bg-contrast-weak06 offerExpand" xs={12}>
            <Button
              xs={2}
              className="expandButton"
              onClick={() => setIsOfferExpanded(!isOfferExpanded)}
              data-testid="expandButton"
            >
              {isOfferExpanded ? (
                <>
                  <FontAwesomeIcon icon={faMinus} />
                  <span>Show Less</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Show More</span>
                </>
              )}
            </Button>
          </Grid.Item>
          {isOfferExpanded ? (
            <>
              <Grid.Item className="hc-bg-grey05" xs={3}>
                <h4>Comments</h4>
              </Grid.Item>
              <Grid.Item className="hc-bg-contrast-weak06" xs={9}>
                <ExpandableOfferComment
                  offer={offer}
                  projectId={project_id}
                  umbrella={umbrella}
                  outcomePriorityId={outcomePriorityId}
                  storyId={storyId}
                  canEdit={canEdit}
                  canExpandOffer={canExpandOffer}
                />
              </Grid.Item>
              <Grid.Item className="hc-bg-grey05" xs={3}>
                <h4>Offer Id</h4>
              </Grid.Item>
              <Grid.Item className="hc-bg-contrast-weak06" xs={3}>
                {isPlaceholderOffer ? 'N/A' : ivy_offer_id}
              </Grid.Item>
              <Grid.Item className="hc-bg-grey05" xs={3}>
                <h4>Offer Status</h4>
              </Grid.Item>
              <Grid.Item className="hc-bg-contrast-weak06" xs={3}>
                {status}
              </Grid.Item>
              <Grid.Item className="hc-bg-grey05" xs={3}>
                <h4>AB Email</h4>
              </Grid.Item>
              <Grid.Item className="hc-bg-contrast-weak06" xs={9}>
                {ab_email}
              </Grid.Item>
              <Grid.Item className="hc-bg-grey05" xs={3}>
                <h4>Markets</h4>
              </Grid.Item>
              <Grid.Item className="hc-bg-contrast-weak06" xs={9}>
                {checkForAllMarkets(markets)}
              </Grid.Item>
              <Grid.Item className="hc-bg-grey05" xs={3}>
                <h4>Selling Channel</h4>
              </Grid.Item>
              <Grid.Item className="hc-bg-contrast-weak06" xs={9}>
                {ivyChannelDisplay}
              </Grid.Item>
              <Grid.Item className="hc-bg-grey05" xs={3}>
                <h4>Online-Only Offers</h4>
              </Grid.Item>
              <Grid.Item className="hc-bg-contrast-weak06" xs={9}>
                {getOnlineOnlyOffers(variable_profitability)}
              </Grid.Item>
              {!mixed_discount && price_patches.length > 0 ? (
                <>
                  <Grid.Item
                    className="hc-bg-grey05 pricePatch"
                    xs={12}
                    data-testid="price-patch-header"
                  >
                    <h4>Price Patching</h4>
                  </Grid.Item>
                  {price_patches.map((patch, index) => (
                    <GridContainer
                      data-testid="offer"
                      spacing="none"
                      align="center"
                      xs={12}
                      key={'patch-' + index}
                    >
                      <Grid.Item className="hc-bg-grey05" xs={3}>
                        <h4>Price</h4>
                      </Grid.Item>
                      <Grid.Item className="hc-bg-white06" xs={3}>
                        {patch.price_patch}
                      </Grid.Item>
                      <Grid.Item className="hc-bg-grey05" xs={3}>
                        <h4>Markets</h4>
                      </Grid.Item>
                      <Grid.Item className="hc-bg-white06" xs={3}>
                        {patch.price_markets.join(', ')}
                      </Grid.Item>
                    </GridContainer>
                  ))}
                </>
              ) : null}
            </>
          ) : null}
        </Grid.Container>
      )}
    </div>
  )
}

export default Offer
