import { Grid, Input } from '@enterprise-ui/canvas-ui-react'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalDrawer } from 'nicollet-react'
import { useContext, useState, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import ChannelApi from '../api/creative-hub/ChannelApi'
import OfferApi from '../api/creative-hub/OfferApi'
import ProjectApi from '../api/creative-hub/ProjectApi'
import PublishingApi from '../api/creative-hub/PublishingApi'
import UmbrellaApi from '../api/creative-hub/UmbrellaApi'
import PlatformApi from '../api/platform-notifications/EmailVerification'
import './scss/PublishingDetails.scss'
import {
  CanEditContext,
  EffectiveRoleContext,
  EnterpriseLinkedUmbrellasContext,
} from '../context/Context'
import OffersBasicBucket from '../creativeHubGlobal/components/OffersBasicBucket'
import StickySelectAndFilterPublishing from '../creativeHubGlobal/components/StickySelectAndFilterPublishing'
import AssetHubExecutionModal from '../execution/components/AssetHubExecutionModal'
import { formatContacts } from '../planning/util/CreateEditProjectUtil'
import CategorySelect from '../publishing/components/CategorySelect'
import { canEditCatAmp } from '../util/CheckRole'

import CategorySection from './components/CategorySection'
import PublishItemsBucket from './components/PublishItemsBucket'
import { convertCategoryOptions } from './util/helpers'

const PriorityOffers = ({ session = {} }) => {
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [toggleUmbrellas, setToggleUmbrellas] = useState(true)
  const [categoryOptions, setCategoryOptions] = useState([])
  const api = useMemo(() => new PublishingApi(), [])
  const viewName = 'Priority Offers'

  const platformApi = useMemo(() => new PlatformApi(), [])
  const [selectedProject, setSelectedProject] = useState(useLocation().state)
  const [selectedProjectId] = useState(useParams().projectId)

  const [selectedMarketingChannelFilter, setMarketingChannelFilter] = useState(
    []
  )
  const [selectedMarketingPriorityFilter, setMarketingPriorityFilter] =
    useState('')
  const [selectedMarketingPyramidFilter, setMarketingPyramidFilter] =
    useState('')

  const [selectedOffer, setSelectedOffer] = useState(null)
  const [umbrellasWithOffers, setUmbrellasWithOffers] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [isOfferDrawerOpen, setIsOfferDrawerOpen] = useState(false)
  const [isItemImageModalOpen, setIsItemImageModalOpen] = useState(false)
  const [itemSelected, setItemSelected] = useState()
  const [updatedItemImage, setUpdatedItemImage] = useState()
  const [linkedUmbrellas, setLinkedUmbrellas] = useState()

  const [canEdit, setCanEdit] = useState(false)
  const { effectiveRole } = useContext(EffectiveRoleContext)
  const [channels, setChannels] = useState([])
  const channelApi = useMemo(() => new ChannelApi(), [])
  const projectApi = useMemo(() => new ProjectApi(), [])
  const umbrellaApi = new UmbrellaApi()
  const offerApi = useMemo(() => new OfferApi(), [])
  const [pastProject, setPastProject] = useState(false)

  const getCategoryOptions = async () => {
    try {
      // eslint-disable-next-line prettier/prettier
      const categoryFilters = await api.getCategoriesByProjectId(
        selectedProjectId
      )
      setCategoryOptions(
        convertCategoryOptions(categoryFilters, 'is_priority_view')
      )
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  const priorityOffersEntitlements = [
    'APP-OAUTH2-CrtvHub-CreativeArt',
    'APP-OAUTH2-CrtvHub-Copywriter',
    'APP-OAUTH2-CrtvHub-CreativeProd',
    'APP-OAUTH2-CrtvHub-Marketing',
    'APP-OAUTH2-CrtvHub-BCM',
    'APP-OAUTH2-CrtvHub-PROJMGR',
  ]

  const [contactsByEntitlement, setContactsByEntitlement] = useState()
  const entitlements = []
  const contactOptions = formatContacts(contactsByEntitlement)

  useEffect(() => {
    async function fetchData() {
      for (let i = 0; i < priorityOffersEntitlements.length; i++) {
        await platformApi
          .getContactsByEntitlement(priorityOffersEntitlements[i])
          .then((data) => {
            entitlements.push(data)
          })
          .catch((e) => console.log('error', e))
      }

      setContactsByEntitlement(entitlements)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCanEdit(effectiveRole?.some((role) => canEditCatAmp(role)))
  }, [effectiveRole, setCanEdit])

  useEffect(() => {
    channelApi.getChannels().then((response) => setChannels(response))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      selectedProject?.project_id !== null &&
      selectedProject?.project_id !== undefined
    ) {
      getCategoryOptions()
      setSearchInput('')
      offerApi
        .getOffersByProjectAndChannel(selectedProjectId, 6)
        .then((data) => setUmbrellasWithOffers(data))

      umbrellaApi
        .getEnterpriseUmbrellas(
          selectedProjectId,
          selectedProject?.start_date,
          selectedProject?.end_date
        )
        .then((response) => {
          setLinkedUmbrellas(response)
        })
      channelApi.getChannels().then((response) => setChannels(response))
    } else if (selectedProjectId) {
      setCategoryOptions([])
      projectApi.fetchProjectDetails(selectedProjectId).then((data) => {
        if (data.length > 0) setSelectedProject(data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId, selectedProject])

  const handleSearch = (e) => {
    e.preventDefault()
    setSearchInput(e.target.value)
  }

  return (
    <div data-testid="PriorityOffers" className="publishingPage">
      <div className="publishing-note">
        <strong>About this view</strong>: Weekly Planning Space where E&A and
        Creative Teams plan, create and execute Priority Offers to marketing
        channels.
      </div>
      <CanEditContext.Provider value={{ canEdit }}>
        <StickySelectAndFilterPublishing
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          selectedMarketingPyramidFilter={selectedMarketingPyramidFilter}
          selectedMarketingPriorityFilter={selectedMarketingPriorityFilter}
          selectedMarketingChannelFilter={selectedMarketingChannelFilter}
          setMarketingPriorityFilter={setMarketingPriorityFilter}
          setMarketingPyramidFilter={setMarketingPyramidFilter}
          setMarketingChannelFilter={setMarketingChannelFilter}
          channels={channels}
          pastProject={pastProject}
          setPastProject={setPastProject}
          viewName={viewName}
          session={session}
        />
        <ModalDrawer
          isOpen={isOfferDrawerOpen}
          iconButtonCloseOnClick={() =>
            setIsOfferDrawerOpen(!isOfferDrawerOpen)
          }
          scroll={true}
          onRequestClose={() => {
            setIsOfferDrawerOpen(false)
          }}
          headingText={
            selectedOffer &&
            selectedOffer.adPatches &&
            selectedOffer.adPatches.length > 1
              ? 'Art Patches'
              : 'Featured Items'
          }
          sm={12}
          md={6}
          lg={4}
        >
          <PublishItemsBucket
            selectedOffer={selectedOffer}
            setIsItemImageModalOpen={setIsItemImageModalOpen}
            setItemSelected={setItemSelected}
            updatedItemImage={updatedItemImage}
            showPriority={false}
          />
        </ModalDrawer>
        {isItemImageModalOpen && selectedOffer != null && (
          <AssetHubExecutionModal
            isItemImageModalOpen={isItemImageModalOpen}
            selectedOffer={selectedOffer}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            setIsItemImageModalOpen={setIsItemImageModalOpen}
            setUpdatedItemImage={setUpdatedItemImage}
          />
        )}
      </CanEditContext.Provider>
      {selectedProject ? (
        <Grid.Container xs={12} className="category-filter">
          <Grid.Item xs={12} className="projectInfo">
            <span className="project-description">
              {selectedProject?.project_description}{' '}
            </span>{' '}
            <span className="project-name">
              {' '}
              {selectedProject?.project_name}{' '}
            </span>
            <span className="publishProjectDate">
              {' '}
              <DateFormatter
                date={selectedProject?.start_date}
                format="MM/DD/YYYY"
              />
              {' - '}
              <DateFormatter
                date={selectedProject?.end_date}
                format="MM/DD/YYYY"
              />
            </span>
          </Grid.Item>
          <Grid.Item xs={12} className="umbrellaToggle">
            <Input.Toggle
              id="toggleUmbrellas"
              label="View Umbrellas and Offers"
              onChange={(event) => {
                setToggleUmbrellas(!toggleUmbrellas)
              }}
              checked={toggleUmbrellas}
            />
          </Grid.Item>
          <Grid.Item xs={12} className="categorySelect">
            <CategorySelect
              categoriesSelected={categoriesSelected}
              setCategoriesSelected={setCategoriesSelected}
              categoryOptions={categoryOptions}
            />
          </Grid.Item>
        </Grid.Container>
      ) : null}

      <div className="publishingDetails">
        {selectedProject && toggleUmbrellas ? (
          <EnterpriseLinkedUmbrellasContext.Provider
            value={{ linkedUmbrellas }}
          >
            <div className="offerList">
              <div align="left" className="closeUmbrellasDrawer">
                <h3>Umbrellas and Offers</h3>

                <div
                  className="closeDrawerButton"
                  onClick={() => {
                    setToggleUmbrellas(!toggleUmbrellas)
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faTimes} color="#000000" />
                </div>
              </div>
              <input
                id="searchTerm"
                type="text"
                className="search-input"
                placeholder="Search by Title, Ivy Number or Pyramid"
                onChange={(e) => {
                  handleSearch(e)
                }}
              />
              <OffersBasicBucket
                umbrellasWithOffers={umbrellasWithOffers}
                searchInput={searchInput}
                selectedProject={selectedProject}
              />
            </div>
          </EnterpriseLinkedUmbrellasContext.Provider>
        ) : null}
        {selectedProject ? (
          <div className="media-units">
            <CategorySection
              setCategoriesSelected={setCategoriesSelected}
              projectId={selectedProjectId}
              setSelectedOffer={setSelectedOffer}
              setIsOfferDrawerOpen={setIsOfferDrawerOpen}
              selectedProject={selectedProject}
              startDate={selectedProject.start_date}
              endDate={selectedProject.end_date}
              categoriesSelected={categoriesSelected}
              channelOptions={categoryOptions}
              setIsItemImageModalOpen={setIsItemImageModalOpen}
              setItemSelected={setItemSelected}
              updatedItemImage={updatedItemImage}
              canEdit={canEdit}
              contactOptions={contactOptions}
              isPriorityCategory={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default PriorityOffers
