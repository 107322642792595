import { Input, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useContext, useState, useRef, useEffect } from 'react'

import './scss/AdditionalInfoTabs.scss'
import { WeeklyAdAutomationContext } from '../../context/Context'
import noImagePlaceholder from '../../media/NoImagePlaceholder.png'

import EditablePageSummary from './EditablePageSummary'

const Details = ({ canEdit, pageId, type }) => {
  const {
    details,
    setDetails,
    updatePageDetails,
    isAdditionalInfoLoading,
    makeToast,
  } = useContext(WeeklyAdAutomationContext)
  const { page_image_link = '', assethub_link = '' } = details || {}
  const [isImageLoading, setIsImageLoading] = useState(true)
  const initialDetailsRef = useRef(details)
  useEffect(() => {
    if (details !== null) {
      initialDetailsRef.current = details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdditionalInfoLoading])
  const validateUrl = (url) => {
    const urlRegex = /^https:\/\/target.scene7.com.*$/
    return urlRegex.test(url)
  }
  const checkIfImageisLoaded = (url) =>
    new Promise((resolve) => {
      const img = new Image()
      img.src = url

      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
    })

  const handleBlur = (event) => {
    const { id, value } = event.target
    if (value !== initialDetailsRef.current[id]) {
      if (id === 'page_image_link') {
        if (value?.length === 0 || validateUrl(value)) {
          const payload = {
            [id]: value,
          }
          updatePageDetails(pageId, payload)
        } else {
          makeToast({
            message: 'Page Image Link (Scene 7) format is not matching',
            type: 'error',
          })
        }
      } else {
        const payload = {
          [id]: value,
        }
        updatePageDetails(pageId, payload)
      }
    }
  }

  const handleImageError = (e) => {
    setIsImageLoading(true)
    checkIfImageisLoaded(noImagePlaceholder).then((canLoad) => {
      if (canLoad) {
        e.target.src = noImagePlaceholder
      }
      setIsImageLoading(false)
    })
  }
  const handleImageLoad = () => {
    setIsImageLoading(false)
  }
  return (
    <div>
      {!isAdditionalInfoLoading && (
        <>
          <div className="image-container">
            {page_image_link && (
              <>
                {isImageLoading && <Spinner className="image-spinner" />}
                <img
                  className={`image ${isImageLoading ? 'hidden' : ''}`}
                  alt="Scene 7 Img not found"
                  src={page_image_link}
                  onError={handleImageError}
                  onLoad={handleImageLoad}
                />
              </>
            )}
          </div>
          <Grid.Container spacing="none" direction="column">
            <Input.Label htmlFor="assethub_link">AssetHub Link</Input.Label>
            <Input.Text
              id="assethub_link"
              value={assethub_link || ''}
              onChange={(e) => {
                setDetails((prevDetails) => ({
                  ...prevDetails,
                  assethub_link: e.target.value,
                }))
              }}
              disabled={!canEdit}
              onBlur={handleBlur}
            />
            <Input.Label htmlFor="scene7_link">
              Page Image Link (Scene 7)
            </Input.Label>
            <Input.Text
              id="page_image_link"
              value={page_image_link || ''}
              onChange={(e) => {
                setDetails((prevDetails) => ({
                  ...prevDetails,
                  page_image_link: e.target.value,
                }))
                setIsImageLoading(true)
              }}
              onBlur={handleBlur}
              disabled={!canEdit}
            />
          </Grid.Container>
          <label className="C-Input--type-label">Page Offer List</label>
          <EditablePageSummary pageId={pageId} />
        </>
      )}
    </div>
  )
}
export default Details
