import { Modal, Form } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import Draggable from 'react-draggable'

import CancelSubmitButtons from '../../../components/CancelSubmitButtons'
import { extractEmailAddresses } from '../../../components/util/emailUtil'

import { CommentField } from './CommentItem'

export default function AddComment({ handleDialogClose, onAddComment }) {
  const [comment, setComment] = useState('')

  const onSubmit = () => {
    const sendEmailList = extractEmailAddresses(comment)
    onAddComment(comment, sendEmailList)
    handleDialogClose()
  }

  const handleSetComment = (e) => {
    setComment(e.target.value)
  }

  const handleMouseDown = (e) => {
    e.stopPropagation()
  }

  return (
    <>
      <div onMouseDown={handleMouseDown} />
      <Draggable>
        <Modal
          isVisible={true}
          headingText="Add Comment"
          onRefuse={handleDialogClose}
        >
          <div className="hc-pa-normal hc-pl-lg">
            <CommentField
              value={comment}
              handleSetComment={handleSetComment}
              setValue={setComment}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                handleCommentChange,
                handleBlur,
                values,
                errors,
              }) => (
                <>
                  <Form.Field
                    id="comment"
                    type="textarea"
                    handleBlur={handleBlur}
                    onChange={handleCommentChange}
                    value={values.comment}
                    error={errors?.comment}
                    errorText={errors?.comment}
                    autoFocus={true}
                    onMouseDown={(e) => e.stopPropagation()}
                  />

                  <div style={{ color: 'red' }}>{errors.comment}</div>
                  <CancelSubmitButtons
                    canDelete={false}
                    handleDialogClose={handleDialogClose}
                    handleSubmit={handleSubmit}
                    submitDisabled={errors?.comment}
                  />
                </>
              )}
            </CommentField>
          </div>
        </Modal>
      </Draggable>
    </>
  )
}
