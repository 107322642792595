import { Button, Card } from '@enterprise-ui/canvas-ui-react'
import classnames from 'classnames'

const OptionsList = ({
  alignOptionsList,
  id,
  'data-testid': dataTestId,
  optionSize,
  options,
  selectedOption,
  isShowingOptions,
  onOptionClick,
  optionsListNoMinWidth,
  renderOption,
}) => {
  const cardClasses = classnames(
    'C-Autocomplete__Options',
    { [`--align-${alignOptionsList}`]: alignOptionsList },
    { [`--min-width-none`]: optionsListNoMinWidth },
    { isVisible: isShowingOptions }
  )

  return (
    <Card
      data-testid={dataTestId ? `${dataTestId}_optionsList` : null}
      className={cardClasses}
      elevation={2}
    >
      <ul
        data-test="optionsList"
        className="C-Autocomplete__List"
        role="listbox"
        id={`ariaControls_${id}`}
      >
        {options.map((option, index) => (
          <li key={typeof option.id !== 'undefined' ? option.id : index}>
            <Button
              role="option"
              className={classnames('--embeddedInComponent', {
                isHover: selectedOption
                  ? selectedOption.id === option.id
                  : false,
              })}
              // type="primary"
              id={option.id}
              data-testid={dataTestId ? `${dataTestId}_option_0${index}` : null}
              // dense={optionSize === 'dense'}
              // expanded={optionSize === 'expanded'}
              data-test={`${id}___option_#${index}`}
              disabled={option.disabled}
              onClick={onOptionClick(option)}
            >
              {renderOption(option)}
            </Button>
          </li>
        ))}
      </ul>
    </Card>
  )
}

OptionsList.displayName = 'OptionsList'
export default OptionsList
