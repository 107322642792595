import { Card, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useRef, useState } from 'react'

import {
  CanEditContext,
  WeeklyAdAutomationContext,
} from '../../context/Context'

import WeeklyAdMediaUnit from './WeeklyAdMediaUnit'
import '../../publishing/scss/Category.scss'
const WeeklyAdMediaUnitList = ({ pageId, pageSequence }) => {
  const [isDraggable, setIsDraggable] = useState(true)
  const draggingItem = useRef()
  const dragOverItem = useRef()
  const {
    createMediaUnit,
    getMediaUnits,
    mediaUnits,
    isAdditionalInfoLoading,
    reorderMediaUnits,
  } = useContext(WeeklyAdAutomationContext)
  const { canEdit } = useContext(CanEditContext)
  useEffect(() => {
    getMediaUnits(pageId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const createNewMediaUnit = () => {
    const channel =
      pageSequence === 1
        ? { channel_id: 3, state: 'unassigned' }
        : { channel_id: 4, state: 'unassigned' }
    createMediaUnit(pageId, channel)
  }
  const dragEnter = (event) => {
    event.dataTransfer.types.includes('media-unit') && event.preventDefault()
  }

  const dragOver = (event, index) => {
    event.dataTransfer.types.includes('media-unit') && event.preventDefault()
  }

  const handleDragStart = (event, position) => {
    event.dataTransfer.setData('media-unit', true)
    draggingItem.current = position
  }
  const handleMediaUnitDrop = (event, position) => {
    if (event.dataTransfer.types.includes('media-unit')) {
      dragOverItem.current = position
      const categoryDataCopy = [...mediaUnits]
      const draggingItemContent = categoryDataCopy[draggingItem.current]
      if (
        categoryDataCopy[dragOverItem.current] &&
        categoryDataCopy[draggingItem.current] &&
        draggingItem.current !== dragOverItem.current
      ) {
        categoryDataCopy.splice(draggingItem.current, 1)
        categoryDataCopy.splice(dragOverItem.current, 0, draggingItemContent)
        let payload = { updated_order: position + 1 }
        reorderMediaUnits(
          pageId,
          categoryDataCopy[dragOverItem.current]?.id,
          payload
        )
      }
    }
  }
  return (
    <div className="visible-bucket category-expanded">
      <div className="right-align sticky-header">
        {canEdit && (
          <Tooltip content={`Add Media Unit`} location="left">
            <FontAwesomeIcon
              className={
                isAdditionalInfoLoading
                  ? 'wa-mu-fa-disabled'
                  : 'wa-mu-category-icon'
              }
              icon={faPlusCircle}
              onClick={createNewMediaUnit}
              data-testid="add-media-unit"
              data-cy={`addBucket`}
            />
          </Tooltip>
        )}
      </div>
      {!isAdditionalInfoLoading &&
        mediaUnits?.map((mediaUnit = {}, index) => (
          <Card
            onDragStart={(event) => {
              handleDragStart(event, index)
            }}
            onDragEnter={(event) => dragEnter(event)}
            onDragOver={(event) => dragOver(event, index)}
            onDrop={(event) => handleMediaUnitDrop(event, index)}
            style={{ background: 'none' }}
            data-testid={'mediaUnit-'}
            draggable={canEdit && isDraggable}
            key={`mediaUnit- ${mediaUnit?.id}`}
            className="media-unit-card"
          >
            <WeeklyAdMediaUnit
              key={mediaUnit.id}
              mediaUnit={mediaUnit}
              index={index}
              pageId={pageId}
              setIsDraggable={setIsDraggable}
            />
          </Card>
        ))}
    </div>
  )
}

export default WeeklyAdMediaUnitList
