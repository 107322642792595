import { createContext } from 'react'

export const BreadcrumbContext = createContext({})
export const EffectiveRoleContext = createContext({})
export const CanEditContext = createContext(true)
export const UmbrellaContext = createContext({})
export const EnterpriseLinkedUmbrellasContext = createContext({})
export const MerchantViewContext = createContext({})
export const WeeklyAdAutomationContext = createContext({})
export const AppContext = createContext({})
