import { useState, useEffect, useMemo } from 'react'

import ProjectApi from '../api/creative-hub/ProjectApi'
import {
  handleFireflyInit,
  handleFireflyFailure,
  handleFireflySuccess,
} from '../util/FireflyHelper'

import PlanningViewMessage from './components/PlanningViewMessage'
import ProjectListTable from './components/ProjectListTable'
import StickyBarPlanning from './components/StickyBarPlanning'

import './scss/PlanningPage.scss'
const PlanningPage = ({ archive, session = {} }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [project, setProject] = useState({})
  const [projects, setProjects] = useState([])
  const api = useMemo(() => new ProjectApi(), [])
  const [pastProject, setPastProject] = useState(false)
  const [projectsData, setProjectsData] = useState()
  const [pageNum, setPageNum] = useState(0)
  const [loading, setLoading] = useState(false)
  const viewName = 'MKTG Priorities'

  useEffect(() => {
    if (project) {
      handleFireflyInit('pageload', viewName, 'success', session)
      setLoading(true)
      api
        .findProjects({
          page_num: pageNum,
          past_project: pastProject,
          project_description: '',
        })
        .then((data) => {
          setProjectsData(data)
          setProjects(data?.projects)
          setLoading(false)
          handleFireflySuccess('pageload', viewName, 'success', session)
        })
        .catch((e) => {
          console.log(e)
          setProjectsData()
          setProjects([])
          setLoading(false)
          handleFireflyFailure('pageload', viewName, 'error', session)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, project, pastProject, pageNum])

  return (
    <div data-testid="planningPage">
      <PlanningViewMessage />

      <StickyBarPlanning
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        search={search}
        setSearch={setSearch}
        pastProject={pastProject}
        setPastProject={setPastProject}
        setPageNum={setPageNum}
      />
      {pastProject ? (
        <h2 className="projectHeading"> Past Project List</h2>
      ) : (
        <h2 className="projectHeading"> Upcoming Projects</h2>
      )}

      <ProjectListTable
        projects={projects?.filter(
          (project) =>
            project?.project_description
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            project?.project_name.toLowerCase().includes(search.toLowerCase())
        )}
        loading={loading}
        setIsDrawerOpen={setIsDrawerOpen}
        setProject={setProject}
        setPastProject={setPastProject}
        projectsData={projectsData}
        setPageNum={setPageNum}
        pageNum={pageNum}
      />
    </div>
  )
}
export default PlanningPage
