import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Grid,
  Heading,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import { Formik } from 'formik'
import isEmpty from 'lodash.isempty'
import { useContext, useEffect, useState, useMemo } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import MarketingChannelSelect from '../../src/creativeHubGlobal/components/MarketingChannelSelect'
import ChannelApi from '../api/creative-hub/ChannelApi'
import LoadingSpinner from '../components/LoadingSpinner'
import DialogView from '../containers/DialogView'
import { BreadcrumbContext } from '../context/Context'
import { canEditImageTracking } from '../util/CheckRole'
import {
  handleFireflyClickInteraction,
  handleFireflyFailure,
  handleFireflyInit,
  handleFireflySuccess,
} from '../util/FireflyHelper'

import ItemApi from './api/ItemApi'
import ItemsPDF from './components/ItemsPDF'
import ItemTable from './components/ItemTable'
import UpdateImageTracking from './components/UpdateImageTracking'
import {
  PROJECT_ITEMS_PAGE_SIZE,
  fetchDivisionFromQuery,
  filterOptions,
  formatDivisionOptions,
  formatPyramidNameOptions,
  getPageNumber,
  isLastPage,
  makeProjectItemsPayload,
  setFilterFromQuery,
} from './util/ItemUtil'

const ImageTrackingPage = (props) => {
  const channelApi = useMemo(() => new ChannelApi(), [])

  const { role, session = {} } = props

  const { search } = useLocation()
  const [channels, setChannels] = useState([])
  const navigate = useNavigate()
  const { setBreadcrumbContext } = useContext(BreadcrumbContext)
  const query = useMemo(() => new URLSearchParams(search), [search])
  const api = useMemo(() => new ItemApi(), [])
  // eslint-disable-next-line
  const [selectedChannelIds, setSelectedChannelIds] = useState(
    query.get('channelIds')
      ? query.get('channelIds').split(',').map(Number)
      : []
  )

  const [selectedMarketingChannelFilter, setMarketingChannelFilter] = useState(
    channels?.filter((item) => selectedChannelIds?.includes(item.channel_id))
  )

  const [selectedProjectId] = useState(useParams().projectId)
  const [selectedPyarmidName, setSelectedPyarmidName] = useState(
    query.get('pyramidName')
  )
  const [selectedDivision, setSelectedDivision] = useState(
    query.get('division')
  )

  const [queryParams, setQueryParams] = useState(Object.fromEntries(query))
  const [loading, setLoading] = useState(false)
  const [itemList, setItemList] = useState([])
  const [projectItemData, setProjectItemData] = useState({})
  const [editItem, setEditItem] = useState({})
  const [pyramidSelectValues, setPyramidSelectValues] = useState([])
  const [selectedDivisionValues, setSelectedDivisionValues] = useState([])
  const [onFirstPage, setOnFirstPage] = useState(true)
  const [onLastPage, setOnLastPage] = useState(false)
  const [canDisplayPdf, setDisplayPdf] = useState(false)
  const hasEditPrivileges = role?.some((r) => canEditImageTracking(r))
  const { project = {} } = projectItemData
  const projectTitle = project?.project_description
  const viewName = 'Promo Offers: Image Tracking'

  const {
    dpci,
    imageCollected,
    pageNum: pageNumber = 0,
    pyramids,
    sortBy,
    sortOrder,
  } = queryParams

  useEffect(() => {
    if (selectedProjectId) {
      channelApi.getChannels().then((response) => {
        if (selectedChannelIds.length > 0) {
          setMarketingChannelFilter(
            response?.filter((item) =>
              selectedChannelIds?.includes(item.channel_id)
            )
          )
        }
        setChannels(response)
      })

      const payload = makeProjectItemsPayload({
        channelIds: selectedChannelIds,
        division: selectedDivision,
        dpci,
        imageCollected,
        pageNum,
        projectId: selectedProjectId,
        pyarmidType: pyramids,
        pyramidName: selectedPyarmidName,
        sortBy,
        sortOrder,
      })
      fetchItems(payload, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId, selectedChannelIds])

  const pageNum = pageNumber ? parseInt(pageNumber) : 0

  const fetchItems = (payload, isFirstRequest = false) => {
    handleFireflyInit('pageload', viewName, 'success', session)
    setLoading(true)
    api
      .fetchItemsByProjectId(payload)
      .then((data) => {
        setProjectItemData(data)
        setItemList(data?.items)
        setLoading(false)
        setOnFirstPage(payload.page_num === 0)
        setOnLastPage(isLastPage(payload.page_num, data?.total_items))
        setBreadcrumbContext({
          row: data?.project,
          setBreadcrumbContext,
        })
        if (isFirstRequest) {
          setPyramidSelectValues(formatPyramidNameOptions(data?.pyramid_names))
          setSelectedDivisionValues(
            formatDivisionOptions(data?.division_filter)
          )
        }
        handleFireflySuccess('pageload', viewName, 'success', session)
      })
      .catch((e) => {
        console.log(e)
        setProjectItemData()
        setItemList([])
        setLoading(false)
        handleFireflyFailure('pageload', viewName, 'error', session)
      })
  }

  const handleSubmitRequest = (values) => {
    !isEmpty(values.dpci)
      ? query.set('dpci', values.dpci)
      : query.delete('dpci')

    query.delete('pyramids')
    query.delete('channelIds')
    query.delete('imageCollected')
    query.delete('pyramidName')
    query.delete('pageNum')
    query.delete('division')

    const allFilters = {
      division: [],
      imageCollected: [],
      pyramids: [],
    }

    values?.division?.forEach((filter = {}) => {
      allFilters[filter.filter].push(filter.value)
    })

    values.filters.forEach((filter) =>
      allFilters[filter.filter].push(filter.value)
    )
    Object.keys(allFilters).forEach((filterType) => {
      if (!isEmpty(allFilters[filterType])) {
        query.set(filterType, allFilters[filterType])
      }
    })

    if (values?.pyramidName) {
      query.set('pyramidName', values?.pyramidName)
      setSelectedPyarmidName(values?.pyramidName)
    }

    const channelIds = []
    selectedMarketingChannelFilter.forEach((channelFilter) => {
      channelIds.push(channelFilter.id)
    })
    if (channelIds.length > 0) {
      query.set('channelIds', channelIds)
    }

    setQueryParams(Object.fromEntries(query))

    navigate(`/execution/items/${selectedProjectId}?${query.toString()}`)
    handleRequest(query)
  }

  const handleRequest = (qurey = [], changePage, isUpdateRequst) => {
    const queryParams = Object.fromEntries(query)
    const channelIds = []
    if (selectedMarketingChannelFilter.length) {
      selectedMarketingChannelFilter.forEach((channelFilter) => {
        channelIds.push(channelFilter.id)
      })
    }
    let payload = makeProjectItemsPayload({
      channelIds: channelIds,
      division: queryParams.division,
      dpci: queryParams.dpci,
      imageCollected: queryParams.imageCollected,
      pageNum: pageNumber ? parseInt(pageNumber) : 0,
      projectId: selectedProjectId,
      pyramidName: selectedPyarmidName,
      pyramidType: queryParams.pyramids,
      sortBy,
      sortOrder,
    })

    if (!isUpdateRequst) {
      if (!changePage) {
        setOnFirstPage(true)
        setOnLastPage(false)
        payload.page_num = 0
      } else {
        payload.page_num = getPageNumber(changePage, pageNum)
        query.set('pageNum', payload.page_num)
      }
    }

    setQueryParams(Object.fromEntries(query))

    fetchItems(payload)

    navigate(`/execution/items/${selectedProjectId}?${query.toString()}`)
  }

  const handleClearFilters = () => {
    setSelectedPyarmidName('')
    setQueryParams({})
    setOnFirstPage(true)
    setOnLastPage(false)
    setSelectedDivision('')
    setMarketingChannelFilter([])
    let payload = makeProjectItemsPayload({ projectId: selectedProjectId })
    fetchItems(payload)
    navigate(`/execution/items/${selectedProjectId}`)
  }

  const handleUpdateImageCollected = ({
    offerId = '',
    id = '',
    imageCollected = 'No',
    ivyOfferId = '',
  }) => {
    setLoading(true)
    const payload = [
      {
        image_collected: imageCollected,
        item_id: id,
        ivy_offer_id: ivyOfferId,
        offer_id: offerId,
      },
    ]
    api
      .updateItemTracking(payload)
      .then((data) => {
        setLoading(false)
        handleRequest(query, null, true)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const handleCanDisplayPdf = (value = false) => {
    setDisplayPdf(value)
    handleFireflyClickInteraction(
      'pageload',
      'Promo Offers: Image Tracking PDF',
      'success',
      session
    )
  }

  const handleSort = (values = {}) => {
    const queryParams = Object.fromEntries(query)
    let payload = makeProjectItemsPayload({
      division: queryParams.division,
      dpci,
      imageCollected,
      pageNum,
      projectId: selectedProjectId,
      pyramidName: selectedPyarmidName,
      pyramidType: pyramids,
      sortBy: values?.sortBy,
      sortOrder: values?.sortOrder,
    })
    query.set('sortBy', values?.sortBy)
    query.set('sortOrder', values?.sortOrder)
    setQueryParams(Object.fromEntries(query))
    fetchItems(payload)
    navigate(`/execution/items/${selectedProjectId}?${query.toString()}`)
  }

  return projectItemData ? (
    <div data-testid="ImageTrackingPage">
      <Formik
        enableReinitialize
        initialValues={{
          division: fetchDivisionFromQuery(query, selectedDivisionValues, [
            'division',
          ]),
          dpci: query.get('dpci') ? query.get('dpci') : '',
          filters: setFilterFromQuery(query),
          pyramidName: selectedPyarmidName,
        }}
        onSubmit={(values) => handleSubmitRequest(values)}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
        }) => (
          <div className="hc-pa-none">
            <Grid.Container
              spacing="none"
              direction="row-reverse"
              justify="space-between"
              align="flex-end"
            >
              <Grid.Item>
                <Button
                  disabled={onFirstPage}
                  onClick={() => {
                    handleRequest(query, -1)
                  }}
                >
                  {`Prev ${PROJECT_ITEMS_PAGE_SIZE} Items`}
                </Button>
                <Button
                  disabled={onLastPage}
                  onClick={() => {
                    handleRequest(query, 1)
                  }}
                >
                  {`Next ${PROJECT_ITEMS_PAGE_SIZE} Items`}
                </Button>
              </Grid.Item>

              <Grid.Item xs={12}>
                <Card
                  elevation={2}
                  corners="bottom"
                  className="hc-pa-dense"
                  style={{ borderBottomRightRadius: 0 }}
                >
                  <Grid.Container spacing="dense" justify="center">
                    <Grid.Item xs={11}>
                      <Heading className="hc-ta-center" size={2}>
                        {projectTitle}
                      </Heading>
                    </Grid.Item>
                    <Grid.Item xs={1}>
                      {itemList?.length > 0 ? (
                        <FontAwesomeIcon
                          className="labelledIcon clickable"
                          onClick={() => handleCanDisplayPdf(true)}
                          icon={faFilePdf}
                          size="2x"
                        />
                      ) : null}
                    </Grid.Item>
                  </Grid.Container>
                  <Grid.Container spacing="dense" justify="center">
                    <Grid.Item xs={3}>
                      <Autocomplete
                        multiselect
                        id="filters"
                        chipHeight="dense"
                        onUpdate={setFieldValue}
                        value={values.filters}
                        options={filterOptions}
                        placeholder="Filters: Pyramid, Collected/Not Collected"
                      />
                    </Grid.Item>
                    {selectedDivisionValues?.length > 0 ? (
                      <Grid.Item xs={2}>
                        <Autocomplete
                          multiselect
                          grow
                          id="division"
                          chipHeight="dense"
                          onUpdate={setFieldValue}
                          value={values.division}
                          options={selectedDivisionValues}
                          placeholder="Division"
                        />
                      </Grid.Item>
                    ) : null}
                    <Grid.Item
                      xs={2}
                      className="hc-mt-dense"
                      style={{ marginTop: '6px !important' }}
                    >
                      <Input.Select
                        id="pyramidName"
                        onUpdate={(id, value) => {
                          setSelectedPyarmidName(value)
                        }}
                        options={pyramidSelectValues}
                        value={values.pyramidName || ''}
                        defaultValue=""
                      />
                    </Grid.Item>
                    <Grid.Item xs={1} className="hc-mt-dense">
                      <Form.Field
                        id="dpci"
                        placeholder="DPCI search"
                        value={values.dpci}
                        onChange={handleChange}
                      />
                    </Grid.Item>

                    <Grid.Item xs={3}>
                      <MarketingChannelSelect
                        selectedMarketingChannelFilter={
                          selectedMarketingChannelFilter
                        }
                        setMarketingChannelFilter={setMarketingChannelFilter}
                        channels={channels}
                        label={''}
                      />
                    </Grid.Item>
                    <Grid.Item className="hc-mt-dense">
                      <ButtonGroup className="hc-mb-none hc-mt-none">
                        <Button
                          type="secondary"
                          onClick={() => {
                            handleReset()
                            handleClearFilters()
                          }}
                        >
                          Clear
                        </Button>
                        <Button type="primary" onClick={handleSubmit}>
                          Apply
                        </Button>
                      </ButtonGroup>
                    </Grid.Item>
                  </Grid.Container>
                  <ItemTable
                    items={itemList}
                    setEditItem={setEditItem}
                    hasEditPrivileges={hasEditPrivileges}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    handleSort={handleSort}
                  />
                </Card>
              </Grid.Item>
              <Grid.Item>
                <Button
                  disabled={onFirstPage}
                  onClick={() => {
                    handleRequest(query, -1)
                  }}
                >
                  {`Prev ${PROJECT_ITEMS_PAGE_SIZE} Items`}
                </Button>
                <Button
                  disabled={onLastPage}
                  onClick={() => {
                    handleRequest(query, 1)
                  }}
                >
                  {`Next ${PROJECT_ITEMS_PAGE_SIZE} Items`}
                </Button>
              </Grid.Item>
            </Grid.Container>
            <UpdateImageTracking
              editItem={editItem}
              setEditItem={setEditItem}
              updateItem={handleUpdateImageCollected}
              hasEditPrivileges={hasEditPrivileges}
            />
            <LoadingSpinner show={loading} />
          </div>
        )}
      </Formik>
      <DialogView
        isVisible={canDisplayPdf}
        onClose={() => handleCanDisplayPdf(false)}
        toolbar={
          <div className="dialog-view-toolbar">
            <PDFDownloadLink
              document={
                <ItemsPDF projectTitle={projectTitle} items={itemList} />
              }
              fileName={`CreativeHub_ImageTrackingList_${projectTitle}_${new Date().toLocaleDateString()}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download'
              }
            </PDFDownloadLink>
          </div>
        }
      >
        <PDFViewer name="smple" height="1200" width="100%" showToolbar>
          <ItemsPDF projectTitle={projectTitle} items={itemList} />
        </PDFViewer>
      </DialogView>
    </div>
  ) : null
}
export default ImageTrackingPage
