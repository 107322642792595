export const groups = {
  'APP-OAUTH2-CrtvHub-CopyWriter': 'Copywriter',
  'APP-OAUTH2-CrtvHub-CreativeArt': 'CreativeArt',
  'APP-OAUTH2-CrtvHub-CreativeProd': 'CreativeProd',
  'APP-OAUTH2-CrtvHub-Marketing': 'Marketing',
}

export const formatContacts = (contactsByEntitlement) => {
  const flatContactDetails = {}
  contactsByEntitlement?.forEach((entitlement = {}) => {
    const { group = '' } = entitlement
    const listofMemberNames = []
    entitlement?.members?.forEach((contact) => {
      const { display_name } = contact
      listofMemberNames.push(display_name)
    })
    if (group) {
      flatContactDetails[groups[group]] = [...mapPmm(listofMemberNames)]
    }
  })

  return flatContactDetails
}

export const mapPmm = (pmmArray = []) =>
  pmmArray.map((option, index) => ({
    id: index + 1,
    label: option,
    value: option,
  }))
