import axios from 'axios'

import appConfig from '../../config/appConfig'
import projectMapper from '../../creativeHubGlobal/mappers/ProjectMapper'
import {
  fetchAuthorizationToken,
  fetchIdToken,
} from '../../creativeHubGlobal/util/AuthUtil'
import { makeChannelIdQueryParam } from '../../creativeHubGlobal/util/CreativeHubGlobalUtil'
import { generateCreateEditProjectError } from '../../util/ErrorUtil'
export default class ProjectApi {
  constructor() {
    this.config = appConfig()
    this.client = null
  }

  init_project_api = () => {
    this.client = axios.create({
      baseURL: this.config.markethub.url,
      headers: {
        Authorization: fetchAuthorizationToken(),
        'x-id-token': fetchIdToken(),
      },
      timeout: 31000,
    })
    return this.client
  }

  findProjects = async (search) =>
    await this.init_project_api()
      .post(this.config.markethub.url + `projects/search`, search)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  findUmbrellaChannelsByPyramid = async ({ projectId, pyramidType }) =>
    await this.init_project_api()
      .get(
        `${
          this.config.markethub.url
        }pyramid?projectId=${projectId}&pyramidType=${encodeURIComponent(
          pyramidType
        )}`
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  putProject = async (project, id) =>
    await this.init_project_api()
      .put(this.config.markethub.url + `projects/${id}`, project)
      .then((res) => projectMapper(res.data))
      .catch((e) => {
        console.log(e)
        return generateCreateEditProjectError(e.response)
      })

  createProject = async (project) =>
    await this.init_project_api()
      .post(this.config.markethub.url + 'projects', project)
      .then((res) => projectMapper(res.data))
      .catch((e) => {
        console.log(e)
        return generateCreateEditProjectError(e.response)
      })

  deleteProject = async (deleteProjectRequest) => {
    await this.init_project_api()
      .delete(
        `${this.config.markethub.url}projects/${deleteProjectRequest.project_id}`
      )
      .then((res) => res.data)
      .catch((error) => {
        console.error(error)
        return []
      })
  }

  fetchProjectDetails = async (projectId = '') =>
    await this.init_project_api()
      .get(this.config.markethub.url + `projects/${projectId}`)
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })

  fetchProjectSummary = async (projectId, selectedChannels = []) =>
    await this.init_project_api()
      .get(
        `${
          this.config.markethub.url
        }project-summary/${projectId}${makeChannelIdQueryParam(
          selectedChannels
        )}`,
        projectId
      )
      .then((res) => res.data)
      .catch((e) => {
        console.log(e)
        return []
      })
}
