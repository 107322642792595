import {
  Button,
  Card,
  ExpandableSection,
  Grid,
  ProgressBar,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import './scss/WACreativeSummary.scss'
import { faLink, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useMemo, useState, useEffect } from 'react'

import UmbrellaApi from '../../api/creative-hub/UmbrellaApi'
import WeeklyAdAutomationApi from '../../api/creative-hub/WAAutomationApi.js'
import { WeeklyAdAutomationContext } from '../../context/Context.js'
import { isUmbrellaEnterpriseLinked } from '../../creativeHubGlobal/util/UmbrellaUtil'
import { DEACTIVATED } from '../util/Constants.js'
import { ErrorHandler } from '../util/ErrorHandler.js'

import WAOffersDrawer from './WAOffersDrawer.js'

const WACreativeSummary = () => {
  const weeklyAdAutomationApi = useMemo(() => new WeeklyAdAutomationApi(), [])
  const umbrellaApi = useMemo(() => new UmbrellaApi(), [])

  const { currentPageInfo, selectedProject, makeToast } = useContext(
    WeeklyAdAutomationContext
  )

  const [expandedStory, setExpandedStory] = useState('')
  const [isStoryExpanding, setIsStoryExpanding] = useState(false)
  const [umbrellaDetailsForSelectedStory, setUmbrellaDetailsForSelectedStory] =
    useState({})
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [selectedUmbrella, setSelectedUmbrella] = useState({})
  const [offerDetails, setOfferDetails] = useState([])
  const [isOfferListLoading, setIsOfferListLoading] = useState(false)
  const [offerMetadata, setOfferMetadata] = useState({})
  const [linkedUmbrellas, setLinkedUmbrellas] = useState([])

  const stories = {}
  Object.values(currentPageInfo?.pyramids).forEach((pyramid) =>
    pyramid?.stories.forEach((story) => (stories[story.story_id] = story))
  )

  useEffect(() => {
    selectedProject &&
      umbrellaApi
        .getEnterpriseUmbrellas(
          selectedProject?.project_id,
          selectedProject?.start_date,
          selectedProject?.end_date
        )
        .then((response) => {
          setLinkedUmbrellas(response)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject])

  const handleStoryExpand = (story_id) => {
    setUmbrellaDetailsForSelectedStory({})
    if (story_id === expandedStory) {
      setExpandedStory('')
      setIsStoryExpanding(false)
    } else {
      setIsStoryExpanding(true)
      const umbrellaIds = stories[story_id]?.umbrellas?.map((umbrella) => ({
        umbrella_id: umbrella.umbrella_id,
      }))

      weeklyAdAutomationApi
        .fetchUmbrellaDetailsForStory({
          project_id: selectedProject?.project_id,
          story_id,
          umbrellas: umbrellaIds,
        })
        .then((response) => {
          setIsStoryExpanding(false)
          setUmbrellaDetailsForSelectedStory(response)
        })
      setExpandedStory(story_id)
    }
  }
  const fetchOffers = (umbrella, pageNum) => {
    const filteredUmbrella = stories[umbrella?.story_id]?.umbrellas?.filter(
      ({ umbrella_id }) => umbrella_id === umbrella.id
    )[0]
    const offerIds = filteredUmbrella?.offers?.map((offer) => offer.offer_id)
    weeklyAdAutomationApi
      .fetchOffersForUmbrella({
        pageNum,
        project_id: selectedProject?.project_id,
        story_id: umbrella?.story_id,
        umbrellas: [{ offers: offerIds, umbrella_id: umbrella.id }],
      })
      .then((response) => {
        setIsOfferListLoading(false)
        setOfferDetails((prevState) => {
          const offers =
            response?.story?.umbrellas?.filter(
              (umb) => umb.id === umbrella.id
            )?.[0]?.offers || []
          return [...prevState, ...offers]
        })
        setOfferMetadata(response?.meta_data)
      })
      .catch((error) => {
        makeToast({
          message: ErrorHandler(error),
          type: 'error',
        })
        setIsOfferListLoading(false)
      })
  }
  const handleViewOffers = (umbrella) => {
    setIsDialogVisible(true)
    setSelectedUmbrella(umbrella)
  }

  const sortedUmbrellas = (umbrellas) =>
    umbrellas?.sort((a, b) => a.umbrella_order - b.umbrella_order)
  const storyList = Object.values(stories)
  return (
    <>
      {storyList?.length > 0 ? (
        storyList?.map(({ story_name, story_id }, idx) => (
          <Card className="storyList" key={idx + '-' + story_id}>
            <ExpandableSection
              onExpand={(e) => handleStoryExpand(story_id)}
              expanded={story_id === expandedStory}
              className="storyHeader"
            >
              <h4>{`Story: ${story_name}`}</h4>
              <ExpandableSection.Content>
                {isStoryExpanding && <ProgressBar indeterminate />}
                {sortedUmbrellas(
                  umbrellaDetailsForSelectedStory?.story?.umbrellas
                )?.map((umbrella, idx) => (
                  <div
                    key={idx + '-' + umbrella?.umbrella_id}
                    className="umbrellaDetail"
                  >
                    <Grid.Container xs={12} direction="row">
                      <Grid.Item
                        xs={5}
                        className={
                          umbrella.status.toLowerCase() === DEACTIVATED
                            ? 'deactivated'
                            : ''
                        }
                      >
                        <div>
                          <span className="umbrella-detail-key">
                            Umbrella {umbrella?.umbrella_order}:{' '}
                            <span className="summary-item--value">
                              {isUmbrellaEnterpriseLinked(
                                linkedUmbrellas,
                                umbrella.ivy_umbrella_id
                              ) ? (
                                <Tooltip
                                  content="This umbrella is also linked to an Enterprise Guest Outcome"
                                  location="top-left"
                                >
                                  <FontAwesomeIcon
                                    icon={faLink}
                                    className="fa-icon linked"
                                    size="lg"
                                  />
                                </Tooltip>
                              ) : null}
                              {umbrella.key ? (
                                <FontAwesomeIcon
                                  icon={faKey}
                                  className="fa-duotone key"
                                  size="lg"
                                  color="black"
                                />
                              ) : null}
                              {umbrella?.name}{' '}
                            </span>
                          </span>
                        </div>

                        <div>
                          <span className="umbrella-detail-key">Pyramid: </span>
                          {umbrellaDetailsForSelectedStory?.priority_name}
                        </div>
                      </Grid.Item>

                      <Grid.Item
                        xs={5}
                        className={
                          umbrella.status.toLowerCase() === DEACTIVATED
                            ? 'deactivated'
                            : ''
                        }
                      >
                        <div>
                          <span className="umbrella-detail-key">
                            Marketing Channels:{' '}
                          </span>
                          {umbrella?.channels?.map((channel, idx) => (
                            <span>
                              {channel.channel_name}
                              {idx !== umbrella?.channels.length - 1 && ', '}
                            </span>
                          ))}
                        </div>

                        <div>
                          <span className="umbrella-detail-key">
                            Promo Message:{' '}
                          </span>
                          <span>{umbrella?.promo_message}</span>
                        </div>
                      </Grid.Item>

                      <Grid.Item xs={2}>
                        <div className="centerContent">
                          <Button
                            onClick={() => handleViewOffers(umbrella)}
                            className="textButton"
                          >
                            View Offers
                          </Button>
                        </div>
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                ))}
              </ExpandableSection.Content>
            </ExpandableSection>
          </Card>
        ))
      ) : (
        <Grid.Item className="no-items-found" xs={12}>
          No Data Found.
        </Grid.Item>
      )}
      {selectedUmbrella.name && (
        <WAOffersDrawer
          isDialogVisible={isDialogVisible}
          setIsDialogVisible={setIsDialogVisible}
          selectedUmbrella={selectedUmbrella}
          setSelectedUmbrella={setSelectedUmbrella}
          umbrellaDetailsForSelectedStory={umbrellaDetailsForSelectedStory}
          offers={offerDetails}
          setOfferDetails={setOfferDetails}
          fetchMoreOffers={fetchOffers}
          isOfferListLoading={isOfferListLoading}
          setIsOfferListLoading={setIsOfferListLoading}
          offerMetadata={offerMetadata}
          setOfferMetadata={setOfferMetadata}
        />
      )}
    </>
  )
}

export default WACreativeSummary
